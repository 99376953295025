import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { setDocument } from 'app/state/redux/documentSlice';
import { useEditorDispatch, useEditorSelector } from 'app/state/contexts/EditorContext';
import { usePageNumberingSave } from 'app/api/documents/document-queries';
import { i18n } from 'app/utils/i18n';

export const PageNumbering = () => {
  const editorDispatch = useEditorDispatch();
  const { documentId } = useParams();
  const reduxDocument = useEditorSelector((editor) => editor.pDocument);
  const { mutateAsync: savePageNumbering } = usePageNumberingSave();
  const [pageNumbering, setPageNumbering] = useState(reduxDocument.content?.pageNumbering || {});

  useEffect(() => {
    setPageNumbering(reduxDocument.content?.pageNumbering || {});
  }, [reduxDocument]);

  const handleShowFrom = (e) => {
    const newValue = { showFrom: parseInt(e.target.value), countFrom: pageNumbering.countFrom ?? 1 };
    setPageNumbering(newValue);

    savePageNumbering({ documentSuperId: documentId, documentRevisionId: reduxDocument.revisionId, pageNumbering: newValue }).then((res) => {
      editorDispatch(setDocument(res.data));
    });
  };

  const handleCountFrom = (e) => {
    const newValue = { showFrom: pageNumbering.showFrom ?? 1, countFrom: parseInt(e.target.value) };
    setPageNumbering(newValue);

    savePageNumbering({ documentSuperId: documentId, documentRevisionId: reduxDocument.revisionId, pageNumbering: newValue }).then((res) => {
      editorDispatch(setDocument(res.data));
    });
  };

  return (
    <>
      <h2 className="mb-3">{i18n('project-details.page-numbering.title')}</h2>
      <div className="row">
        <div className="col">
          <label className="form-label">{i18n('project-details.page-numbering.show-from')}</label>
          <input value={pageNumbering.showFrom || 1} onChange={handleShowFrom} className="form-control" type="number" min={1} />
        </div>

        <div className="col">
          <label className="form-label">{i18n('project-details.page-numbering.count-from')}</label>
          <input value={pageNumbering.countFrom || 1} onChange={handleCountFrom} className="form-control" type="number" min={1} />
        </div>
      </div>
    </>
  );
};
