import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Editor } from './EditorOld';
import { useParams, useHistory } from 'react-router-dom';
import request from 'app/api/request';
import withPage from 'app/slate/extensions/page';
import withParagraph from 'app/slate/extensions/paragraph';
import withLayout from 'app/slate/extensions/layout';
import { TemplateSidebar } from './TemplateSidebar';
import TemplateStructure from './TemplateStructure';
import { useDelayedSave } from 'app/utils/hooks/delayed-save';
import { NavbarTemplateEditor } from './navbar';
import { Spinner } from 'app/components';
import { i18n } from 'app/utils/i18n';
import { SubNavBar } from 'app/components/editor/navbar/SubNavbar';
import { SubToolbar } from 'app/components/editor/subtoolbar/SubToolbar';
import { v4 as uuidv4 } from 'uuid';
import ServerErrorHandler from 'app/ErrorHandler';
import { UserContext } from 'app/state/contexts/UserContext';

function upgradeTemplate(template) {
  template.structure.templateSuperId = template.superId;
  return template;
}

const buildDraftContent = (draftContent = [], documentDesign, documentStyle, template) => {
  if (!draftContent.find((s) => s.type === 'document')) {
    draftContent.push({
      type: 'document',
      style: documentStyle.content,
      templateSuperId: documentDesign.templateSuperId,
      templateRevisionId: documentDesign.templateRevisionId,
    });
  }

  if (!draftContent.find((s) => s.type === 'template' && s.templateSuperId === template.superId)) {
    draftContent.push({
      ...template,
      templateSuperId: template.superId,
      type: 'template',
    });
  }

  return draftContent;
};

async function fetchTemplate(templateId, selectedOrganization) {
  const {
    data: [template],
  } = await request.get(`/gaby/templates?superId=${templateId}&organization=${selectedOrganization}&compiled=true`);

  return upgradeTemplate(template);
}

async function fetchDocWithStyle(documentId, templateId) {
  const {
    data: [doc],
  } = await request.get(`/gaby/documents?superId=${documentId}&current=true&compileSass=true&useDraft=true&useTemplate=${templateId}`);
  const styleId = doc.content.design.find((style) => style.type === 'document').styleId;
  const { data: style } = await request.get(`/gaby/styles/${styleId}`);
  return [doc, style];
}

function fetchDraft(documentId) {
  return request
    .get(`/gaby/design-draft/${documentId}?compiled=true`)
    .then((res) => res.data)
    .catch((err) => {
      if (err.status === 404) {
        return null;
      } else {
        throw err;
      }
    });
}

function discardDraft(documentId) {
  return request.del(`/gaby/design-draft/${documentId}`);
}

const extensions = [withLayout({ markEmpty: true }), withPage(), withParagraph()];

const templateEditorStyles = `
.layout-leaf-empty { 
  background: rgba(193, 210, 228, 0.23);
}

.layout-leaf { 
  outline: 1px dotted rgba(77, 184, 148, 0.5);
}

/*TODO lägg till .layout-styling på hover-effekt*/
}`;

export const TemplateEditor = () => {
  const history = useHistory();

  const { documentId, templateId } = useParams();
  const { selectedOrganization } = useContext(UserContext);

  const [doc, setDoc] = useState({});

  const [transpiledCss, setTranspiledCss] = useState('');

  const [template, setTemplate] = useState(null);

  const [templateStyle, setTemplateStyle] = useState('');
  const [documentStyle, setDocumentStyle] = useState('');

  const [structureEditorContent, setStructureEditorContent] = useState();

  const [shouldStoreDraft, setShouldStoreDraft] = useState(false);
  const [draftExists, setDraftExists] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);
  const [draftContent, setDraftContent] = useState(null);
  const [dirtyDraft, setDirtyDraft] = useState(false);

  const storeTemplate = () => {
    return request
      .post(`/gaby/templates?compiled=true`, {
        ...template,
        style: templateStyle,
        structure: structureEditorContent[0],
        revisionId: uuidv4(),
      })
      .then((res) => res.data)
      .then((template) => {
        setErrorMessage(null);
        setTemplate(template);
        history.replace('?');
      })
      .catch(async (e) => {
        try {
          const data = await JSON.parse(e.request.response);
          setErrorMessage(data.message);
        } catch (e) {
          setErrorMessage('Unknown error');
        }

        history.replace('?');
      });
  };

  const [storeDraft, cancelStoreDraft] = useDelayedSave(
    useCallback(
      async (draftContent) => {
        const draft = {
          documentSuperId: doc.superId,
          documentRevisionId: doc.revisionId,
          content: draftContent,
          organization: selectedOrganization,
        };

        await request.post(`/gaby/design-draft?compiled=true`, draft);
        setDraftExists(true);
        setDirtyDraft(false);

        const [pDocument] = await fetchDocWithStyle(documentId, templateId);
        if (pDocument.css) {
          setTranspiledCss(pDocument.css);
          setErrorMessage(null);
        } else {
          setErrorMessage('Error compiling sass');
        }
      },
      [documentId, selectedOrganization, doc, templateId]
    )
  );

  useEffect(() => {
    if (shouldStoreDraft) {
      storeDraft(draftContent);
      setDirtyDraft(true);
    }
  }, [draftContent, shouldStoreDraft, storeDraft]);

  const init = useCallback(() => {
    const docPromise = fetchDocWithStyle(documentId, templateId);
    const templatePromise = fetchTemplate(templateId, selectedOrganization);
    const draftPromise = fetchDraft(documentId);

    Promise.all([docPromise, templatePromise, draftPromise])
      .then(([[pDocument, documentStyle], template, draft]) => {
        setDoc(pDocument);
        setTranspiledCss(pDocument.css);
        setTemplate(template);

        setDraftExists(!!draft);

        const documentDesign = pDocument.content.design.find((s) => s.type === 'document');
        const content = buildDraftContent(draft?.content, documentDesign, documentStyle, template);
        setDraftContent(content);
        const templateDraft = content.find((s) => s.type === 'template' && s.superId === templateId);
        setStructureEditorContent([templateDraft.structure]);
        setTemplateStyle(templateDraft.style);
        const draftDocumentStyle = content.find((s) => s.type === 'document').style;
        setDocumentStyle(draftDocumentStyle);
      })
      .catch(ServerErrorHandler);
  }, [documentId, templateId, selectedOrganization]);

  useEffect(() => {
    init();
  }, [init]);

  const handleTemplateClassNameChange = useCallback(
    (className) => {
      const newTemplate = {
        ...template,
        className: className.split(' ')[0],
      };
      setTemplate(newTemplate);
      setShouldStoreDraft(true);
    },
    [template]
  );

  const handleStructureChange = (value, editor) => {
    if (editor.operations.some((op) => op.type !== 'set_selection')) {
      setShouldStoreDraft(true);
    }
    setStructureEditorContent(value);

    // FIXME This is needed for change events to happen, why?
    history.replace('#');
  };

  useEffect(() => {
    setDraftContent((content) => {
      if (!content) {
        return content;
      }

      content.find((s) => s.type === 'document').style = documentStyle;
      return [...content];
    });
  }, [documentStyle, templateId]);

  useEffect(() => {
    setDraftContent((content) => {
      if (!content) {
        return content;
      }

      content.find((s) => s.type === 'template' && s.superId === templateId).style = templateStyle;
      return [...content];
    });
  }, [templateStyle, templateId]);

  useEffect(() => {
    setDraftContent((content) => {
      if (!content) {
        return content;
      }

      content.find((s) => s.type === 'template' && s.superId === templateId).structure = structureEditorContent[0];
      return [...content];
    });
  }, [structureEditorContent, templateId]);

  const handleTemplateStyleChange = (style) => {
    setTemplateStyle(style);
    setShouldStoreDraft(true);
  };

  const handleDiscardDraftClick = async () => {
    cancelStoreDraft();
    setShouldStoreDraft(false);
    discardDraft(documentId).then(init).catch(ServerErrorHandler);
  };

  const handleApplyDraftInProjectClick = async () => {
    setShouldStoreDraft(false);
    await storeTemplate();

    const docRes = await request.post(`/gaby/documents/${documentId}/actions?compileSass=true&useTemplate=${templateId}`, {
      action: 'apply_draft',
      revisionId: doc.revisionId,
    });

    setTranspiledCss(docRes.data.css);
    setDraftExists(false);
  };

  const handleDocumentStyleChange = async (newStyle) => {
    setDocumentStyle(newStyle);
    setShouldStoreDraft(true);
  };

  if (!structureEditorContent || documentStyle === undefined || documentStyle === null) {
    return <Spinner />;
  }

  const shadowedChildren = <style type="text/css">{transpiledCss + templateEditorStyles}</style>;
  const permissions = { styling: true, insert: false, languange: false };
  const showSubToolbar = Object.values(permissions).some((value) => value); // Permission check?
  const toolbarComponents = { styling: true, insert: false, languange: false };

  return (
    <div className={`container-fluid ps-0 ${showSubToolbar ? 'sub-navbar-padding' : ''}`}>
      <div className="d-flex">
        <Editor
          className="d-flex w-100"
          extensions={extensions}
          referenceData={null}
          onChange={handleStructureChange}
          doc={doc}
          section={structureEditorContent}
          shadowedChildren={shadowedChildren}
          leftComponent={(section) => <TemplateStructure template={template} onTemplateClassNameChange={handleTemplateClassNameChange} />}
        >
          <NavbarTemplateEditor document={doc} template={template} />
          <TemplateSidebar
            errorMessage={errorMessage}
            style={templateStyle}
            onStyleChange={handleTemplateStyleChange}
            documentStyle={documentStyle}
            onDocumentStyleChange={handleDocumentStyleChange}
          >
            {draftExists && (
              <div className="bg-light p-3 border border-gray-300 mt-r-2-5">
                <div className="font-medium-header pb-r-1">{i18n('template-editor.draft-changes')}</div>
                <button className="btn btn-sm btn-secondary me-r-1" type="button" onClick={handleDiscardDraftClick}>
                  {i18n('template-editor.button.discard-draft')}
                </button>
                <button className="btn btn-sm btn-secondary" type="button" disabled={dirtyDraft} onClick={handleApplyDraftInProjectClick}>
                  {i18n('template-editor.button.update-project')}
                </button>
              </div>
            )}
          </TemplateSidebar>
          <SubNavBar visibile={showSubToolbar}>
            <SubToolbar enabled={toolbarComponents} shadowedChildren={shadowedChildren} />
          </SubNavBar>
        </Editor>
      </div>
    </div>
  );
};
