import React from 'react';
import { Transforms, Element } from 'slate';
import Tippy from '@tippyjs/react';
import { useEditorSelector } from 'app/state/contexts/EditorContext';

const isMention = (node) => Element.isElement(node) && node.type === 'mention';

const mentions = (editor) => {
  const { isInline, isVoid, renderElement } = editor;

  editor.isInline = (element) => isMention(element) || isInline(element);
  editor.isVoid = (element) => isMention(element) || isVoid(element);

  editor.renderElement = (props) => {
    if (isMention(props.element)) {
      return <Mention {...props} />;
    } else {
      return renderElement(props);
    }
  };

  return editor;
};

export const Mention = ({ attributes, children, element }) => {
  const references = useEditorSelector((editor) => editor.references);
  const data = element.data;

  let output = 'Loading..';
  let referenceString = '???';
  const reference = references.find((item) => item.revisionId === data.referenceId);
  if (!!reference) {
    // TODO FIX THIS
    const selection = reference.selectionContent;
    output = selection[data.selection[0]][data.selection[1]].value;
    referenceString = `(${reference.name} - ${data.selection})`;
  }

  return (
    <Tippy content={<p className="gaby-tooltip">{referenceString}</p>} placement="top">
      <span {...attributes} className="reference">
        <span style={{ userSelect: 'none' }} contentEditable={false}>
          {output}
          {children}
        </span>
      </span>
    </Tippy>
  );
};

export const insertMention = (editor, reference, selection) => {
  const data = {
    referenceId: reference.revisionId,
    selection: [selection.start.i, selection.start.j], // always just check the first clicked node even if a range i selected
  };
  const mention = { type: 'mention', data, children: [{ text: '' }] };
  Transforms.insertNodes(editor, mention);
  Transforms.move(editor);
};

export default mentions;
