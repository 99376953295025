import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Dashboard from 'app/views/Dashboard';
import LabelRouter from 'app/views/admin/labels/LabelRouter';
import Lists from 'app/views/admin/lists/ListsRouter';
import MyProfile from 'app/views/myprofile/MyProfile';
import { Organizations } from 'app/views/organizations/Organizations';
import SideBySideEditor from 'app/views/documents/document-viewers/side-by-side-viewer/SideBySideViewer';
import Project from 'app/views/documents/Project';
import { TemplateEditor } from 'app/views/documents/settings/templates/TemplateEditor';
import { PdfViewer } from 'app/views/documents/document-viewers/PdfViewer';
import { DocumentEditor } from 'app/views/documents/document-viewers/document-editor/DocumentEditor';
import Auth from 'auth/Auth';
import PageWrapper from 'app/PageWrapper';
import { Title } from 'app/components';
import AuthWrapper from './AuthWrapper';
const Routes = () => (
  <Switch>
    <Route path="/auth" component={Auth} />
    <Route path="/">
      <AuthWrapper>
        <PageWrapper>
          <Switch>
            <Route path="/documents/:documentId/sections/:sectionId">
              <Title>Xplir Reporting Pilot - Editor</Title>
              <DocumentEditor />
            </Route>
            <Route path="/documents/:documentId/pdf/:documentRevisionId">
              <Title>Xplir Reporting Pilot - PDF</Title>
              <PdfViewer />
            </Route>
            <Route path="/documents/:documentId/templates/:templateId">
              <Title>Xplir Reporting Pilot - Templates</Title>
              <TemplateEditor />
            </Route>
            <Route path="/documents/:documentId">
              <Title>Xplir Reporting Pilot - Details</Title>
              <Project />
            </Route>
            <Route path="/sbs/documents/:documentId/sections/:sectionId">
              <Title>Xplir Reporting Pilot - Compare</Title>
              <SideBySideEditor />
            </Route>
            <Route path="/organizations">
              <Title>Xplir Reporting Pilot - Organization</Title>
              <Organizations />
            </Route>
            <Route path="/myprofile">
              <Title>Xplir Reporting Pilot - Min profil</Title>
              <MyProfile />
            </Route>
            <Route path="/admin/labels">
              <Title>Xplir Reporting Pilot - Labels</Title>
              <LabelRouter />
            </Route>
            <Route path="/admin/lists">
              <Title>Xplir Reporting Pilot - Lists</Title>
              <Lists />
            </Route>
            <Route path="/">
              <Title>Xplir Reporting Pilot - Overview</Title>
              <Dashboard />
            </Route>
          </Switch>
        </PageWrapper>
      </AuthWrapper>
    </Route>
  </Switch>
);

export default Routes;
