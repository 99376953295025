import { i18n } from 'app/utils/i18n';
import { wrapper } from './wrapper-helper';
import { formatDate } from 'app/utils/date';

export function SelectFile(props) {
  const { files, currentReferenceData, onSelectFile } = props;

  return wrapper(
    i18n('reference-data.update-file.modal.title'),
    [],
    () => (
      <>
        <table>
          <tbody>
            {files.map((file) => {
              if (file.revisionId === currentReferenceData.fileRevisionId) {
                return (
                  <tr key={file.revisionId} onClick={() => onSelectFile(file)}>
                    <td>
                      <span className="fs-3 text-blue-800">
                        <i className="fa-regular fa-link"></i> {file.filename}
                      </span>
                      <br />
                      <span className="text-gray-700">{i18n('reference-data.update-file.current-file')}</span>
                    </td>
                    <td style={{ textAlign: 'end' }}>{formatDate(file.createdAt)}</td>
                  </tr>
                );
              } else {
                return (
                  <tr key={file.revisionId} onClick={() => onSelectFile(file)}>
                    <td>{file.filename}</td>
                    <td style={{ textAlign: 'end' }}>{formatDate(file.createdAt)}</td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </>
    ),
    () => <></>
  );
}
