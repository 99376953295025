import React from 'react';
import { IconButton, Loader } from 'app/components';
import { useLists } from 'app/api/foundation/list-queries';
import { i18n } from 'app/utils/i18n';
import { useHistory } from 'react-router-dom';

const Lists = () => {
  const lists = useLists();
  const history = useHistory();

  function handleListClick(list) {
    history.push(`/admin/lists/${list?.code}`);
  }

  function handleNewClick() {
    history.push('/admin/lists/new');
  }

  function renderContent() {
    if (lists?.isLoading || !lists.data) {
      return <Loader />;
    }

    return (
      <table>
        <thead>
          <tr>
            <th>{i18n('lists.code')}</th>
            <th>{i18n('lists.name')}</th>
          </tr>
        </thead>
        <tbody>
          {lists.data.map((item) => {
            return (
              <tr key={item.id} onClick={() => handleListClick(item)}>
                <td>{item.code}</td>
                <td>{item.name}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  return (
    <>
      <div className="content-title columns two mb-2">
        <h1 className="">{i18n('lists.title')}</h1>
        <div className="justify-self-end">
          <IconButton onClick={handleNewClick} icon="plus" />
        </div>
      </div>
      {renderContent()}
    </>
  );
};

export default Lists;
