import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FileZone } from 'app/components';
import { i18n } from 'app/utils/i18n';
import { Loader } from 'app/components';
import moment from 'moment';
import { useXlsxFiles } from './reference-data-queries';
import { useGetDocument } from 'app/api/documents/document-queries';
import { UserContext } from 'app/state/contexts/UserContext';
import { useReferenceList } from './reference-data-queries';

export function FilesListView(props) {
  const { onCloseClick, referenceData, onUploadSuccess } = props;
  const { documentId } = useParams();

  const [fileDetails, setFileDetails] = useState();

  const { selectedOrganization } = useContext(UserContext);
  const { data: document } = useGetDocument(documentId, selectedOrganization);
  const referenceList = useReferenceList(document);
  const xlsxFiles = useXlsxFiles(documentId);

  if (!xlsxFiles.isSuccess || !referenceData.isSuccess || !referenceList.isSuccess) {
    return <Loader />;
  }

  const setFile = (e, file) => {
    e.stopPropagation();
    setFileDetails(file);
  };

  if (!!fileDetails) {
    return (
      <div className="detail-view reference-data">
        <div className="details--info">
          <div className="area--title">
            <div
              className="back"
              onClick={() => {
                setFileDetails(null);
              }}
            >
              <i className="fa-regular fa-chevron-left" />
            </div>
            <p>{fileDetails.filename}</p>
          </div>
          <div className="area--main">
            <dl className="description-list">
              <dt>{i18n('reference-data.file.type')}</dt>
              <dd>{fileDetails.type}</dd>
              <dt>{i18n('reference-data.file.filesize')}</dt>
              <dd>-</dd>
              <dt>{i18n('reference-data.file.updated-by')}</dt>
              <dd>-</dd>
              <dt>{i18n('reference-data.file.updated-at')}</dt>
              <dd>-</dd>
              <dt>{i18n('reference-data.file.uploaded-by')}</dt>
              <dd>-</dd>
              <dt>{i18n('reference-data.file.uploaded-at')}</dt>
              <dd>{moment(fileDetails.createdAt).format('YYYY-MM-DD HH:mm')}</dd>
            </dl>
            <h3>{i18n('reference-data.file.used-in')}</h3>
            <div className="pill-box">
              {referenceList.data
                .filter((reference) => reference.fileRevisionId === fileDetails.revisionId)
                .map((reference) => {
                  return (
                    <div className="pill" key={reference.revisionId}>
                      {reference.name}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    );
  }

  const sortedList = xlsxFiles.data.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
  return (
    <div>
      <div className="detail-view reference-data">
        <div className="close-info-btn" onClick={onCloseClick}>
          <i className="fa-regular fa-xmark" />
        </div>
        <div>
          <div className="fs-3 py-r-1 px-r-1-25">{i18n('reference-data.files.title')}</div>
          <div className="img-wrapper">
            <FileZone type="xlsx" documentId={documentId} onSuccess={onUploadSuccess} />
          </div>
        </div>
        <div className="list">
          {sortedList.map((file) => (
            <div
              key={file.revisionId}
              className={`reference-item ${file.superId === referenceData?.fileSuperId && 'active-reference-item'}`}
              onClick={(e) => setFile(e, file)}
            >
              <div>
                <div className="file-details-item-wraper columns col-auto-1fr">
                  {file.superId === referenceData?.fileSuperId && <i className="fa-regular fa-link" />}
                  <p className="file-name">{file.filename}</p>
                </div>
                <p className="date-text">{moment(file.createdAt).format('D MMM. YYYY, HH:mm')}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
