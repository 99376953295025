const UserCircle = ({ user }) => {
  return (
    <div className="bg-blue-300 rounded-circle aspect-1x1 wr-2-5 d-flex align-items-center justify-content-center">
      <span>
        {user.firstName[0].toUpperCase()}
        {user.lastName[0].toUpperCase()}
      </span>
    </div>
  );
};

export default UserCircle;
