import { Spinner } from 'app/components';
import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';

const CustomToggle = React.forwardRef(({ onClick }, ref) => (
  <button
    style={{ marginLeft: 'auto' }}
    className="bg-transparent border-0 me-2 wr-2 aspect-1x1 d-flex align-items-center justify-content-center rounded-circle hover-bg-blue-300"
    ref={ref}
    onClick={(e) => {
      e.stopPropagation();
      e.preventDefault();
      onClick(e);
    }}
  >
    <i className="fa-regular fa-ellipsis-v" />
  </button>
));

const CustomMenu = React.forwardRef(({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
  return (
    <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
      <ul className="list-unstyled mb-0">{children}</ul>
    </div>
  );
});

function DetailsMenu({ items }) {
  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} />
      <Dropdown.Menu className="border-gray-300 rounded-sm" as={CustomMenu}>
        {items.map((item) => (
          <Dropdown.Item key={item.title} className="px-3 py-2 d-flex align-items-center" onClick={item.onClick}>
            {item.icon ? <i className={`fa-regular fa-${item.icon} me-2`} /> : null}
            <div>{item.title}</div>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

const SimpleTable = ({ headers, rows, isLoading, className }) => {
  return (
    <div className={`simple-table ${className}`} style={{ gridTemplateColumns: `4fr repeat(${headers.length - 1}, 1fr)` }}>
      <div style={{ display: 'contents' }}>
        {headers.map((header) => (
          <div key={header.key} className="cell" style={{ backgroundColor: 'transparent' }}>
            {header.component}
          </div>
        ))}
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        rows.map((row) => (
          <Link className="link" to={row.linkTo} key={row.key}>
            {row.cells.map((cell) => (
              <div key={cell.key} className={`cell ${row.deleted ? 'bg-gray-300' : ''}`}>
                {cell.component}
              </div>
            ))}
            {row.dropdownItems && !row.disabled ? (
              <div className={`cell ${row.deleted ? 'bg-gray-300' : ''}`}>
                <DetailsMenu items={row.dropdownItems} />
              </div>
            ) : null}
          </Link>
        ))
      )}
    </div>
  );
};

export default SimpleTable;
