import { Node, Transforms, Element, Editor as SlateEditor } from 'slate';
import { ReactEditor } from 'slate-react';
import { v4 as uuidv4 } from 'uuid';
import { evolveUuids } from 'app/utils/helper-functions';
import { defaultParagraph } from './paragraph';
import Page from 'app/slate/components/page/Page';

export const isPage = (node) => Element.isElement(node) && node.type === 'page';

const page =
  (options = {}) =>
  (editor) => {
    const withButtons = options.withButtons ?? false;
    const { insertBreak, normalizeNode, renderElement } = editor;

    editor.insertBreak = (...args) => {
      console.log('insert break', args);
      const [pNode] = SlateEditor.first(editor, editor.selection.anchor);
      const pDomNode = ReactEditor.toDOMNode(editor, pNode);
      console.log('check break at', pNode, pDomNode);

      insertBreak(...args);
    };

    const normalizeTopLevel = (entry) => {
      // TODO make sure all top level elements are pages
      normalizeNode(entry);
    };

    const normalizePage = (entry) => {
      const [node, path] = entry;
      if (node.uuid) {
        normalizeNode(entry);
        return;
      }

      Transforms.setNodes(
        editor,
        {
          uuid: uuidv4(),
        },
        {
          at: path,
        }
      );
    };

    editor.normalizeNode = (entry) => {
      const [node, path] = entry;
      if (path === 0) {
        normalizeTopLevel(entry);
      } else if (isPage(node)) {
        normalizePage(entry);
      } else {
        normalizeNode(entry);
      }
    };

    editor.renderElement = (props) => {
      if (isPage(props.element)) {
        return <Page {...props} withButtons={withButtons} />;
      } else {
        return renderElement(props);
      }
    };

    return editor;
  };

export const emptyPage = (revisionId) => ({
  type: 'page',
  uuid: uuidv4(),
  revisionId,
  children: [
    {
      type: 'layout',
      children: [defaultParagraph(revisionId)],
    },
  ],
});

export const insertPage = (editor) => {
  if (!editor.selection) {
    return;
  }
  const [currentPage, path] = SlateEditor.above(editor, {
    at: editor.selection.anchor,
    match: (node) => isPage(node),
  });

  const { value: lastLeaf } = Node.texts(currentPage, { reverse: true }).next();
  const point = { path: [...path, ...lastLeaf[1]], offset: lastLeaf[0].text.length };
  Transforms.insertNodes(editor, emptyPage(), {
    at: point,
    match: (node) => isPage(node),
  });

  Transforms.setSelection(editor, { anchor: { path: [1, 0, 0], offset: 0 }, focus: { path: [1, 0, 0], offset: 0 } });
};

export const prependPage = (editor) => {
  Transforms.insertNodes(editor, emptyPage(), {
    at: [0],
    match: (node) => isPage(node),
  });

  Transforms.setSelection(editor, { anchor: { path: [1, 0, 0], offset: 0 }, focus: { path: [1, 0, 0], offset: 0 } });
};

export const insertTemplate = (editor, template, index) => {
  const content = evolveUuids(template.structure);
  Transforms.insertNodes(editor, content, {
    at: [index],
    match: (node) => isPage(node),
  });

  editor.onInsertTemplate?.(template);
  // After page insert, popper does not recalculate position of "Add new page" buttons. scrollBy() fixes that
  window.scrollBy(0, 1);
};

export default page;
