import React, { useEffect } from 'react';
import { i18n } from 'app/utils/i18n';
import { Modal } from 'app/components';
import { useCloneDocument } from 'app/api/documents/document-queries';

const ListItem = ({ labelKey = '', type = 'success' }) => {
  return (
    <li className={`mb-3 position-relative ps-4 ${type === 'success' ? '' : 'text-gray-500'}`}>
      <i className={`fa-regular mr-2 position-absolute ${type === 'success' ? 'fa-check text-success' : 'fa-xmark'}`} style={{ left: 0, top: '5px' }} />{' '}
      {i18n(labelKey)}
    </li>
  );
};

const CloneDocumentModal = ({ show, documentVersion = null, selectedOrganization, onClose }) => {
  const { mutate: cloneDocument, isLoading, isSuccess, isError } = useCloneDocument();

  useEffect(() => {
    if (isSuccess || isError) {
      onClose();
    }
    // eslint-disable-next-line
  }, [isSuccess, isError]);

  const onClickClone = () => {
    cloneDocument({ documentSuperId: documentVersion.documentSuperId, documentRevisionId: documentVersion.documentRevisionId, selectedOrganization });
  };

  return (
    <Modal show={show} modalWrapperClass="size-small height-auto">
      <div className="header d-flex">
        <h3>{i18n('project-details.clone.modal.title')}</h3>
      </div>
      <div className="body mt-5 with-footer">
        <div>
          <p className="mb-4">{i18n('project-details.clone.modal.info')}</p>
          <div className="row">
            <div className="col-sm-6">
              <ul>
                <ListItem labelKey="project-details.clone.sections" />
                <ListItem labelKey="project-details.clone.content" />
                <ListItem labelKey="project-details.clone.data" />
                <ListItem labelKey="project-details.clone.templates" />
              </ul>
            </div>
            <div className="col-sm-6">
              <ul>
                <ListItem labelKey="project-details.clone.users" type="danger" />
                <ListItem labelKey="project-details.clone.versions" type="danger" />
                <ListItem labelKey="project-details.clone.comments" type="danger" />
              </ul>
            </div>
          </div>
        </div>
        <div className="modal-footer mt-5">
          <button className="button clear" onClick={onClose}>
            {i18n('common.button.cancel')}
          </button>
          <button className="btn btn-primary" onClick={onClickClone}>
            {isLoading ? <i className="fa-regular fa-circle-notch fa-spin" /> : null} {i18n('common.button.duplicate')}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CloneDocumentModal;
