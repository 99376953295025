import { useState, useEffect, useContext } from 'react';
import ServerErrorHandler from 'app/ErrorHandler';
import request from 'app/api/request';
import { setTemplates as setReduxTemplates } from 'app/state/redux/documentSlice';
import { useDispatch } from 'react-redux';
import { UserContext } from 'app/state/contexts/UserContext';

export const useTemplates = (documentId = null) => {
  const [templates, setTemplates] = useState(null);
  const { selectedOrganization } = useContext(UserContext);

  const dispatch = useDispatch();
  useEffect(() => {
    if (selectedOrganization === null || documentId === null) {
      return;
    }

    request
      .get(`/gaby/templates?type=template&organization=${selectedOrganization}&documentSuperId=${documentId}&selected=true`)
      .then(({ data: templates }) => {
        setTemplates(templates);
        dispatch(setReduxTemplates(templates));
      })
      .catch(ServerErrorHandler);
  }, [selectedOrganization, documentId]); // eslint-disable-line react-hooks/exhaustive-deps

  return templates;
};
