import React from 'react';
import { i18n } from 'app/utils/i18n';
import { NavLink, useRouteMatch, Switch, Route } from 'react-router-dom';
import Overview from './Overview';
import Insider from './insider/Insider';
import { SupportModal, NavbarDefault } from 'app/components';

const MyProfile = () => {
  const { path } = useRouteMatch();

  return (
    <div className="grid--container full--height columns">
      <NavbarDefault />
      <div className="content">
        <div className="content-title">
          <h1>{i18n('my-profile.title')}</h1>
        </div>
        <div className="menu--layout">
          <NavLink to={path} exact>
            {i18n('my-profile.menu.overview')}
          </NavLink>
          <NavLink to={`${path}/info`}>{i18n('my-profile.menu.insider')}</NavLink>
        </div>
        <Switch>
          <Route path={path} exact>
            <Overview />
          </Route>
          <Route path={`${path}/info`}>
            <Insider />
          </Route>
        </Switch>
      </div>
      <SupportModal />
    </div>
  );
};

export default MyProfile;
