import { i18n } from 'app/utils/i18n';

export function Level({ organization }) {
  const iconClassName = organization ? 'fal fa-user' : 'fal fa-lock';
  const labelKey = organization ? 'theme.level.organization' : 'theme.level.system';
  return (
    <div className="border border-line rounded px-2 py-1 d-flex flex-row align-items-center bg-white">
      <span className="text-gray-900">{i18n(labelKey)}</span>
      <span className="ms-2 text-blue-700">
        <i className={iconClassName}></i>
      </span>
    </div>
  );
}
