import React, { useState, useEffect } from 'react';
import ReactDataSheet from 'libs/react-datasheet';
import { Spinner } from 'app/components';

/**
 * Use this instead of ReactDataSheet so that if the amount of data is large
 * it will show a loader while the component is building.
 */
export function DelayedDataSheet(props) {
  const [component, setComponent] = useState(<Spinner />);

  useEffect(() => {
    const timer = setTimeout(() => {
      setComponent(<ReactDataSheet {...props} />);
    }, 0);
    return () => clearTimeout(timer);
  });

  return component;
}
