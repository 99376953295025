import { useEffect, useState } from 'react';

export function useUiDebug() {
  const [showDebug, setShowDebug] = useState(false);

  useEffect(() => {
    const setTrue = (e) => e.ctrlKey && e.shiftKey && e.altKey && setShowDebug(true);
    const setFalse = () => setShowDebug(false);
    window.addEventListener('keydown', setTrue);
    window.addEventListener('keyup', setFalse);
    return () => {
      window.removeEventListener('keydown', setTrue);
      window.removeEventListener('keyup', setFalse);
    };
  }, [setShowDebug]);

  return showDebug;
}
