import authStore from 'app/utils/authStore';

const IfHasPermission = ({ permission, organizationId, children }) => {
  const hasPermission = authStore.hasPermission(permission, organizationId);

  if (hasPermission) {
    return children;
  }

  return null;
};

export default IfHasPermission;
