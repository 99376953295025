import React, { useState, useContext } from 'react';
import { i18n } from 'app/utils/i18n';
import { UserContext } from 'app/state/contexts/UserContext';
import { InfoBlock, InfoBlockCheckBox, LanguageIcon } from 'app/components';
import config from 'config';
import { Modal } from 'app/components';
import { langs } from 'app/components/LanguageSelector';
import request from 'app/api/request';
import ServerErrorHandler from 'app/ErrorHandler';

const UserProperties = () => {
  const { user } = useContext(UserContext);
  const [showModal, setShowModal] = useState();
  const [selectedLang, setSelectedLang] = useState(user.userProperties?.cultureId || config.defaultLocale);
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLasttName] = useState(user.lastName);
  const [mobilePhone, setMobilePhone] = useState(user.mobilePhone);
  const [spellCheck, setSpellCheck] = useState(user.userProperties.spellCheck || false);
  const [busy, setBusy] = useState(false);

  const [displayName, setDisplayName] = useState(`${user.firstName} ${user.lastName}`);
  const [displayPhone, setDisplayPhone] = useState(user.mobilePhone);
  const [displayLanguage, setDisplayLanguage] = useState(user.userProperties?.cultureId || config.defaultLocale);

  const handleClickEdit = () => {
    setShowModal(true);
  };

  const handleClickCancel = () => {
    setShowModal(false);
  };

  const handleCheckboxChange = (isChecked) => {
    setSpellCheck(isChecked);
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    setBusy(true);

    request
      .put('/registry/users/current-user', {
        firstName,
        lastName,
        mobilePhone,
        telephone: user.telephone,
        email: user.email,
        jobTitle: user.jobTitle,
        userProperties: { cultureId: selectedLang, spellCheck: spellCheck ? 'true' : 'false' },
      })
      .then(() => {
        reloadUser().then(() => {
          setShowModal(false);
        });
      })
      .catch(ServerErrorHandler)
      .finally(() => {
        setBusy(false);
      });
  };

  const reloadUser = () => {
    return new Promise((resolve, reject) => {
      request
        .get('/registry/users/current-user')
        .then((response) => {
          user.firstName = response.data.firstName;
          user.lastName = response.data.lastName;
          user.telephone = response.data.mobilePhone;
          user.spellCheck = (response.data.userProperties?.spellCheck === 'true') | false;
          user.language = response.data.userProperties?.cultureId || config.defaultLocale;

          setDisplayName(`${user.firstName} ${user.lastName}`);
          setDisplayPhone(user.telephone);
          setDisplayLanguage(user.language);
          handleCheckboxChange(user.spellCheck);
          resolve();
        })
        .catch(ServerErrorHandler);
    });
  };

  const handleChange = (event) => {
    setSelectedLang(event.target.value);
  };

  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="my-0">{i18n('my-profile.overview.title')}</h2>
          <button className="btn btn-secondary btn-sm" onClick={handleClickEdit}>
            {i18n('common.edit')} <i className="ms-2 fa-regular fa-gear" />
          </button>
        </div>
        <InfoBlock caption={i18n('my-profile.name')} text={displayName} className="border-bottom border-gray-300" />
        <InfoBlock caption={i18n('my-profile.email')} text={user.email} className="border-bottom border-gray-300" />
        <InfoBlock caption={i18n('my-profile.phone')} text={displayPhone} className="border-bottom border-gray-300" />
        <InfoBlockCheckBox
          caption={i18n('my-profile.spellcheck')}
          isChecked={spellCheck}
          handleCheckboxChange={(e) => handleCheckboxChange(e.target.checked)}
          className="border-bottom border-gray-300"
        />
        <InfoBlock caption={i18n('my-profile.language')}>
          <p className="fs-3 d-flex align-items-center">
            <LanguageIcon lang={displayLanguage} className="me-3" />
            {i18n(`lang.${displayLanguage}`)}
          </p>
        </InfoBlock>
        <Modal titleLabelKey="my-profile.overview.modal.title" show={showModal} onClose={handleClickCancel}>
          <form onSubmit={handleSubmitForm}>
            <div className="mb-4">
              <label htmlFor="firstName" className="form-label">
                {i18n('my-profile.first-name')}
              </label>
              <input type="text" id="firstName" className="form-control" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
            </div>
            <div className="mb-4">
              <label htmlFor="lastName" className="form-label">
                {i18n('my-profile.last-name')}
              </label>
              <input type="text" id="lastName" className="form-control" value={lastName} onChange={(e) => setLasttName(e.target.value)} />
            </div>
            <div className="mb-4">
              <label htmlFor="mobilePhone" className="form-label">
                {i18n('my-profile.mobile-phone')}
              </label>
              <input type="text" id="mobilePhone" className="form-control" value={mobilePhone} onChange={(e) => setMobilePhone(e.target.value)} />
            </div>
            <div className="mb-4">
              <label htmlFor="spellCheck" className="form-label">
                {i18n('my-profile.spell-check')}
              </label>
              <div style={{ display: 'block', marginLeft: '1em' }}>
                <input type="checkbox" id="spellCheck" checked={spellCheck} onChange={(e) => handleCheckboxChange(e.target.checked)} />
              </div>
            </div>
            <div className="mb-4">
              <label htmlFor="lang" className="form-label">
                {i18n('my-profile.lang')}
              </label>
              <select name="lang" id="lang" className="form-select" value={selectedLang} onChange={handleChange}>
                {langs.map((lang) => (
                  <option key={lang.lang} value={lang.lang}>
                    {i18n(lang.labelKey)}
                  </option>
                ))}
              </select>
            </div>
            <div className="d-flex justify-content-end">
              <button className="btn btn-default" type="button" onClick={handleClickCancel}>
                {i18n('common.cancel')}
              </button>
              <button className="btn btn-primary">
                {busy ? <i className="fa-regular fa-spinner fa-spin" /> : null} {i18n('common.save')}
              </button>
            </div>
          </form>
        </Modal>
      </div>
    </div>
  );
};

export default UserProperties;
