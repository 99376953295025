import React, { useState, useEffect } from 'react';
import { useSlate } from 'slate-react';
import { Editor as SlateEditor, Transforms, Location } from 'slate';
import { equals, any, propEq, startsWith } from 'ramda';
import { emptyLayout, isLayout } from 'app/slate/extensions/layout';
import Tippy from '@tippyjs/react';
import { ToolMenu } from 'app/components';
import { i18n } from 'app/utils/i18n';
import { isParagraph } from 'app/slate/extensions/paragraph';

const isPathOpen = (openPaths, path) => {
  const pathOpen = openPaths.find((p) => {
    return p.join('') === path.join('');
  });

  return !!pathOpen;
};

const hasItemChildren = ({ children }) => {
  return !!children && !!children.length && any(propEq('type', 'layout'))(children);
};

const Toggle = ({ item, isOpen }) => {
  const hasChildren = hasItemChildren(item);

  if (hasChildren) {
    return <i className={`wr-1 me-3 fa-regular fa-angle-${isOpen ? 'down' : 'right'}`}></i>;
  }

  return <i className="wr-1 me-3"></i>;
};

const PageClass = ({ template, index, onUpdateClassName }) => {
  const [editing, setEditing] = useState(false);
  const [className, setClassName] = useState(template.className);

  const editor = useSlate();

  const handleEditClick = (e) => {
    e.stopPropagation();
    setEditing(true);
  };

  const handleCancelClick = (e) => {
    e.stopPropagation();
    setClassName(template.className);
    setEditing(false);
  };

  const handleEditClassName = (e) => {
    setClassName(e.target.value);
  };

  const handleUpdateClassName = () => {
    setEditing(false);
    Transforms.setNodes(
      editor,
      {
        class: className,
      },
      {
        at: [index],
      }
    );
    onUpdateClassName(className);
  };

  const handleClickButtonWrapper = (e) => {
    e.stopPropagation();
  };

  if (editing) {
    return (
      <>
        <div className="flex-1">
          <input type="text" className="form-control form-control-sm" value={className} onChange={handleEditClassName} />
        </div>
        <button className="btn ms-auto text-primary" onClick={handleCancelClick}>
          <i className="fa-regular fa-xmark" />
        </button>
        <button className="btn text-primary" onClick={handleUpdateClassName}>
          <i className="fa-regular fa-check" />
        </button>
      </>
    );
  }

  return (
    <>
      <div>
        <span className="badge bg-gray-200 border border-gray-300 m-0 me-1 mb-1">{template.className}</span>
      </div>
      <div className="ms-auto d-flex" onClick={handleClickButtonWrapper}>
        <button className="btn text-primary" onClick={handleEditClick}>
          <i className="fa-regular fa-pen" />
        </button>
      </div>
    </>
  );
};

const ChildClasses = ({ item = {}, path = [], onClickEdit = null, editNode = [], onRemove }) => {
  const arr = item.className?.split(' ') || [];
  const [, editPath] = editNode;
  const editing = equals(path, editPath);

  const [classes, setClasses] = useState(item.className);

  const editor = useSlate();

  const handleEditClick = (e) => {
    e.stopPropagation();
    onClickEdit(item, path);
  };

  const handleCancelClick = (e) => {
    e.stopPropagation();
    onClickEdit({}, []);
  };

  const handleEditClassNames = (e) => {
    setClasses(e.target.value);
  };

  const handleUpdateClassNames = () => {
    Transforms.setNodes(
      editor,
      {
        className: classes,
      },
      {
        at: path,
      }
    );

    onClickEdit({}, []);
  };

  const handleClickButtonWrapper = (e) => {
    e.stopPropagation();
  };

  const handleRemoveClick = () => {
    onRemove([item, path]);
  };

  const menuItems = [{ text: i18n('template.div.dropdown.remove'), icon: 'fa-regular fa-trash-can', onClick: handleRemoveClick }];

  if (editing) {
    return (
      <>
        <div className="flex-1">
          <input type="text" className="form-control form-control-sm" value={classes} onChange={handleEditClassNames} />
        </div>
        <button className="btn ms-auto text-primary" onClick={handleCancelClick}>
          <i className="fa-regular fa-xmark" />
        </button>
        <button className="btn text-primary" onClick={handleUpdateClassNames}>
          <i className="fa-regular fa-check" />
        </button>
      </>
    );
  }

  return (
    <>
      <div>
        {arr.map((cls, index) => {
          return (
            <span key={index} className="badge bg-gray-200 border border-gray-300 m-0 me-1 mb-1">
              {cls}
            </span>
          );
        })}
      </div>
      <div className="ms-auto d-flex" onClick={handleClickButtonWrapper}>
        <button className="btn text-primary" onClick={handleEditClick}>
          <i className="fa-regular fa-pen" />
        </button>
        <Tippy interactive={true} offset={[100, -60]} trigger="click" content={<ToolMenu menuItems={menuItems} />}>
          <button className="btn">
            <i className="fa-regular fa-ellipsis-v more toggle-icon edit-permission-group" />
          </button>
        </Tippy>
      </div>
    </>
  );
};

// TODO: We can replace depth with path length.
const Child = ({ child, index, depth, onClick, openPaths = [], path = [], selectedNode = [], onClickEdit = null, editNode = [], onRemove = null }) => {
  // It is open if the a open path starts with the childs path
  const isOpen = any(startsWith(path))(openPaths);
  const [, selectedPath] = selectedNode;

  const handleItemClick = (e) => {
    e.stopPropagation();

    onClick(child, path);
  };

  const isSelected = equals(selectedPath, path);

  return (
    <ul className={``}>
      <li className={`py-1 ps-3 clickable ${depth < 2 ? 'bg-blue-100 border-bottom border-gray-300' : 'ms-3'}`} onClick={handleItemClick}>
        <div className={`p-1 rounded me-1 d-flex align-items-center ${isSelected && 'bg-info text-light'} `}>
          <Toggle item={child} isOpen={isOpen} />
          <span className="me-5">{child.type === 'layout' ? 'div' : child.type}</span>
          <ChildClasses item={child} path={path} onClickEdit={onClickEdit} editNode={editNode} onRemove={onRemove} />
        </div>
      </li>
      {isOpen && child.children?.map && child.children.filter(propEq('type', 'layout'))
        ? child.children.map((child, i) => {
            if (child.type !== 'layout') {
              return null;
            }
            return (
              <Child
                child={child}
                index={i}
                depth={depth + 1}
                onClick={onClick}
                openPaths={openPaths}
                key={i}
                path={[...path, i]}
                selectedNode={selectedNode}
                onClickEdit={onClickEdit}
                editNode={editNode}
                onRemove={onRemove}
              />
            );
          })
        : null}
    </ul>
  );
};

const PageItem = ({
  page = {},
  index = 0,
  template,
  onTemplateClassNameChange,
  onClickItem,
  openPaths = [],
  selectedNode = [],
  onEditNode,
  editNode,
  onRemove,
}) => {
  return (
    <ul key={index}>
      <li className="ps-3 py-2 bg-blue-100 border-bottom border-gray-300">
        <div className={`p-1 rounded me-1 d-flex align-items-center`}>
          <Toggle item={page} isOpen />
          <span className="me-5">page</span>
          <PageClass template={template} index={index} onUpdateClassName={onTemplateClassNameChange} />
        </div>
      </li>
      {page.children && Array.isArray(page.children)
        ? page.children.map((child, i) => {
            return (
              <Child
                child={child}
                index={i}
                depth={1}
                onClick={onClickItem}
                openPaths={openPaths}
                key={i}
                path={[index, i]}
                selectedNode={selectedNode}
                onClickEdit={onEditNode}
                editNode={editNode}
                onRemove={onRemove}
              />
            );
          })
        : null}
    </ul>
  );
};

const TemplateStructure = ({ template, onTemplateClassNameChange }) => {
  const editor = useSlate();

  const [editNode, setEditNode] = useState([{}, []]);

  const [openPaths, setOpenPaths] = useState([]);
  const [selectedNode, setSelectedNode] = useState([{}, []]);

  useEffect(() => {
    if (!editor.lastSelection) {
      return;
    }

    const generator = SlateEditor.nodes(editor, {
      at: editor.lastSelection,
      match: isLayout,
      mode: 'lowest',
    });

    const [deepestLayoutNode, deepestLayoutPath] = generator.next().value;

    if (deepestLayoutPath.length > 0) {
      setOpenPaths([...openPaths, deepestLayoutPath]);
      setSelectedNode([deepestLayoutNode, deepestLayoutPath]);
    }
    // eslint-disable-next-line
  }, [editor.lastSelection]);

  const handleClickListItem = (item, path) => {
    setSelectedNode([item, path]);

    if (!hasItemChildren(item)) {
      return;
    }

    const isOpen = isPathOpen(openPaths, path);

    if (isOpen) {
      setOpenPaths(openPaths.filter((openPath) => !startsWith(path, openPath)));
    } else {
      setOpenPaths([...openPaths, path]);
    }
  };

  const handleSetEditNode = (item, path) => {
    setEditNode([item, path]);
  };

  const handleAddBefore = () => {
    const [, path] = selectedNode;
    Transforms.insertNodes(editor, emptyLayout(), {
      at: path,
    });
  };

  const handleAddInside = () => {
    const [node, path] = selectedNode;
    const child = node.children.find(isParagraph);
    if (child) {
      Transforms.wrapNodes(editor, emptyLayout({ withParagraph: false, horizontal: !node.className?.includes('horizontal') }), {
        at: [...path, 0],
        split: false,
        match: isParagraph,
      });
    } else {
      Transforms.insertNodes(editor, emptyLayout({ horizontal: !node.className?.includes('horizontal') }), {
        at: [...path, 0],
        split: false,
      });
    }
  };

  const handleAddAfter = () => {
    const [, path] = selectedNode;
    const afterPath = [...path];
    afterPath[afterPath.length - 1]++;
    Transforms.insertNodes(editor, emptyLayout(), {
      at: afterPath,
    });
  };

  const handleRemove = ([, path]) => {
    Transforms.removeNodes(editor, {
      at: path,
      match: (node) => isLayout(node),
      mode: 'lowest',
    });
  };

  const hasSelectedNode = Location.isLocation(selectedNode[1]);

  return (
    <div className="bg-light h-100 border-end border-gray-300 flex-1 me-3 position-relative">
      <h4 className="subheading px-3 pt-4">{i18n('template-editor.layers')}</h4>
      {editor.children.map((pageItem, index) => (
        <PageItem
          page={pageItem}
          key={index}
          index={index}
          onClickItem={handleClickListItem}
          openPaths={openPaths}
          selectedNode={selectedNode}
          onEditNode={handleSetEditNode}
          editNode={editNode}
          onRemove={handleRemove}
          template={template}
          onTemplateClassNameChange={onTemplateClassNameChange}
        />
      ))}
      {hasSelectedNode ? (
        <div id="template-editor-bottom-menu" className="position-fixed bottom-0 w-100 p-1 border-start border-gray-300">
          <span className="me-2">{i18n('template.add-div.title')}</span>
          <button className="btn btn-secondary me-1" onClick={handleAddBefore}>
            Before
          </button>
          <button className="btn btn-secondary me-1" onClick={handleAddInside}>
            Inside
          </button>
          <button className="btn btn-secondary me-1" onClick={handleAddAfter}>
            After
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default TemplateStructure;
