import { NavPills } from 'app/components';
import { Route, Switch, useRouteMatch, useParams } from 'react-router-dom';
import ProjectCSS from './project-css';
import { PageNumbering } from './PageNumbering';
import { AreaSettings } from './AreaSettings';
import { i18n } from 'app/utils/i18n';
import { ThemeConfiguration } from './ThemeConfiguration';
import { usePermissions } from 'app/utils/hooks/document-permission';
import { FontSettings } from './FontSettings';

export const DesignSettings = () => {
  const { path, url } = useRouteMatch();
  const { documentId } = useParams();
  const { documentPermissions } = usePermissions(documentId);

  const items = [
    { text: i18n('project-settings.theme-configuration'), url: `${url}` },
    { text: i18n('project-settings.areas'), url: `${url}/areas` },
    { text: i18n('project-settings.page-numbering'), url: `${url}/page-numbering` },
    { text: i18n('project-settings.fonts'), url: `${url}/fonts` },
  ];

  if (documentPermissions.includes('CODER')) {
    items.push({ text: i18n('project-settings.custom-css'), url: `${url}/css` });
  }

  return (
    <>
      <div className="mt-5">
        <NavPills items={items} className="d-inline-flex" />
      </div>
      <Switch>
        <Route path={`${path}`} exact>
          <ThemeConfiguration documentId={documentId} />
        </Route>
        <Route path={`${path}/areas`}>
          <AreaSettings documentId={documentId} />
        </Route>
        <Route path={`${path}/page-numbering`}>
          <PageNumbering documentId={documentId} />
        </Route>
        <Route path={`${path}/css`}>
          <ProjectCSS documentId={documentId} />
        </Route>
        <Route path={`${path}/fonts`}>
          <FontSettings documentId={documentId} />
        </Route>
      </Switch>
    </>
  );
};
