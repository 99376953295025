import { forwardRef } from 'react';

export const CustomMarkButton = forwardRef(({ icon, css, selected, setSelected }, ref) => {
  return (
    <button
      ref={ref}
      className={`${css} ${selected ? 'active' : ''}`}
      onMouseDown={(event) => {
        event.preventDefault();
        setSelected(!selected);
      }}
    >
      <i className={`fa-regular fa-${icon}`} />
    </button>
  );
});
