import { useState, useEffect, useReducer, useCallback } from 'react';
import { UserContext } from 'app/state/contexts';
import request from 'app/api/request';
import config from 'config';
import { useCurrentUser } from './api/auth/user-queries';
import { useAllLabels } from './api/foundation/labels-queries';
import { populateI18n } from './utils/i18n';
import authStore from 'app/utils/authStore';

const useOrganizations = (user) => {
  const key = user?.id ? `selected_organization_${user.id}` : null;
  const [organizations, setOrganizations] = useState([]);

  const [selectedOrganization, selectOrganization] = useReducer((_, newOrg) => {
    if (key) {
      window.localStorage.setItem(key, newOrg);
    }

    return newOrg;
  });

  useEffect(() => {
    if (key) {
      const savedOrgId = localStorage.getItem(key);
      if (savedOrgId !== null) {
        selectOrganization(parseInt(savedOrgId, 10));
      } else if (organizations.length > 0) {
        selectOrganization(organizations[0].id);
      }
    }
  }, [key, organizations]);

  return {
    organizations,
    setOrganizations,
    selectedOrganization,
    selectOrganization,
  };
};

const PageWrapper = (props) => {
  const [initSuccess, setInitSuccess] = useState(false);
  const { user: currentUser, permissions: currentPermissions } = useCurrentUser()?.data || {};

  const { organizations, setOrganizations, selectedOrganization, selectOrganization } = useOrganizations(currentUser);
  const { data: labels } = useAllLabels(currentUser?.cultureId || currentUser?.userProperties?.cultureId || config.defaultLocale || 'sv');
  useEffect(() => {
    if (currentUser) {
      refreshOrganizations();
      authStore.attributeRoles = currentPermissions.attributeRoles;
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    if (labels) {
      populateI18n(labels);
    }
  }, [labels]);

  const refreshOrganizations = useCallback(() => {
    request
      .get('/gaby/organizations')
      .then((response) => response.data)
      .then((organizations) => {
        setOrganizations(organizations);
        setInitSuccess(true);
      });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!initSuccess || !currentUser || selectedOrganization === undefined || organizations.length < 1) {
    return <div>Loading...</div>;
  }

  const userContextValue = {
    user: currentUser,
    refreshOrganizations,
    selectedOrganization,
    organizations,
    selectOrganization,
  };
  return <UserContext.Provider value={userContextValue}>{props.children}</UserContext.Provider>;
};

export default PageWrapper;
