import { Element as SlateElement, Transforms, Editor as SlateEditor, Node } from 'slate';

export const isBlockActive = (editor, format, styling) => {
  const [match] = SlateEditor.nodes(editor, {
    match: (n) =>
      (!SlateEditor.isEditor(n) && SlateElement.isElement(n) && n.type === format) ||
      (!!n.formatting && !!styling && !!n.formatting['indent'] && !!styling['indent'] && n.formatting.indent === styling.indent) || // indent
      (!!n.formatting && !!styling && !!n.formatting['format'] && !!styling['format'] && n.formatting['format'] === styling.format), // blockquote || formatButton
  });

  return !!match;
};

export const isSimpleBlockActive = (editor, format) => {
  const [match] = SlateEditor.nodes(editor, {
    match: (n) => !SlateEditor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  });

  return !!match;
};

export const isMarkActive = (editor, format) => {
  const marks = SlateEditor.marks(editor);
  return marks ? marks[format] === true : false;
};

export const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    SlateEditor.removeMark(editor, format);
  } else {
    SlateEditor.addMark(editor, format, true);
  }
};

export const toggleBlock = (editor, format, styling) => {
  const isActive = isSimpleBlockActive(editor, format, styling);
  const LIST_TYPES = ['numbered-list', 'bulleted-list'];

  const isList = LIST_TYPES.includes(format);
  Transforms.unwrapNodes(editor, {
    match: (n) => LIST_TYPES.includes(!SlateEditor.isEditor(n) && SlateElement.isElement(n) && n.type),
    split: true,
  });

  if (!editor.selection) return;
  let node = Node.parent(editor, editor.selection.anchor.path);
  let textFormatting = node.formatting ?? {};
  let isAlreadyList = node.type === 'list-item';
  let customFormatting = {};

  if (!!styling) {
    let isIndentPress = !!styling['indent'];
    let isAlreadyIndented = !!textFormatting['indent'];

    let shouldBeIndented = isAlreadyIndented;

    if (isIndentPress) {
      shouldBeIndented = !isAlreadyIndented;
    }

    let isBlockQuotePress = styling['format'] === 'blockquote';
    let isAlreadyBlockQuote = textFormatting.format === 'blockquote';

    let shouldBeBlockQuote = isAlreadyBlockQuote;

    if (isBlockQuotePress) {
      shouldBeBlockQuote = !shouldBeBlockQuote;
    }

    customFormatting = {
      ...textFormatting,
      ...styling,
      indent: shouldBeIndented,
    };

    if (!styling.class) {
      delete customFormatting.class;
    }

    if (!styling.format) {
      delete customFormatting.format;
    }

    if (shouldBeBlockQuote) {
      customFormatting.format = 'blockquote';
    } else {
      if (isAlreadyBlockQuote) {
        customFormatting.format = null;
      }
    }
  }

  customFormatting = {
    ...customFormatting,
    listType: isList ? format : null,
  };

  let formatType = 'paragraph';

  if (isList) {
    if (isAlreadyList) {
      // Changing from numbered to bulleted or vice versa?
      if (!!textFormatting.listType && format !== textFormatting.listType) {
        formatType = 'list-item';
      }
    } else {
      formatType = 'list-item';
    }
  }

  const newProperties = {
    type: formatType,
    formatting: customFormatting,
  };

  Transforms.setNodes(editor, newProperties, {
    at: editor.lastSelection,
  });

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};
