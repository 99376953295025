const FilterButton = ({ title, checked, value, onChange }) => {
  const handleClick = () => {
    onChange({ checked: !checked, value });
  };
  return (
    <button onClick={handleClick} className={`btn ${checked ? 'bg-primary text-white border-primary' : 'bg-transparent border-dark text-black'}`}>
      {title}
    </button>
  );
};

export default FilterButton;
