import React from 'react';
import { i18n } from 'app/utils/i18n';
import { InfoBlock } from 'app/components';

const Insider = () => {
  const handleClickEdit = () => {};

  return (
    <div className="row mt-r-2-5">
      <div className="col-md-6">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="my-0">{i18n('my-profile.insider.title')}</h2>
              <button className="btn btn-secondary btn-sm" onClick={handleClickEdit}>
                {i18n('common.edit')} <i className="ms-2 fa-regular fa-gear" />
              </button>
            </div>
            <InfoBlock caption={i18n('my-profile.insider.name')} text="[Förnamn Efternamn]" className="border-bottom border-gray-300" />
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="my-0">{i18n('my-profile.insider-function.title')}</h2>
              <button className="btn btn-secondary btn-sm" onClick={handleClickEdit}>
                {i18n('common.edit')} <i className="ms-2 fa-regular fa-gear" />
              </button>
            </div>
            <InfoBlock caption={i18n('my-profile.insider.function')} text="[...]" className="border-bottom border-gray-300" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Insider;
