import React from 'react';

const NavItem = ({ item = {}, index, onClick, isActive }) => {
  function onClickItem(e) {
    e.preventDefault();

    onClick(index);
  }
  return (
    <li className="nav-item flex-grow-1">
      <a
        href="/#"
        className={`nav-link text-center ${isActive ? 'active' : 'bg-gray-200 border-gray-300 border-top border-start border-end text-dark'}`}
        onClick={onClickItem}
      >
        {item.text}
      </a>
    </li>
  );
};

const NavTabs = ({ items, onClick, activeIndex = -1 }) => {
  return (
    <ul className="nav nav-tabs">
      {items.map((item, index) => {
        return <NavItem key={index} index={index} item={item} isActive={index === activeIndex} onClick={onClick} />;
      })}
    </ul>
  );
};

export default NavTabs;
