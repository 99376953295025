import React, { useEffect, useState } from 'react';
import { Loader, Pagination } from 'app/components';
import { useLabels } from 'app/api/foundation/labels-queries';
import { i18n } from 'app/utils/i18n';
import { useHistory } from 'react-router-dom';
import LabelDownloadButton from './LabelDownloadButton';
import LabelImportButton from './LabelImportButton';

const Labels = () => {
  const history = useHistory();

  const [keySearch, setKeySearch] = useState('');
  const [textSearch, setTextSearch] = useState('');
  const [page, setPage] = useState(0);

  const { data, isLoading, refetch } = useLabels({ key: keySearch, text: textSearch, page });

  useEffect(() => {
    onSearch();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  function handleListClick(label) {
    history.push(`/admin/labels/${label?.key}`);
  }

  function handleNewClick() {
    history.push('/admin/labels/new');
  }

  function onReset() {
    setTextSearch('');
    setKeySearch('');
    setPage(0);
  }

  function onSearch(e) {
    if (e) {
      e.preventDefault();
    }

    refetch();
  }
  function renderContent() {
    if (isLoading || !data) {
      return <Loader />;
    }

    return (
      <>
        <table>
          <thead>
            <tr>
              <th>{i18n('label.key')}</th>
              <th>{i18n('label.text')}</th>
              <th>{i18n('label.locale')}</th>
              <th>{i18n('label.visibility')}</th>
            </tr>
          </thead>
          <tbody>
            {data.items.map((item) => {
              return (
                <tr key={item.id} onClick={() => handleListClick(item)}>
                  <td>{item.key}</td>
                  <td>{item.text}</td>
                  <td>{item.locale}</td>
                  <td>{item.visibility}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagination onPaginate={(page) => setPage(page)} page={data.page} pageSize={20} />
      </>
    );
  }

  return (
    <>
      <div className="content-title columns two mb-2">
        <h1 className="">{i18n('label.title')}</h1>
        <div className="justify-self-end d-flex">
          <LabelDownloadButton />
          <LabelImportButton />
          <button className="btn btn-primary me-2" onClick={handleNewClick}>
            <i className="fa fa-plus" /> {i18n('labels.new')}
          </button>
        </div>
      </div>
      <form className="search-field-wrapper row align-items-end" onSubmit={onSearch}>
        <div className={'search-field form-group col'}>
          <label className="search-field-label">{i18n('label.key')}</label>
          <input className="form-control" value={keySearch} onChange={(e) => setKeySearch(e.target.value)} />
        </div>
        <div className={'search-field form-group col'}>
          <label className="search-field-label">{i18n('label.text')}</label>
          <input className="form-control" value={textSearch} onChange={(e) => setTextSearch(e.target.value)} />
        </div>
        <div className="col">
          <button className="btn btn-primary">{i18n('common.search')}</button>
          <button className="btn btn-secondary" type="button" onClick={onReset}>
            {i18n('reset.field')}
          </button>
        </div>
      </form>
      {renderContent()}
    </>
  );
};

export default Labels;
