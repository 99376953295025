import moment from 'moment';

const UserList = ({ listTitle, users, onAdd, isAdmin = false }) => {
  return (
    <>
      <div className="project--overview">
        <div className="columns two mb-2">
          <h2>{listTitle}</h2>
          <div className="action--align--left">
            <button className="action--button" onClick={() => onAdd(isAdmin)}>
              <i className="fa-regular fa-plus font-15" />
            </button>
          </div>
        </div>
      </div>
      <table className="permissions-table project-overview-users">
        <thead>
          <tr>
            <td>Förnamn</td>
            <td>Efternamn</td>
            <td>Användarnamn</td>
            <td>Senast inloggad</td>
          </tr>
        </thead>
        <tbody>
          {users?.map((user) => {
            return (
              <tr key={user.id}>
                <td>{user.firstName}</td>
                <td>{user.lastName}</td>
                <td>{user.userName}</td>
                <td>{moment(user.lastVisitTime).format('YYYY-MM-DD HH:mm')}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default UserList;
