import React from 'react';
import { SubNavBar } from 'app/components/editor/navbar/SubNavbar';
import RevisionSelector from './RevisionSelector';
import Tippy from '@tippyjs/react';
import moment from 'moment';
import { TippyDropDown, TippyDropDownContent } from 'app/components/TippyDropdown';

const VersionSelector = ({ versions = [], documentId, handleChangeVersionL, revisionIdLeft, documentL, handleChangeVersionR, revisionIdRight, documentR }) => {
  const documentVersion = (document, revisionId) => {
    if (!document || !versions) {
      return '';
    }
    if (!revisionId) {
      return 'Senaste versionen';
    }
    const version = versions.filter((version) => version.documentRevisionId === revisionId);
    if (version.length === 1) {
      return version[0].name;
    }
    return moment(document.createdAt).format('YYYY-MM-DD HH:mm');
  };

  return (
    <SubNavBar>
      <div className="side-by-side">
        <div className="d-flex align-items-center">
          <div className="me-2">
            <Tippy
              interactive
              trigger="click"
              placement="bottom-start"
              content={
                <TippyDropDownContent className="py-4">
                  <RevisionSelector
                    handleChangeVersion={handleChangeVersionL}
                    revisionId={revisionIdLeft}
                    documentId={documentId}
                    currentDocument={documentL}
                  />
                </TippyDropDownContent>
              }
            >
              <TippyDropDown text={documentVersion(documentL, revisionIdLeft)} />
            </Tippy>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-start">
          <div className="me-2">
            <Tippy
              interactive
              trigger="click"
              placement="bottom-start"
              content={
                <TippyDropDownContent className="py-4">
                  <RevisionSelector
                    handleChangeVersion={handleChangeVersionR}
                    revisionId={revisionIdRight}
                    documentId={documentId}
                    currentDocument={documentR}
                  />
                </TippyDropDownContent>
              }
            >
              <TippyDropDown text={documentVersion(documentR, revisionIdRight)} />
            </Tippy>
          </div>
        </div>
      </div>
    </SubNavBar>
  );
};

export default VersionSelector;
