import React, { useState } from 'react';
import { i18n } from 'app/utils/i18n';
import { useCreateList } from 'app/api/foundation/list-queries';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

const ListNew = () => {
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [busy, setBusy] = useState(false);

  const { mutate: createList } = useCreateList(code, name);
  const history = useHistory();

  function handleSubmit(e) {
    e.preventDefault();
    setIsSubmitted(true);

    if (code && name) {
      setBusy(true);

      createList(
        { code, name },
        {
          onSuccess: (data) => {
            if (data?.code) {
              history.push(`/admin/lists/${data?.code}`);
            }
          },
        }
      );
    }
  }

  return (
    <>
      <div className="content-title">
        <h1 className="">{i18n('lists.title')}</h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label className={'col-form-label'} htmlFor="code">
              {i18n('lists.code')}
            </label>
            <input className="form-control" name="code" id="code" type="text" value={code} onChange={(e) => setCode(e.target.value)} />
            {isSubmitted && !code ? <div className="invalid-feedback d-block">Please enter a code.</div> : null}
          </div>
          <div className="mb-3">
            <label className={'col-form-label'} htmlFor="name">
              {i18n('lists.name')}
            </label>
            <input className="form-control" type="text" name="name" id="name" value={name} onChange={(e) => setName(e.target.value)} />
            {isSubmitted && !name ? <div className="invalid-feedback d-block">Please enter a name.</div> : null}
          </div>

          <div className="mt-4 d-flex justify-content-end">
            <Link className="button clear" to="/admin/lists">
              {i18n('common.cancel')}
            </Link>
            <button className="button light-blue" type="submit" disabled={busy}>
              {i18n('common.save')}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ListNew;
