import request from 'app/api/request';
import { useMutation, useQuery } from 'react-query';
import { showMessage } from 'app/utils/messages';
import { i18n } from 'app/utils/i18n';
import ServerErrorHandler from 'app/ErrorHandler';

export const queryKeys = {
  lists: 'LISTS',
  list: 'LIST',
};

export function useLists() {
  return useQuery(
    [queryKeys.lists],
    async () => {
      const res = await request.get(`/foundation/lists?size=1000`).catch((err) => {
        if (err.status === 404) {
          return null;
        } else {
          throw err;
        }
      });

      return res?.data?._embedded?.userDefinedLists || [];
    },
    {
      onError: ServerErrorHandler,
    }
  );
}

export function useList(id) {
  return useQuery([queryKeys.list, id], {
    queryFn: async () => {
      const res = await request.get(`/foundation/lists/${id}`).catch((err) => {
        if (err.status === 404) {
          return null;
        } else {
          throw err;
        }
      });

      return res?.data;
    },
    onError: ServerErrorHandler,
  });
}

export function useCreateList() {
  return useMutation(
    async ({ code, name }) => {
      return await request
        .post(`/foundation/lists`, { code, name })
        .then((response) => response?.headers?.Location)
        .then((location) => {
          if (location) {
            return request.get(location).then((response) => response.data);
          }

          return;
        });
    },
    {
      onError: ServerErrorHandler,
      onSuccess: (data) => {
        showMessage('success', i18n('lists.created'));
        return data;
      },
    }
  );
}

export function useUpdateList(id) {
  return useMutation(
    async (list) => {
      return await request.put(`/foundation/lists/${id}`, list).then((response) => {
        return response.data;
      });
    },
    {
      onError: ServerErrorHandler,
      onSuccess: (data) => {
        showMessage('success', i18n('lists.updated'));
        return data;
      },
    }
  );
}
