import ServerErrorHandler from 'app/ErrorHandler';
import request from 'app/api/request';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { queryKeys as documentQueryKeys } from 'app/api/documents/document-queries';
import { queryKeys as styleQueryKeys } from 'app/api/style-queries';

const queryKeys = {
  draft: 'draft',
};

export const NO_DRAFT = 'NO_DRAFT';

export function useDraft({ documentSuperId }) {
  return useQuery(
    [queryKeys.draft, documentSuperId],
    async () => {
      const res = await request.get(`/gaby/design-draft/${documentSuperId}?compiled=true`).catch((err) => {
        if (err.status === 404) {
          return null;
        } else {
          throw err;
        }
      });

      return res?.data || NO_DRAFT;
    },
    {
      onError: ServerErrorHandler,
    }
  );
}

export function useSaveDraft() {
  const queryClient = useQueryClient();
  return useMutation(
    async (draft) => {
      await request.post(`/gaby/design-draft?compiled=true`, draft);
    },
    {
      onError: ServerErrorHandler,
      onSuccess: (_, draft) => {
        queryClient.invalidateQueries(queryKeys.draft, draft.documentSuperId);
        queryClient.invalidateQueries(styleQueryKeys.css);
      },
    }
  );
}

export function useApplyDraft() {
  const queryClient = useQueryClient();
  return useMutation(
    async (pDocument) => {
      await request.post(`/gaby/documents/${pDocument.superId}/actions`, {
        action: 'apply_draft',
        revisionId: pDocument.revisionId,
      });
    },
    {
      onError: ServerErrorHandler,
      onSuccess: (_, pDocument) => {
        queryClient.invalidateQueries(queryKeys.draft, pDocument.superId);
        queryClient.invalidateQueries(documentQueryKeys.document, pDocument.superId);
        queryClient.invalidateQueries(styleQueryKeys.css);
      },
    }
  );
}

export function useDiscardDraft() {
  const queryClient = useQueryClient();
  return useMutation(
    async (pDocument) => {
      await request.del(`/gaby/design-draft/${pDocument.superId}`);
    },
    {
      onError: ServerErrorHandler,
      onSuccess: (_, pDocument) => {
        queryClient.invalidateQueries(queryKeys.draft, pDocument.superId);
        queryClient.invalidateQueries(documentQueryKeys.document, pDocument.superId);
        queryClient.invalidateQueries(styleQueryKeys.css);
      },
    }
  );
}
