import useToasts from 'app/useToasts';
import { EventType, dispatchMessage } from './useToasts';

const Toasts = () => {
  const { messages } = useToasts();
  return (
    <div className="floating-message-container">
      {[...messages.entries()].map(([id, message]) => (
        <Toast id={id} message={message} />
      ))}
    </div>
  );
};

const Toast = ({ id, message }) => {
  const handleClose = () => {
    dispatchMessage(EventType.REMOVE_MESSAGE, message);
  };

  return (
    <div key={id} className={'alert fade in floating-message alert-dismissable '}>
      <div className="icon-wrapper">
        <Icon type={message.type} />
      </div>
      <div className="text-wrapper">
        <h4>{message.title}</h4>
        <p>{message.text}</p>
      </div>
      <button type="button" className="close" aria-label="close" onClick={handleClose}>
        <span>&times;</span>
      </button>
    </div>
  );
};

const Icon = ({ type }) => {
  var cls = 'text-info';
  var icon = 'info-circle';

  if (type === 'error') {
    cls = 'text-danger';
    icon = 'exclamation-triangle';
    // Don't auto-hide error messages
  } else if (type === 'success') {
    cls = 'text-success';
    icon = 'check';
  } else if (type === 'warning') {
    cls = 'text-warning';
    icon = 'exclamation-circle';
  }

  return <i className={`${cls} fas fa-${icon}`} />;
};

export default Toasts;
