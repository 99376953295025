import { useCallback, useEffect, useRef, useState } from 'react';
import { useDelayedSave } from 'app/utils/hooks/delayed-save';
import { v4 as uuidv4 } from 'uuid';
import produce from 'immer';
import { childFinder, changedNodesFromOperations } from 'app/slate/utils';
import request from 'app/api/request';
import { setDocument, setIsDirty, setSection } from 'app/state/redux/documentSlice';
import ServerErrorHandler from 'app/ErrorHandler';
import { useEditorDispatch } from 'app/state/contexts/EditorContext';

const useSave = (document, section, sectionRevisionId, translationId) => {
  const operationsRef = useRef([]);
  const [currentRevisionId, setCurrentRevisionId] = useState(null);
  const documentId = document.superId;
  const sectionId = section.superId;
  const editorDispatch = useEditorDispatch();
  useEffect(() => setCurrentRevisionId(section.revisionId), [section.revisionId]);
  const lastSuccessfulSave = useRef();

  const [storeSection] = useDelayedSave(
    useCallback(
      (value, editor) => {
        const revisionId = uuidv4();

        const evolvedValue = produce(value, (draft) => {
          const childFromPath = childFinder(draft);
          // Create new revision IDs for Slate leafs
          changedNodesFromOperations(editor, operationsRef.current).forEach(([, path]) => {
            const child = childFromPath(path);
            child.previousRevisionId = child.revisionId;
            child.revisionId = revisionId;
          });
        });

        // Create new section, with same revisionId as the updated slate leafs
        const newSection = {
          ...section,
          previousRevisionId: currentRevisionId,
          revisionId,
          content: evolvedValue,
          createdAt: undefined,
          documentSuperId: documentId,
        };

        // Reset list of operations
        operationsRef.current = [];

        function saveSection() {
          return request.post(`/gaby/documents/${documentId}/actions?compileSass=true`, {
            action: 'update_section',
            revisionId: currentRevisionId,
            data: newSection,
          });
        }

        function saveLang() {
          const sectionRevisionIdToSave =
            sectionRevisionId || document.content.sections.find((section) => section.targetLanguages?.map((l) => l.superId).includes(sectionId)).revisionId;

          return request
            .post(`/gaby/sections`, newSection)
            .then((res) => res.data)
            .then(() => {
              return request.post(`/gaby/documents/${documentId}/actions?compileSass=true`, {
                action: 'update_section_target_lang',
                revisionId: currentRevisionId,
                data: {
                  parentRevisionId: sectionRevisionIdToSave,
                  targetLanguage: {
                    revisionId: revisionId,
                    superId: newSection.superId,
                  },
                },
              });
            });
        }

        setCurrentRevisionId(revisionId);
        (translationId ? saveLang() : saveSection())
          .then((res) => res.data)
          .then((doc) => {
            editorDispatch(setSection(newSection));
            editorDispatch(setDocument(doc));
            editorDispatch(setIsDirty(false));
            lastSuccessfulSave.current = revisionId;
          })
          .catch((e) => {
            // save failed, roll back to previous ID
            setCurrentRevisionId(lastSuccessfulSave.current);
            ServerErrorHandler(e);
          });
      },
      [document, section, currentRevisionId, documentId, editorDispatch, sectionId, sectionRevisionId, translationId]
    )
  );
  return (newValue, editor) => {
    const mutatingOperations = editor.operations.filter((operation) => operation.type !== 'set_selection');
    if (mutatingOperations.length > 0) {
      operationsRef.current.push(...mutatingOperations);
      editorDispatch(setIsDirty(true));
      storeSection(newValue, editor);
    }
  };
};
export default useSave;
