export const formatDate = (date = '', options = {}) => {
  const defaultOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    withTime: true,
    locale: 'sv-SE',
  };

  const opts = { ...defaultOptions, ...options };

  const formatOptions = { year: opts.year, month: opts.month, day: opts.day };

  if (opts.withTime) {
    Object.assign(formatOptions, { hour: opts.hour, minute: opts.minute, second: opts.second });
  }

  return new Intl.DateTimeFormat(opts.locale, formatOptions).format(new Date(date));
};
