import React, { useEffect, useState } from 'react';
import request from 'app/api/request';
import Calendar from 'react-widgets/Calendar';
import ServerErrorHandler from 'app/ErrorHandler';
import { useParams } from 'react-router';
import { i18n } from 'app/utils/i18n';

const formatDate = (date) => {
  const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
  return new Date(date).toLocaleDateString('sv-SV', options);
};

const VersionListItem = ({ version, onClick, selectedRevisionId, enabled }) => {
  const isSelected = version.documentRevisionId === selectedRevisionId;
  const handleOnClick = () => {
    onClick(version.documentRevisionId);
  };

  if (!enabled) {
    return (
      <li>
        <span className={`dropdown-item cursor-pointer d-flex disabled`}>
          {version.name} ({i18n('revision.selector.not-in-named-version')})
        </span>
      </li>
    );
  }

  return (
    <li>
      <span className={`dropdown-item cursor-pointer d-flex  ${isSelected ? 'text-info justify-content-between' : ''}`} onClick={handleOnClick}>
        {version.name}
        {isSelected ? <i className="fa-regular fa-check" /> : null}
      </span>
    </li>
  );
};

const VersionToggleButton = ({ numVersions, open, toggle }) => {
  if (numVersions < 4) {
    return null;
  }

  const handleClick = () => {
    toggle(!open);
  };

  return (
    <li>
      <span className="dropdown-item cursor-pointer text-info" onClick={handleClick}>
        {open ? (
          <>
            <span className="me-1">{i18n('revision.selector.show-less')}</span> <i className="fa-regular fa-angle-up" />
          </>
        ) : (
          <>
            <span className="me-1">{i18n('revision.selector.show-more')}</span> <i className="fa-regular fa-angle-down" />
          </>
        )}
      </span>
    </li>
  );
};

const VersionList = ({ versions, onClickVersion, selectedRevisionId, versionsWithSection }) => {
  const [showAll, setShowAll] = useState(false);

  return (
    <ul className="px-1">
      {versions
        .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
        .map((version, index) => {
          if (!showAll && index > 2) {
            return null;
          }
          return (
            <VersionListItem
              key={version.documentRevisionId}
              enabled={versionsWithSection.includes(version.documentRevisionId)}
              version={version}
              onClick={onClickVersion}
              selectedRevisionId={selectedRevisionId}
            />
          );
        })}
      <VersionToggleButton numVersions={versions.length} open={showAll} toggle={setShowAll} />
    </ul>
  );
};

const SelectedDate = ({ versions, selectedDate, selectedRevisionId }) => {
  const selectedNamedVersion = versions.find((version) => version.documentRevisionId === selectedRevisionId);

  if (selectedNamedVersion || !selectedDate) {
    return null;
  }

  return (
    <div className="d-flex justify-content-between align-items-center mb-2 ">
      <span className="bg-gray-200 p-2 rounded-1">{formatDate(selectedDate)}</span> <i className="fa-regular fa-check text-info" />
    </div>
  );
};

const CalendarSection = ({ versions, documentId, onChangeVersion, selectedRevisionId }) => {
  const [selectedDate, setSelectedDate] = useState(null);

  const dateUrl = (toDate) => {
    if (!toDate) {
      return '';
    }
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const fromDateUrl = subtractDays(new Date(toDate), 7).toLocaleDateString('sv-SV', options);
    // time matters and 00:00 wont match any changes that day
    const toDateUrl = addDays(new Date(toDate), 1).toLocaleDateString('sv-SV', options);
    return `?fromDate=${fromDateUrl}&toDate=${toDateUrl}`;
  };

  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  const subtractDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
  };

  const handleCalendarSelection = (value) => {
    setSelectedDate(value);

    request
      .get(`/gaby/documents/${documentId}/changes${dateUrl(value)}`)
      .then((res) => res.data)
      .then((docChanges) => {
        const newRevisionId = docChanges[0].revisions[docChanges[0].revisions.length - 1].revisionId;
        if (newRevisionId !== selectedRevisionId) {
          onChangeVersion(newRevisionId);
        }
      });
  };

  return (
    <div className="px-r-1-25">
      <h4 className="subheading">{i18n('revision.selector.date')}</h4>
      <SelectedDate versions={versions} selectedDate={selectedDate} selectedRevisionId={selectedRevisionId} />
      <Calendar max={new Date()} min={new Date(2020, 1, 1)} onChange={(value) => handleCalendarSelection(value)} value={selectedDate} />
    </div>
  );
};

const RevisionSelector = (props) => {
  const { handleChangeVersion, documentId, currentDocument, revisionId } = props;
  const [versions, setVersions] = useState([]);
  const { sectionId } = useParams();
  const [versionsWithSection, setVersionsWithSection] = useState([]);

  const fetchVersion = (documentId) => {
    request
      .get(`/gaby/versions/${documentId}`)
      .then((res) => res.data)
      .then((versions) => {
        if (versions) {
          versions.map((version) => (version.enabled = false));
          setVersions(versions);
        }
      })
      .catch(ServerErrorHandler);
  };

  useEffect(() => {
    // Find which versions include the currently viewed section
    Promise.all(versions.map((version) => request.get(`/gaby/documents/${version.documentRevisionId}?compileSass=true`).then((res) => res.data))).then(
      (documents) => {
        const filteredDocs = documents.filter((document) => document.content?.sections?.some((section) => section.superId === sectionId));
        setVersionsWithSection(filteredDocs.map((doc) => doc.revisionId));
      }
    );
  }, [revisionId, versions, sectionId]);

  useEffect(() => {
    fetchVersion(documentId);
  }, [documentId]);

  if (!currentDocument) {
    return;
  }

  return (
    <>
      <ul className="px-1">
        <li>
          <span className="dropdown-item cursor-pointer" onClick={() => handleChangeVersion('current-draft')}>
            {i18n('revision.selector.last-changes')}
          </span>
        </li>
        <li>
          <span className="dropdown-item cursor-pointer" onClick={() => handleChangeVersion('current-draft')}>
            {i18n('revision.selector.last-login')}
          </span>
        </li>
      </ul>
      <hr className="bg-gray-300" />
      <h4 className="subheading px-r-1-25">{i18n('revision.selector.named-version')}</h4>
      <VersionList versions={versions} onClickVersion={handleChangeVersion} selectedRevisionId={revisionId} versionsWithSection={versionsWithSection} />
      <hr className="bg-gray-300" />
      <CalendarSection versions={versions} documentId={documentId} onChangeVersion={handleChangeVersion} selectedRevisionId={revisionId} />
    </>
  );
};

export default RevisionSelector;
