const DiffedLeaf = (props) => {
  const { renderLeaf, leaf } = props;

  if (leaf.highlight) {
    return <span className={`diffed-change-${leaf.highlight}`}>{renderLeaf(props)}</span>;
  }

  return renderLeaf(props);
};

export default DiffedLeaf;
