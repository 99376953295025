import { Node } from 'slate';
import DiffMatchPatch from 'diff-match-patch';
const dmp = new DiffMatchPatch();

/**
 * Create an object with the text content of nodes
 * @param {{content: object[], references?: {superId: string, selectionContent: object[]}[]}[]} slateNode - A Slate section
 * @returns {Object.<string, {text: string}[]>} an object with nodeId as key, and node text as value
 */
export const serialize = ({ content, references }) => {
  const nodeGenerator = Node.nodes({ children: content });
  const result = {};

  for (const [node] of nodeGenerator) {
    let serialized;
    switch (node.type) {
      case 'list-item':
      case 'heading-one':
      case 'heading-two':
      case 'heading-three':
      case 'paragraph':
      case 'mention':
        serialized = serializeText(node);
        break;
      case 'reference-table':
        serialized = serializeReferenceTable(node, references);
        break;
      default:
    }

    if (serialized) {
      result[getNodeId(node)] = serialized;
    }
  }

  return result;
};

export const getNodeId = (node) => {
  if (node.type === 'reference-table') {
    return node.referenceDataSuperId;
  }

  return node.uuid;
};

const serializeText = (node) => {
  return { type: 'text', text: node.children.map((child) => child.text) };
};

/**
 *
 * @param {object} node
 * @param {{superId: string, selectionContent: object[]}[]} references
 * @returns {{type: "table", data: object[]}}
 */
const serializeReferenceTable = (node, references) => {
  return { type: 'table', data: references?.find((ref) => ref.superId === node.referenceDataSuperId)?.selectionContent };
};

const flattenText = (textObj) => {
  return textObj.text.join('');
};

/**
 * Compares sections, and returns what has happened with nodes in the the left document, compared to the right
 * @param {Object.<string, string>} resultL
 * @param {Object.<string, string>} resultR
 * @returns {Object.<string, {type: "paragraph", state: "missing" | "changed", computedDiff: string}>} object with nodeId as key, and what has changed as value
 */
const compareDocuments = (documentL, documentR) => {
  const diff = {};
  Object.entries(documentL).forEach(([key, val]) => {
    if (!documentR[key]) {
      diff[key] = { type: val.type, state: 'missing', computedDiff: missingDiff(val) };
    } else {
      diff[key] = { type: val.type, state: 'changed', computedDiff: complexDiff(val, documentR[key]) };
    }
  });
  Object.entries(documentR).forEach(([key, val]) => {
    if (!documentL[key]) {
      diff[key] = { type: val.type, state: 'added', computedDiff: addedDiff(val) };
    }
  });

  return diff;
};

const complexDiff = (val1, val2) => {
  if (val1.type !== val2.type) {
    return;
  }

  switch (val1.type) {
    case 'text':
      return compareText(val1, val2);
    case 'table':
      return compareTable(val1, val2);
    default:
  }
};

const compareText = (val1, val2) => {
  const computedDiff = dmp.diff_main(flattenText(val1), flattenText(val2));
  dmp.diff_cleanupSemantic(computedDiff);
  return computedDiff;
};

const compareTable = (val1, val2) => {
  if (!val1?.data || !val2?.data) {
    return [];
  }

  // TODO: Mark left as deleted when table shrinks
  const diffs = val1.data.map((row, rowIdx) =>
    row.map((col, colIdx) => {
      const cell1 = col.rawData?.w;
      const cell2 = val2.data[rowIdx]?.[colIdx]?.rawData?.w;
      if (cell1 && cell2) {
        const diff = dmp.diff_main(cell1, cell2);
        dmp.diff_cleanupSemantic(diff);
        return diff;
      } else if (cell1 === undefined && cell2) {
        return [[-1, cell2]];
      } else if (cell2 === undefined && cell1) {
        return [[1, cell1]];
      } else {
        return [[0, '']];
      }
    })
  );
  return diffs;
};

const missingDiff = (val) => {
  switch (val.type) {
    case 'text':
      return [[-1, val.text.join('')]];
    case 'table':
      return val.data?.map((row) => row.map((col) => [[-1, col.rawData?.w]]));
    default:
  }
};

const addedDiff = (val) => {
  switch (val.type) {
    case 'text':
      return [[1, val.text.join('')]];
    case 'table':
      return val.data?.map((row) => row.map((col) => [[1, col.rawData?.w]]));
    default:
  }
};

/**
 * Calculates the offset between two pages, returns empty if no matching pages
 * @param {object} left - slate section
 * @param {object} right - slate section
 * @param {number} a - left page index
 * @param {number} b - right page index
 * @returns {["left" | "right", number]} returns a size 2 array, first item is 'left' or 'right', the second item is the pages offset
 */
const closestMatch = (left, right, a, b) => {
  const leftPage = left[a];
  const rightPage = right[b];
  for (let counter = 0; a < left.length || b < right.length; a++, b++, counter++) {
    if (a < left.length && left[a].uuid === rightPage.uuid) {
      return ['left', counter];
    }

    if (b < right.length && right[b].uuid === leftPage.uuid) {
      return ['right', counter];
    }
  }
  return [];
};

/**
 * Calculates the offset of pages between sections
 * @param {object} left - the left section
 * @param {object} right - the right section
 * @returns {Object.<string, Object.<string, number>>} an object with of what pages (uuid) are offset between two sections
 */
const pageDiff = (left, right) => {
  const diff = { left: {}, right: {} };
  for (let a = 0, b = 0; a < left.length && b < right.length; a++, b++) {
    if (left[a].uuid === right[b].uuid) {
      continue;
    }

    const [side, offset] = closestMatch(left, right, a, b);
    if (!side) {
      continue;
    }

    if (side === 'left') {
      diff.right[right[b].uuid] = { type: 'page', offset };
      a += offset;
    } else {
      diff.left[left[a].uuid] = { type: 'page', offset };
      b += offset;
    }
  }
  return diff;
};

/**
 *
 * @param {object} left document
 * @param {object} right document
 * @returns {{left: object, right: object, leftSource: Object.<string,string>, rightSource: Object.<string,string>}}
 */
export const generateDiffState = (left, right) => {
  const resultL = serialize(left);
  const resultR = serialize(right);
  const extra = pageDiff(left.content, right.content);

  return {
    left: Object.assign({}, compareDocuments(resultL, resultR), extra.left),
    right: Object.assign({}, compareDocuments(resultR, resultL), extra.right),
    leftSource: resultL,
    rightSource: resultR,
  };
};

export const leafsInRange = (rootPath, parent, from, to, side) => {
  let counter = 0;
  let anchor;
  let focus;

  for (const [node, path] of Node.descendants(parent)) {
    if (!node.text) {
      continue;
    }
    if (counter <= from && from < counter + node.text.length) {
      anchor = { path: [...rootPath, ...path], offset: from - counter };
    }
    if (counter <= to && to <= counter + node.text.length) {
      focus = { path: [...rootPath, ...path], offset: to - counter };
    }
    counter += node.text.length;
  }

  return {
    anchor,
    focus,
    highlight: getDiffClass(side),
  };
};

export const getDiffClass = (side) => {
  return side === 'left' ? 'deleted' : 'added';
};
