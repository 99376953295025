import { NO_DRAFT } from 'app/api/draft-queries';

function entryMatcher(entry) {
  return (e) => {
    switch (entry.type) {
      case 'graphical-variables':
        return e.type === 'graphical-variables';
      case 'document':
        return e.type === 'document';
      default:
        return entry.templateSuperId === e.templateSuperId;
    }
  };
}

function insertIndex(draft, entry) {
  switch (entry.type) {
    case 'graphical-variables':
      return 0;
    case 'document':
      return draft.content.findIndex((c) => c.type === 'graphical-variables') + 1;
    default:
      return draft.content.length;
  }
}

export function createNewDraft(pDocument, draft, entry) {
  const emptyDraft = {
    documentSuperId: pDocument.data.superId,
    documentRevisionId: pDocument.data.revisionId,
    organization: pDocument.data.organization,
    content: [],
  };

  const d = window.structuredClone(draft === NO_DRAFT ? emptyDraft : draft);
  const i = d.content.findIndex(entryMatcher(entry));
  if (i === -1) {
    d.content.splice(insertIndex(d, entry), 0, entry);
  } else {
    d.content.splice(i, 1, entry);
  }
  return d;
}
