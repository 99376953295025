import { useState, useEffect, useLayoutEffect, useRef, useContext } from 'react';
import moment from 'moment';
import request from 'app/api/request';
import { EditorContext } from 'app/state/contexts/EditorContext';
import { i18n } from 'app/utils/i18n';
import { CommentContext } from 'app/state/contexts/CommentContext';

const renderCommentHeader = (author, comment) => {
  return (
    <>
      <div className="comment--header--icon user--circle">
        <div style={{ lineHeight: '2rem' }}>{initials(author)}</div>
      </div>
      <div className="comment--header--author">
        <div className="comment--header--author--name">{author}</div>
        <div className="comment--header--author--date">{moment(comment.updatedAt).format('YYYY-MM-DD HH:mm')}</div>
      </div>
    </>
  );
};

export const useUser = (id) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    request
      .get(`/registry/users/${id}`)
      .then((res) => res.data)
      .then((user) => setUser(user));
  }, [id]);

  return user;
};

export const Response = ({ comment }) => {
  const user = useUser(comment.userId);
  const author = user ? `${user.firstName} ${user.lastName}` : 'Bob Banter';

  return (
    <>
      <div className="comment--header comment-header-small">{renderCommentHeader(author, comment)}</div>
      <div className="comment--message">{comment.message}</div>
    </>
  );
};

const CommentMenu = ({ id, onId, onActionPerformed }) => {
  const { deleteComment } = useContext(CommentContext);
  const { shadowHost } = useContext(EditorContext);

  const actionPerformed = () => {
    onActionPerformed && onActionPerformed();
  };

  const handleClickDelete = () => {
    deleteComment(id, onId, shadowHost);
    actionPerformed();
  };

  const handleClickTransform = () => {
    actionPerformed();
  };

  return (
    <div className="comment--popmenu">
      <ul>
        <li>
          <button onClick={handleClickDelete}>Delete</button>
        </li>
        <li>
          <button onClick={handleClickTransform}>Transform</button>
        </li>
      </ul>
    </div>
  );
};

const initials = (name) =>
  name
    .split(' ')
    .map((w) => w.substr(0, 1))
    .map((c) => c.toUpperCase())
    .join('');

export const Comment = ({ comment, activeCommentId, onCommentClick }) => {
  const { comments, saveDraft, saveResponse } = useContext(CommentContext);
  const { shadowHost } = useContext(EditorContext);
  const draftRef = useRef();
  const [response, setResponse] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);
  const [responseBtnDisabled, setResponseBtnDisabled] = useState(true);
  const user = useUser(comment.userId);

  useLayoutEffect(() => {
    if (!!draftRef.current) {
      draftRef.current.focus();
    }
  }, []);

  const handleResponseChange = (e) => {
    const response = e.target.value;
    setResponse(response);
    setResponseBtnDisabled(!response ? true : false);
  };

  const handleSaveDraft = (e) => {
    e.preventDefault();
    saveDraft({ comment, message: draftRef.current.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    saveResponse({ comment, message: e.target.elements.response.value });
    setResponse('');
    setResponseBtnDisabled(true);
  };

  const author = user ? `${user.firstName} ${user.lastName}` : 'Bob Banter';
  const isActiveComment = activeCommentId === comment.id;
  const filteredComments = comments.filter((response) => response.onType === 'comment' && response.onId === comment.id);

  const element = shadowHost.shadowRoot.querySelector(`#anchor-${comment.onId}`);

  if (isActiveComment) {
    element?.parentElement?.classList.add('layout-leaf-comment');
  }

  return (
    <div className={isActiveComment ? 'comment active' : 'comment'} onClick={onCommentClick}>
      <div className="comment--header">
        {renderCommentHeader(author, comment)}
        <div className="comment--header--menu">
          <button type="button" className="not-a-button comment-solved-icon">
            <i className="fa-regular fa-check" />
          </button>
          <button type="button" className="not-a-button" onClick={() => setMenuOpen(!menuOpen)}>
            <i className="fa-regular fa-ellipsis-v" />
          </button>
          {menuOpen ? <CommentMenu id={comment.id} onId={comment.onId} onActionPerformed={() => setMenuOpen(false)} /> : null}
        </div>
      </div>
      {comment.message && <div className="comment--citation">{comment.message}</div>}
      {comment.draft ? (
        <form className="comment--draft" onSubmit={handleSaveDraft}>
          <textarea ref={draftRef} placeholder={i18n('comments.add-comment.input.placeholder')}></textarea>
          <button>{i18n('comments.add-comment.button.save')}</button>
        </form>
      ) : (
        <>
          <div className="comment--responses">
            {isActiveComment ? (
              filteredComments.map((response) => <Response comment={response} key={response.id} />)
            ) : filteredComments.length > 0 ? (
              <div className="comment--link">{filteredComments.length} SVAR</div>
            ) : null}
          </div>
          {isActiveComment && (
            <div className={`comment--response ${inputFocused ? 'active' : ''}`}>
              <form onSubmit={handleSubmit}>
                <input
                  className="input-variant"
                  name="response"
                  type="text"
                  value={response}
                  onFocus={() => {
                    setInputFocused(true);
                    return true;
                  }}
                  onBlur={() => {
                    setInputFocused(false);
                    return true;
                  }}
                  placeholder={i18n('comments.response.input.placeholder')}
                  onChange={handleResponseChange}
                />
                <button type="submit" disabled={responseBtnDisabled}>
                  {i18n('comments.response.button.save')}
                </button>
              </form>
            </div>
          )}
        </>
      )}
    </div>
  );
};
