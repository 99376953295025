import { isChrome, isChromium, isEdge } from 'react-device-detect';
import { createPortal } from 'react-dom';
import { i18n } from 'app/utils/i18n';

const navbarElement = document.getElementById('navbar-root');

const NavbarPortal = ({ children, fullWidth = false }) => {
  // If element is missing then don't render the navbar
  if (!navbarElement) {
    return null;
  }

  return createPortal(
    isChrome || isChromium || isEdge ? (
      <div className={`header-navbar grid--container ${fullWidth ? '' : 'columns'}`}>
        <div className={`navbar--container ${fullWidth ? '' : 'navbar--content-columns'}`}>{children}</div>
      </div>
    ) : (
      <div className="navbar--wrapper">
        <div className={`header-navbar grid--container ${fullWidth ? '' : 'columns'}`}>
          <div className={`navbar--container ${fullWidth ? '' : 'navbar--content-columns'}`}>{children}</div>
        </div>
        <div className="navbar--unsupported-browser">
          <h3 className="m-2">
            {i18n('navbar.unsupported-browser')}{' '}
            <a target="_blank" rel="noreferrer" href="https://www.google.com/chrome/" style={{ textDecoration: 'underline', fontWeight: 900 }}>
              {i18n('navbar.unsupported-browser.here')}
            </a>{' '}
            {i18n('navbar.unsupported-browser.or-edge')}{' '}
            <a target="_blank" rel="noreferrer" href="https://www.microsoft.com/en-us/edge/download" style={{ textDecoration: 'underline', fontWeight: 900 }}>
              {i18n('navbar.unsupported-browser.here')}.
            </a>
          </h3>
        </div>
      </div>
    ),
    navbarElement
  );
};

export default NavbarPortal;
