import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useParsedSheet, useXlsxFile, useXlsxFiles, useReferenceData, useSelectedData } from '../reference-data-queries';
import { Spinner } from 'app/components';
import { useGetDocument } from 'app/api/documents/document-queries';
import { UserContext } from 'app/state/contexts/UserContext';
import { formatDate } from 'app/utils/date';
import { OldModal } from 'app/components';
import { MissingSheet, findSuitableNewFile } from '../utils';
import { CompareUpdate } from './CompareUpdate';
import { SelectFile } from './SelectFile';
import { SelectSheet } from './SelectSheet';
import { SelectRange } from './SelectRange';

export const MODAL_STATES = {
  select_file: Symbol('select_file'),
  select_sheet: Symbol('select_sheet'),
  select_range: Symbol('select_range'),
  compare_update: Symbol('show_values'),
};

export function UpdateFinancialData(props) {
  const { modalRef, onOpen, onClose, ...innerProps } = props;
  const { documentId, refId } = useParams();
  const referenceData = useReferenceData(refId, documentId);
  const files = useXlsxFiles(documentId);

  if (!referenceData.isSuccess || !files.isSuccess) {
    return <Spinner />;
  }
  const filesSorted = files.data.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
  return (
    <OldModal ref={modalRef} onOpen={onOpen} onClose={onClose}>
      <UpdateFinancialDataInner {...innerProps} files={filesSorted} currentReferenceData={referenceData.data} />
    </OldModal>
  );
}

function UpdateFinancialDataInner(props) {
  const { onAbort, onSuccess, currentReferenceData, files } = props;
  const { documentId } = useParams();
  const { selectedOrganization } = useContext(UserContext);
  const tableIsLinked = !!currentReferenceData.fileRevisionId;
  const currentFile = files.find((f) => f.revisionId === currentReferenceData.fileRevisionId);

  const [selectedFile, setSelectedFile] = useState();
  const maybeFile = selectedFile || findSuitableNewFile(currentReferenceData, currentFile, files);
  const [modalState, setModalState] = useState(tableIsLinked && maybeFile ? MODAL_STATES.compare_update : MODAL_STATES.select_file);
  const [selectedSheet, setSelectedSheet] = useState();
  const [selection, setSelection] = useState();

  const getDocument = useGetDocument(documentId, selectedOrganization);
  const { data: pDocument } = getDocument;

  const maybeSelection = selection || currentReferenceData.selection;

  const file = useXlsxFile(maybeFile?.revisionId);
  const selectedXlsx = file.data?.selectedXlsx;
  const sheetOptions = file.data?.sheetOptions;
  const maybeSheet = selectedSheet || currentReferenceData.selection?.sheet || sheetOptions?.[0]?.label;
  const sheetData = useParsedSheet(selectedXlsx, maybeSheet, pDocument?.content.locale);
  const selectedData = useSelectedData(sheetData, maybeSelection, currentReferenceData);

  currentReferenceData.selectionContent?.forEach((row) => row.forEach((cell) => (cell.readOnly = true)));
  selectedData.data?.forEach((row) => row.forEach((cell) => (cell.readOnly = true)));

  const handleNewFileClick = () => setModalState(MODAL_STATES.select_file);
  const handleNewSelectionClick = () => setModalState(MODAL_STATES.select_range);
  const handleSaveSelectionClick = () => {
    setModalState(MODAL_STATES.compare_update);
  };
  const handleSelectFile = (file) => {
    const tableIsLinked = !!currentReferenceData.fileRevisionId;
    if (tableIsLinked) {
      setModalState(MODAL_STATES.compare_update);
    } else {
      setModalState(MODAL_STATES.select_sheet);
    }
    setSelectedFile(file);
  };
  const handleSelectSheet = (sheet) => {
    setModalState(MODAL_STATES.select_range);
    setSelectedSheet(sheet);
  };
  const handleChooseSheetClick = () => {
    setModalState(MODAL_STATES.select_sheet);
  };

  const isFetching = [sheetData, selectedData, file].some((l) => l.isFetching);

  useEffect(() => {
    if (isFetching) {
      return;
    }
    /* eslint-disable no-fallthrough */
    // Fallthrough, push the user as far back as needed in the modal process
    switch (modalState) {
      case MODAL_STATES.compare_update:
        !selectedData.isSuccess && !selectedData.isLoading && setModalState(MODAL_STATES.select_range);
      case MODAL_STATES.select_range:
        const sheetIsMissing = sheetData.isError && sheetData.error instanceof MissingSheet;
        sheetIsMissing && setModalState(MODAL_STATES.select_sheet);
      default:
      // Nothing further
    }
    /* eslint-enable no-fallthrough */
  }, [modalState, sheetData, selectedData, isFetching]);

  switch (modalState) {
    case MODAL_STATES.compare_update:
      return (
        <CompareUpdate
          pDocument={pDocument}
          selectedData={selectedData}
          sheetData={sheetData}
          currentReferenceData={currentReferenceData}
          currentFile={currentFile}
          maybeFile={maybeFile}
          maybeSelection={maybeSelection}
          maybeSheet={maybeSheet}
          onNewFileClick={handleNewFileClick}
          onNewSelectionClick={handleNewSelectionClick}
          onAbortClick={onAbort}
          onSaveSuccess={onSuccess}
        />
      );

    case MODAL_STATES.select_file:
      return <SelectFile files={files} currentReferenceData={currentReferenceData} onSelectFile={handleSelectFile} />;

    case MODAL_STATES.select_sheet:
      return (
        <SelectSheet
          file={file}
          maybeFile={maybeFile}
          sheetOptions={sheetOptions}
          currentReferenceData={currentReferenceData}
          onNewFileClick={handleNewFileClick}
          onSelectSheet={handleSelectSheet}
        />
      );

    case MODAL_STATES.select_range:
      return (
        <SelectRange
          sheetData={sheetData}
          maybeFile={maybeFile}
          onNewFileClick={handleNewFileClick}
          onChooseSheetClick={handleChooseSheetClick}
          setSelection={setSelection}
          onAbortClick={onAbort}
          onSaveSelectionClick={handleSaveSelectionClick}
        />
      );

    default:
      throw new Error('Trying to show invalid modal state');
  }
}

export function CurrentFileBlob({ file, children }) {
  return (
    <div className="file-info bg-blue-200 align-items-center">
      <div>
        {file && (
          <>
            <span className="fs-3 text-blue-800">
              <i className="fa-regular fa-link"></i> {file.filename}
            </span>
            <br />
            <span className="text-gray-700">{formatDate(file.createdAt)}</span>
          </>
        )}
      </div>
      <div className="d-flex gap-r-1">{children}</div>
    </div>
  );
}
