import React, { useState, useRef } from 'react';
import { i18n } from 'app/utils/i18n';
import { InfoBlock } from 'app/components';
import Modal, { ModalContentWrapper } from 'app/components/OldModal';
import ServerErrorHandler from 'app/ErrorHandler';
import request from 'app/api/request';

const ChangePassword = () => {
  const modal = useRef();

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword1, setNewPassword1] = useState('');
  const [newPassword2, setNewPassword2] = useState('');
  const [busy, setBusy] = useState(false);

  const handleClickEdit = () => {
    modal.current.open();
  };

  const handleClickCancel = () => {
    modal.current.close();
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    setBusy(true);
    request
      .patch('/registry/users/current-user/password', {
        currentPassword: currentPassword,
        newPassword1: newPassword1,
        newPassword2: newPassword2,
      })
      .then((response) => {
        if (response.status === 204) {
          modal.current.close();
          setCurrentPassword('');
          setNewPassword1('');
          setNewPassword2('');
        }
      })
      .catch(ServerErrorHandler)
      .finally(() => {
        setBusy(false);
      });
  };

  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="my-0">{i18n('my-profile.password.title')}</h2>
          <button className="btn btn-secondary btn-sm" onClick={handleClickEdit}>
            {i18n('my-profile.change-password.title')} <i className="ms-2 fa-regular fa-gear" />
          </button>
        </div>
        <InfoBlock caption={i18n('my-profile.password')} text="********" />
      </div>
      <Modal ref={modal}>
        <ModalContentWrapper titleLabelKey="my-profile.password.modal.title">
          <form onSubmit={handleSubmitForm}>
            <div className="mb-4">
              <label htmlFor="currentPassword" className="form-label">
                {i18n('my-profile.current-password')}
              </label>
              <input
                type="password"
                id="currentPassword"
                className="form-control"
                value={currentPassword}
                placeholder={i18n('my-profile.current-password')}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="newPassword1" className="form-label">
                {i18n('my-profile.new-password-1')}
              </label>
              <input
                type="password"
                id="newPassword1"
                className="form-control"
                value={newPassword1}
                placeholder={i18n('my-profile.new-password-1')}
                onChange={(e) => setNewPassword1(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="newPassword2" className="form-label">
                {i18n('my-profile.new-password-2')}
              </label>
              <input
                type="password"
                id="newPassword2"
                className="form-control"
                value={newPassword2}
                placeholder={i18n('my-profile.new-password-2')}
                onChange={(e) => setNewPassword2(e.target.value)}
              />
            </div>

            <div className="d-flex justify-content-end">
              <button className="btn btn-default" type="button" onClick={handleClickCancel}>
                {i18n('common.cancel')}
              </button>
              <button className="btn btn-primary">
                {busy ? <i className="fa-regular fa-spinner fa-spin" /> : null} {i18n('common.save')}
              </button>
            </div>
          </form>
        </ModalContentWrapper>
      </Modal>
    </div>
  );
};

export default ChangePassword;
