import React, { useContext, useEffect, useState } from 'react';
import { EditorContext } from 'app/state/contexts/EditorContext';
import { DiffContext } from 'app/state/contexts/DiffContext';
import { useParams } from 'react-router-dom';
import { useRevisionL, useRevisionR } from './useRevision';
import request from 'app/api/request';
import Accordion from 'react-bootstrap/Accordion';
import Sidebar from './Sidebar';
import moment from 'moment';

const Change = ({ change, select, accordionIdx, changeKey, selected, userHistory, diffState }) => {
  const oppositeChangeBase = diffState?.rightSource[changeKey];
  if (!oppositeChangeBase) {
    return null;
  }

  let opposingSideText;
  switch (change.type) {
    case 'table':
      opposingSideText = tableText(oppositeChangeBase.data);
      break;
    default:
      opposingSideText = oppositeChangeBase.text;
  }

  return (
    <Accordion.Item eventKey={accordionIdx}>
      <Accordion.Header onClick={() => select(change.type, changeKey, change.state === 'missing' ? null : change.index)}>
        <InfoCircle type={change.type} />
        <div className="pt-1 ps-3">
          <div>
            {change.state === 'changed' ? truncate(change.text, 45) : change.state === 'missing' ? truncate(opposingSideText, 45) : 'Sida ' + change.offset}
          </div>
        </div>
      </Accordion.Header>
      <Accordion.Body className="pt-0">
        <div className="fs-9 ms-5 text-gray-700">
          {selected
            ? userHistory.map(({ user, lastChanged }) => (
                <div key={user.userName} className="ms-2">
                  {user.userName}, {new moment(lastChanged).format('YYYY-MM-DD HH:mm')}
                </div>
              ))
            : null}
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
};

const InfoCircle = ({ type }) => {
  let icon = null;

  switch (type) {
    case 'text':
      icon = <i className="fa-regular fa-text"></i>;
      break;
    case 'table':
      icon = <i className="fa-regular fa-table"></i>;
      break;
    default:
      icon = <i className="fa-regular fa-question"></i>;
  }

  return <div className="user--circle text-blue-700">{icon}</div>;
};

const truncate = (str, n) => {
  if (!str || !str?.length) {
    return '';
  }
  return str.length > n ? str.substr(0, n - 1) + '…' : str;
};

const getTextFromDiff = (computedDiff) => {
  if (!computedDiff) {
    return [];
  }

  if (Array.isArray(computedDiff[0]?.[0])) {
    const tableDiff = computedDiff
      .flatMap((row) => row)
      .filter(([[diffType]]) => diffType !== 0)
      .map((cellDiffs) => cellDiffs.map(([_, text]) => text).join(''))
      .map((cellContent) => `[${cellContent}]`)
      .join(', ');

    return [tableDiff];
  }
  return computedDiff.filter(([type, _]) => type === -1).map(([_, text]) => text);
};

const getSelectedSection = (shadowHost, nodeId, index) => {
  const element = shadowHost.shadowRoot.querySelector(`#anchor-${nodeId}`);
  const changes = element?.parentElement.querySelectorAll('.diffed-change-added');

  if (!changes) {
    return null;
  }
  if (changes.length === 0) {
    // Entire paragraph is assumed as changed
    return element?.parentElement?.parentElement;
  }

  return changes[index ?? 0];
};

const calculateChanges = (diffState) => {
  let diffStateEntries = Object.entries(diffState.right);
  if (diffState.left) {
    const leftSide = Object.entries(diffState.left).filter(([_, value]) => value?.type === 'page');
    diffStateEntries = diffStateEntries.concat(leftSide);
  }

  return diffStateEntries
    .filter((it) => Array.isArray(it))
    .flatMap(([key, entry]) => {
      const diffTexts = getTextFromDiff(entry.computedDiff);
      return diffTexts.filter((text) => text?.trim()).map((text, index) => ({ index: diffTexts.length === 1 ? null : index, key, text, ...entry }));
    });
};

export const ParagraphChanges = () => {
  const { diffState, setDiffState } = useContext(DiffContext);
  const { shadowHost } = useContext(EditorContext);
  const [focusedKey, setFocusedKey] = useState(null);
  const [focusedIndex, setFocusedIndex] = useState(null);
  const { documentId, sectionId } = useParams();
  const [revisionL] = useRevisionL();
  const [revisionR] = useRevisionR();
  const [userHistory, setUserHistory] = useState([]);

  useEffect(() => {
    markSelected(focusedKey, focusedIndex);
    return () => removeSelection();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const markSelected = (nodeId, index) => {
    removeSelection();

    setDiffState(({ right, ...restOfDiff }) => Object.assign({}, restOfDiff, { right: { ...right, [nodeId]: { ...right[nodeId], selected: true } } }));
    const innerElement = getSelectedSection(shadowHost, nodeId, index);
    innerElement?.classList?.add('diffed-change-selected');
    innerElement?.classList?.remove('diffed-change-added');
    setFocusedKey(nodeId);
    setFocusedIndex(index);

    const y = innerElement?.getBoundingClientRect().top + window.pageYOffset - 160;
    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  const removeSelection = () => {
    setDiffState(({ right, ...restOfDiff }) => Object.assign({}, restOfDiff, { right: { ...right, [focusedKey]: { ...right[focusedKey], selected: false } } }));

    const element = shadowHost.shadowRoot.querySelector(`.diffed-change-selected`);
    element?.classList?.remove('diffed-change-selected');
    element?.classList?.add('diffed-change-added');

    setFocusedKey(null);
    setFocusedIndex(null);
  };

  const selectChange = (type, nodeId, index) => {
    removeSelection();
    request
      .get(`/gaby/documents/${documentId}/changes/${sectionId}?fromRevision=${revisionL}&toRevision=${revisionR}&${type}Id=${nodeId}`)
      .then((res) => setUserHistory(res.data))
      .then(() => {
        const newDiff = Object.assign({}, ...diffState, { [nodeId]: diffState[nodeId] }, { [nodeId]: { state: 'selected' } });
        setDiffState(newDiff);
      })
      .then(markSelected(nodeId, index));
  };

  if (!diffState.right) {
    return;
  }
  const changes = calculateChanges(diffState);

  const isSelected = (key, index) => {
    if (focusedKey === key && (focusedIndex === null || focusedIndex === index)) {
      return true;
    }

    return false;
  };

  const getSelectedIndex = () => {
    return changes.findIndex((change) => change.key === focusedKey && change.index === focusedIndex);
  };

  return (
    <div>
      <Sidebar.Header>
        <div className="fw-bold">Ändringar</div>
        <div className="text-gray-800 fs-7">
          {getSelectedIndex() + 1}/{changes.length}
        </div>
      </Sidebar.Header>
      <Accordion flush>
        {changes.map((change, idx) => (
          <Change
            key={idx}
            accordionIdx={idx}
            change={change}
            changeKey={change.key}
            selected={isSelected(change.key, change.index)}
            userHistory={userHistory}
            select={selectChange}
            diffState={diffState}
          />
        ))}
      </Accordion>
    </div>
  );
};

const tableText = (data) => {
  return data
    ?.flatMap((row) => row.map((col) => col.rawData?.w))
    .map((cell) => `[${cell}]`)
    .join(', ');
};
