import { TippyMenu } from 'app/components';
import { i18n } from 'app/utils/i18n';
import { useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { useSaveTemplate, useDeleteTemplate, useCopyTemplate } from 'app/api/template-queries';
import { UserContext } from 'app/state/contexts';
import { Modal } from 'app/components';
import authStore from 'app/utils/authStore';
import { Level } from '../Level';
import { usePermissions } from 'app/utils/hooks/document-permission';
const PreviewImage = ({ organization, previewImage }) => {
  return (
    <div className="position-relative">
      {previewImage ? (
        <img className="rounded-top" src={`data:image/png;base64,${previewImage}`} alt="template preview" />
      ) : (
        <img className="rounded-top" src="/client/static/templates/structure-fallback.png" alt="template preview" />
      )}
      <div className="position-absolute bottom-0 end-0 me-2 mb-1">
        <Level organization={organization} />
      </div>
    </div>
  );
};

const RenameModal = ({ onClose, template }) => {
  const [name, setName] = useState(template?.name);

  const { mutate: saveTemplate } = useSaveTemplate();

  const handleChange = (e) => {
    setName(e.target.value);
  };

  const handleSave = (e) => {
    const newTemplate = {
      ...template,
      name,
      revisionId: uuidv4(),
    };

    saveTemplate(newTemplate);
    onClose();
  };

  return (
    <Modal show={true} onClose={onClose}>
      <label className="form-label" htmlFor={`input-name-${template?.superId}`}>
        {i18n('theme.structure.name')}
      </label>
      <input className="form-control" id={`input-name-${template?.superId}`} value={name} onChange={handleChange} />
      <div className="d-flex justify-content-end mt-5">
        <button type="button" className="btn" onClick={onClose}>
          {i18n('common.button.cancel')}
        </button>
        <button type="button" className="btn btn-primary" onClick={handleSave}>
          {i18n('common.button.save')}
        </button>
      </div>
    </Modal>
  );
};

export const StructurePreview = ({ template, onClick, active, onCopy }) => {
  const hasGlobalTemplatePermission = authStore.hasGlobalPermission('TEMPLATE_CREATE');

  const history = useHistory();
  const [templateToRename, setTemplateToRename] = useState(null);
  const { documentId } = useParams();
  const { documentPermissions } = usePermissions(documentId);
  const { mutate: saveTemplate } = useSaveTemplate();
  const { selectedOrganization } = useContext(UserContext);
  const { mutate: deleteTemplate } = useDeleteTemplate();
  const { mutate: copyTemplate } = useCopyTemplate();

  const handleMakeGlobal = (template) => {
    const newTemplate = {
      ...template,
      revisionId: uuidv4(),
      documentSuperId: null,
      organization: null,
    };

    saveTemplate(newTemplate);
  };

  const handleMakeOrganization = (template) => {
    const newTemplate = {
      ...template,
      revisionId: uuidv4(),
      documentSuperId: null,
      organization: selectedOrganization,
    };
    saveTemplate(newTemplate);
  };

  const handleDelete = (template) => {
    deleteTemplate(template);
  };

  const handleCopy = (template) => {
    copyTemplate(
      { ...template, organization: selectedOrganization },
      {
        onSuccess: (copiedTemplate) => onCopy(copiedTemplate),
      }
    );
  };

  const handleCloseRenameModal = () => {
    setTemplateToRename(null);
  };

  const buildMenu = (template) => {
    const items = [];
    items.push({
      text: i18n('templates.dropdown.edit-name'),
      icon: 'fa-pen',
      onClick: () => setTemplateToRename(template),
    });

    items.push({
      text: i18n('templates.dropdown.edit'),
      icon: 'fa-pen',
      onClick: () => history.push(`/documents/${documentId}/templates/${template.superId}`),
    });

    if (template.organization && hasGlobalTemplatePermission) {
      items.push({
        text: i18n('templates.dropdown.make-global-system'),
        icon: 'fa-cloud',
        onClick: () => handleMakeGlobal(template),
      });
    } else if (hasGlobalTemplatePermission) {
      items.push({
        text: i18n('templates.dropdown.make-non-global'),
        icon: 'fa-cloud-slash',
        onClick: () => handleMakeOrganization(template),
      });
    }

    items.push({
      text: i18n('templates.dropdown.delete-template'),
      icon: 'fa-trash',
      onClick: () => handleDelete(template),
    });
    items.push({
      text: i18n('templates.dropdown.copy'),
      icon: 'fa-copy',
      onClick: () => handleCopy(template),
    });
    return items;
  };

  return (
    <>
      <div onClick={onClick} className={`d-flex flex-column align-items-stretch border bg-white rounded ${active ? 'border-blue-800' : ''}`}>
        <button type="button" disabled={onClick ? false : true} className="border-0 p-0 bg-white rounded">
          <PreviewImage organization={template.organization} previewImage={template.thumbnail} />
        </button>
        <div
          className={`px-1 text-black border-top text-left rounded-bottom d-flex justify-content-between align-items-center ${
            active ? 'border-blue-800 bg-blue-400' : 'bg-white'
          }`}
        >
          <div className="px-2 py-2">{template.name}</div>
          {documentPermissions.includes('CODER') && <TippyMenu offset={[100, -50]} menu={buildMenu(template)} />}
        </div>
      </div>
      {templateToRename ? <RenameModal template={templateToRename} onClose={handleCloseRenameModal} /> : null}
    </>
  );
};
