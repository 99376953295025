import clientConfig from 'config';
import moment from 'moment';

var genToken = function (a) {
  return a ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16) : ([1e16] + 1e16).replace(/[01]/g, genToken);
};

// Requests sent in a short timeframe have to share same csrf token since document.cookie is used
// for storing the csrf token for the cookie, and document.cookie is global.
const timeboxedTokenFactory = () => {
  let token = genToken();
  let genTokenTimeout = null;
  return () => {
    clearTimeout(genTokenTimeout);
    genTokenTimeout = setTimeout(() => (token = genToken()), 250);
    return token;
  };
};
const timeboxedToken = timeboxedTokenFactory();

// ------------------------------------------------
// Helper functions

var getReq = function () {
  if (window.XMLHttpRequest) {
    var xhr = new XMLHttpRequest();
    xhr.onerror = function (error) {
      console.error('Unhandled XHR error : ', error);
    };
    return xhr;
  }
  console.error('unable to make an XMLHttpRequest!');
  return false;
};

var isResponseOk = function (req) {
  return req.status === 200 || req.status === 201 || req.status === 202 || req.status === 204;
};

var getBodyAsJson = function (req) {
  if (req.responseText) {
    return JSON.parse(req.responseText);
  } else {
    return {};
  }
};

export const request = {
  config: {
    onSlow: function () {},
    relativeUrls: true,
    baseUrl: null,
    authToken: null,
    mockXhr: null,
  },

  init: function (config) {
    this.config = config;
  },

  setToken: function setToken(token) {
    this.config.authToken = token;
  },

  _doRequest: function (method, url, data, csrfToken) {
    var config = this.config;

    return new Promise(function (resolve, reject) {
      // If the URL starts with http assume its the full thing,
      // otherwise add the root ourselves
      if (url.substr(0, 4) !== 'http') {
        var rootUrl = config.rootUrl;
        if (config.relativeUrls) {
          // Build the URL out of the current page URL
          rootUrl = window.location.protocol + '//' + window.location.host;
        }
        url = rootUrl + url;
      }

      var req = config.mockXhr || getReq();
      var timeoutId;

      var onLoad = function () {
        if (req.readyState === 4) {
          if (isResponseOk(req)) {
            var headers = {
              Location: req.getResponseHeader('Location'),
              Allow: req.getResponseHeader('Allow'),
            };
            var data = req ? req.responseText : '';
            if (req && req.responseText && req.responseText.substr(0, 1) !== '<') {
              data = getBodyAsJson(req);
              data._allow = req.getResponseHeader('Allow');
            }
            resolve({ status: req.status, data: data, headers });
          } else {
            console.error('Request failed', req.status, req.responseText, req.responseURL);
            reject({ status: req.status, request: req });
          }
          if (clientConfig.useSessionTimeoutNotification) {
            refreshSessionTimeoutTimer();
          }
          // either way, clear the onSlow timeout because we got a response
          clearInterval(timeoutId);
        }
      };

      // var onTimeout = function () {
      //   reject({status: 503, request: req});
      // };

      req.onreadystatechange = onLoad;
      // FIXME doesn't work in IE9.
      //req.ontimeout = onTimeout;
      //
      //// timeout after 30 secs
      //req.timeout = 30000;
      //// start a timer for showing when a request is slow...
      if (config.onSlow) {
        timeoutId = setInterval(config.onSlow, 4000);
      }

      req.open(method, url, true);

      // Need to set headers, like with a token? do it here
      // req.setRequestHeader(header,value);
      req.setRequestHeader('Content-Type', 'application/json');
      req.setRequestHeader('Accept', 'application/hal+json, application/json');

      //random

      //Double submit cookie pattern
      if (method === 'POST' || method === 'PUT' || method === 'PATCH' || method === 'DELETE') {
        var token = csrfToken ? csrfToken : timeboxedToken();
        req.setRequestHeader('X-CSRF-TOKEN', token);
        document.cookie = 'CSRF-TOKEN=' + token + '; SameSite=Strict; path=/';
      }

      // Add any headers from the config
      if (config.headers && config.headers.isArray) {
        config.headers.each(function (h) {
          req.setRequestHeader(h.header, h.value);
        });
      }

      // If any body at all is included here IE does a POST, even if we specify GET!!!
      if (data) {
        req.send(JSON.stringify(data));
      } else {
        req.send();
      }
    });
  },

  generateToken: genToken,

  get: function (url) {
    return this._doRequest('GET', url, null);
  },
  post: function (url, data, csrfToken) {
    return this._doRequest('POST', url, data, csrfToken);
  },
  patch: function (url, data, csrfToken) {
    return this._doRequest('PATCH', url, data, csrfToken);
  },
  put: function (url, data, csrfToken) {
    return this._doRequest('PUT', url, data, csrfToken);
  },
  del: function (url, data, csrfToken) {
    return this._doRequest('DELETE', url, data, csrfToken);
  },

  appendQueryParams: function (url, queryparams) {
    if (!queryparams || Object.keys(queryparams).length === 0) {
      return url;
    }
    let paramsString = '?';
    for (const [key, value] of Object.entries(queryparams)) {
      const lastChar = paramsString.charAt(paramsString.length - 1);
      if (lastChar !== '&' && lastChar !== '?') {
        paramsString += '&';
      }
      paramsString += `${key}=${value}`;
    }
    return url + paramsString;
  },
};

export default request;

let callback;
export function setTimeoutCallback(timeoutCallback) {
  callback = timeoutCallback;
}

// to avoid race conditions between setTimeout and setTimeoutCallback we dont call callback directly
function onTimeout(endTime) {
  callback(endTime);
}

// will call callback when its time to show warning (default 5 minutes before session timeout)
let intervalId;
export function refreshSessionTimeoutTimer() {
  if (!clientConfig) {
    return; // we need to wait until config has been fetched so we know timeout time
  }
  const sessionTimeoutTimeInMinutes = clientConfig.sessionTokenTTLinSeconds / 60 || 120;
  const minutesToWarnBeforeSessionTimeout = clientConfig.minutesBeforeShowingTimeoutWarning || 5;
  if (intervalId) {
    clearInterval(intervalId);
  }
  const warningTime = moment().add(sessionTimeoutTimeInMinutes - minutesToWarnBeforeSessionTimeout, 'minutes');
  const timeOutTime = moment().add(sessionTimeoutTimeInMinutes, 'minutes');
  intervalId = setInterval(() => {
    if (moment().isSameOrAfter(warningTime)) {
      onTimeout(timeOutTime);
      clearInterval(intervalId);
    }
  }, 1000);
}
