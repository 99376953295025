import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const useRevisionL = () => {
  const history = useHistory();
  const revisionR = useQuery().get('revisionR');
  const [revisionL, setRevisionL] = useState(useQuery().get('revisionL'));

  useEffect(() => {
    const search = `?revisionL=${revisionL || ''}&revisionR=${revisionR || ''}`;
    history.push({ search, hash: history.location.hash });
  }, [revisionL, revisionR, history]);

  return [revisionL, setRevisionL];
};

export const useRevisionR = () => {
  const history = useHistory();
  const revisionL = useQuery().get('revisionL');
  const [revisionR, setRevisionR] = useState(useQuery().get('revisionR'));

  useEffect(() => {
    const search = `?revisionL=${revisionL || ''}&revisionR=${revisionR || ''}`;
    history.push({ search, hash: history.location.hash });
  }, [revisionL, revisionR, history]);

  return [revisionR, setRevisionR];
};
