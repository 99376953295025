import { useSlate } from 'slate-react';
import { isMarkActive, toggleMark } from 'app/slate/functions';
import { forwardRef } from 'react';

export const MarkButton = forwardRef(({ format, icon, css }, ref) => {
  const editor = useSlate();
  return (
    <button
      ref={ref}
      className={`${css}  ${isMarkActive(editor, format) ? 'active' : ''}`}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <i className={`fa-regular fa-${icon}`} />
    </button>
  );
});
