import { Element } from 'slate';
import { isReferenceTable } from './reference-table';
import { ReferenceTable } from 'app/slate/components/ReferenceTable';

const isImportArea = (node) => Element.isElement(node) && node.type === 'import-area';

const importArea =
  (options = {}) =>
  (editor) => {
    const { isVoid, renderElement } = editor;

    editor.isVoid = (element) => isImportArea(element) || isReferenceTable(element) || isVoid(element);

    editor.renderElement = (props) => {
      if (isReferenceTable(props.element)) {
        return <ReferenceTable {...props} readOnly={options.readOnly} />;
      } else {
        return renderElement(props);
      }
    };

    return editor;
  };

export default importArea;
