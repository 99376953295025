import { Spinner } from 'app/components';
import { useParams } from 'react-router';
import { NO_DRAFT, useApplyDraft, useDiscardDraft, useDraft } from 'app/api/draft-queries';
import { i18n } from 'app/utils/i18n';
import { useGetDocument } from 'app/api/documents/document-queries';
import { useContext } from 'react';
import { UserContext } from 'app/state/contexts';
import { Clickable } from 'app/components/Clickable';
import { useUiDebug } from 'app/utils/hooks/ui-debug';
import { Link } from 'react-router-dom';

export function DraftController() {
  const { documentId } = useParams();
  const { selectedOrganization } = useContext(UserContext);
  const pDocument = useGetDocument(documentId, selectedOrganization);
  const draft = useDraft({ documentSuperId: documentId });
  const { mutate: discardDraft } = useDiscardDraft();
  const { mutate: applyDraft } = useApplyDraft();
  const showDebug = useUiDebug();

  const onApplyDraftClick = () => applyDraft(pDocument.data);
  const onDiscardDraftClick = () => discardDraft(pDocument.data);

  if (draft.isLoading || pDocument.isLoading) {
    return <Spinner />;
  }

  if (draft.isError || pDocument.isError) {
    return <div>Error fetching draft information</div>;
  }

  if (draft.data === NO_DRAFT) {
    return <></>;
  }

  return (
    <div className="position-fixed d-flex flex-column draft-info bg-white">
      <div className="d-flex flex-row py-2 px-3 gap-r-2 justify-content-between align-items-center">
        <Clickable className="text-blue-700 fs-5 text-uppercase d-flex flex-row gap-r-0-5 align-items-center" onClick={onDiscardDraftClick}>
          {i18n('theme.discard-draft')}
          <i className="fa-regular fa-trash" />
        </Clickable>
        <div className="flex-grow-1">&nbsp;</div>
        <Link
          to={`/documents/${documentId}/pdf/${pDocument.data.revisionId}?useDraft=true`}
          className="text-blue-700 fs-5 text-uppercase d-flex flex-row gap-r-1 align-items-center"
          target={'_blank'}
        >
          {i18n('theme.preview-draft')}
          <i className="fa-regular fa-eye" />
        </Link>
        <Clickable className="btn btn-primary" onClick={onApplyDraftClick}>
          {i18n('theme.apply-draft')}
        </Clickable>
      </div>
      {showDebug && (
        <ul style={{ listStyleType: 'disc' }} className="p-4">
          {draft.data.content.map((d) => (
            <li key={d.templateSuperId || d.type}>{d.type}</li>
          ))}
        </ul>
      )}
    </div>
  );
}
