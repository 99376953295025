import { useState, useEffect, useRef, useReducer } from 'react';
import request from 'app/api/request';
import { fromByteArray } from 'base64-js';
import { v4 as uuidv4 } from 'uuid';
import ServerErrorHandler from 'app/ErrorHandler';
import { i18n } from 'app/utils/i18n';
import ConfirmationModal from './ConfirmationModal';

const getTypeSettings = (type) => {
  let descriptionLabel;
  let mimetypes = [];

  switch (type) {
    case 'img':
      descriptionLabel = 'filezone.type.image';
      mimetypes.push('image/png', 'image/jpeg', 'image/webp', 'image/svg+xml');
      break;
    case 'xlsx':
      descriptionLabel = 'filezone.type.excel';
      mimetypes.push('application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');

      break;
    case 'xliff':
      descriptionLabel = 'filezone.type.xliff';
      // No mimemetypes for xliff, due to trouble selecting them with file picker
      break;
    case 'json':
      descriptionLabel = 'filezone.type.json';
      mimetypes.push('application/json', 'text/plain');
      break;
    case 'font':
      descriptionLabel = 'filezone.type.json';
      mimetypes.push('font/ttf', 'font/woff', 'font/woff2', 'application/font-woff', 'font/otf');
      break;
    default:
      descriptionLabel = '-';
  }

  return { descriptionLabel, mimetypes };
};

const reducer = (state, action) => {
  switch (action) {
    case 'ENTERS':
      return { dropDepth: state.dropDepth + 1, isHovering: state.dropDepth + 1 > 0 };
    case 'LEAVES':
      return { dropDepth: state.dropDepth - 1, isHovering: state.dropDepth - 1 > 0 };
    case 'RESET':
      return { dropDepth: 0, isHovering: false };
    default:
      return state;
  }
};

export const FileZoneTemplate = ({ icon, title, subtitle, details, errorMessage, isLoading, isActive }) => (
  <div
    className={`cursor-pointer border-dashed border-1 ${
      isActive ? 'border-blue-800' : 'border-blue-400'
    } bg-blue-200 px-3 py-4 rounded-3 d-flex align-items-center justify-content-center flex-column text-gray-800 text-center h-100`}
  >
    <span className="text-blue-800" style={{ fontSize: '2.7rem' }}>
      <i className={isLoading ? 'fa-regular fa-circle-notch fa-spin' : icon} />
    </span>
    <h1 className="fs-7 fw-light">{title}</h1>
    <p className="fs-7 text-gray-700 mb-0">{subtitle}</p>
    <p className="fs-7 text-gray-700 mb-0">{details}</p>
    {errorMessage ? <p className="text-danger fs-7 mb-0">{errorMessage}</p> : null}
  </div>
);

const FileZone = (props) => {
  const { type, documentId, selectedOrganization, onSuccess, old, url = `/gaby/files` } = props;
  const [processFile, setProcessFile] = useState(false);
  const [isUnsupported, setIsUnsupported] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const [{ isHovering }, addEvent] = useReducer(reducer, { dropDepth: 0, isHovering: false });

  useEffect(() => {
    if (isHovering) {
      setIsUnsupported(false);
    }
  }, [isHovering]);

  const ref = useRef();
  const onDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    addEvent('ENTERS');
  };

  const onDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    addEvent('LEAVES');
  };

  const onFileDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    addEvent('RESET');

    handleFileUpload(e.dataTransfer.files[0]);
  };

  const handleConfirmation = () => {
    setShowConfirmationModal(true);
  };

  const handleConfirmUpload = () => {
    setShowConfirmationModal(false);
    handleFileUpload(selectedFile);
  };

  const handleCancelUpload = () => {
    setShowConfirmationModal(false);
  };

  const handleFileUpload = (file) => {
    const { mimetypes } = getTypeSettings(type);
    props.statusHandler?.setIsLoading(true);
    props.statusHandler?.setError(false);
    if (mimetypes.length && !mimetypes.includes(file.type)) {
      setIsUnsupported(true);
      return;
    }

    setProcessFile(true);

    file
      .arrayBuffer()
      .then((buffer) => {
        const body = {
          superId: old?.superId || uuidv4(),
          revisionId: uuidv4(),
          documentSuperId: documentId,
          organization: selectedOrganization,
          filename: file.name,
          fileType: file.type,
          fileSize: file.size,
          fileContent: fromByteArray(new Uint8Array(buffer)),
          type: type,
        };

        return request.post(url, body);
      })
      .then((result) => {
        props.statusHandler?.setIsLoading(false);
        props.statusHandler?.setError(false);
        onSuccess?.(result, old);
      })
      .then(() => {
        setProcessFile(false);
        setSelectedFile(null);
      })
      .catch((e) => {
        props.statusHandler?.setIsLoading(false);
        props.statusHandler?.setError(e);
        return ServerErrorHandler(e);
      });
  };

  const handleClick = () => {
    setIsUnsupported(false);
    if (ref.current && showConfirmationModal === false) {
      ref.current.click();
    }
  };

  const handleFileInput = (e) => {
    const file = e.target.files[0];
    console.log(file.size);
    if (file.size > 2048000) {
      setSelectedFile(file);
      handleConfirmation();
    } else {
      handleFileUpload(file);
    }
  };

  const { descriptionLabel, mimetypes } = getTypeSettings(type);

  return (
    <div onClick={handleClick} onDragEnter={onDragEnter} onDragOver={onDragOver} onDrop={onFileDrop} onDragLeave={onDragLeave}>
      <FileZoneTemplate
        icon={isHovering ? 'fa-solid fa-folder-plus' : 'fa-regular fa-folder-plus'}
        isActive={isHovering}
        isLoading={processFile}
        title={i18n('filezone.title')}
        subtitle={i18n('filezone.subtitle')}
        details={i18n(descriptionLabel)}
        errorMessage={isUnsupported ? i18n('filezone.filedrop.unsupported') : null}
      />
      <ConfirmationModal
        message={i18n('filezone.size.warning')}
        show={showConfirmationModal}
        onConfirm={() => handleConfirmUpload()}
        onDecline={() => handleCancelUpload()}
      />
      <input onChange={handleFileInput} ref={ref} className="d-none" type="file" accept={mimetypes.join(',')} />
    </div>
  );
};

export default FileZone;
