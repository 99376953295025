import { useState } from 'react';
import { ReactEditor, useSlate } from 'slate-react';
import { Transforms } from 'slate';
import { useEditorSelector } from 'app/state/contexts/EditorContext';
import { useGetDocumentTableOfContent } from 'app/api/documents/document-queries';
import { i18n } from 'app/utils/i18n';
import { Modal } from 'app/components';
import { ImportToC } from 'app/components/editor/import-area';
import { calculatePageOffset } from 'app/slate/components/page/utils';

export const TableOfContent = ({ element, attributes, readOnly, children }) => {
  const { settings } = element;
  const pDocument = useEditorSelector((editor) => editor.pDocument);
  const locale = useEditorSelector((editor) => editor.locale);
  const { data } = useGetDocumentTableOfContent(pDocument.revisionId, {
    start: settings.start,
    stop: settings.stop,
    currentPosition: element.uuid,
    headerHierarchy: settings.levels,
    locale,
  });
  const [show, setShow] = useState(false);
  const editor = useSlate();
  const pageOffset = calculatePageOffset(pDocument.content?.pageNumbering);
  const handleModalClose = () => {
    setShow(false);
  };

  const handleDelete = () => {
    const path = ReactEditor.findPath(editor, element);
    Transforms.removeNodes(editor, { at: path });
  };

  const handleEdit = () => {
    setShow(true);
  };

  return (
    <div className="editor-table-of-content--container" {...attributes}>
      {!readOnly && (
        <>
          <div className="controls--container">
            <button onClick={handleEdit}>
              <i className="fa-regular fa-pen-to-square" />
            </button>
            <button onClick={handleDelete}>
              <i className="fa-regular fa-trash-can" />
            </button>
          </div>
          <Modal titleLabelKey="toc.insert.modal-title" size="lg" show={show} onClose={handleModalClose}>
            <ImportToC onClose={handleModalClose} defaultSettings={settings} superId={element.superId} />
          </Modal>
        </>
      )}
      <div className="editor-table-of-content--content">
        <OrderedList headerList={data?.content} pageOffset={pageOffset} settings={settings} />
      </div>
      {children}
    </div>
  );
};

const OrderedList = ({ headerList, pageOffset, settings }) => {
  const filteredHeaders = headerList?.filter((header) => settings.levels.includes(header.type)) || [];

  if (filteredHeaders.length === 0) {
    return headerList?.map((header) => <OrderedList key={header.uuid} headerList={header.children} settings={settings} />) || null;
  }

  const headerName = (header) => {
    const overrideHeader = settings.overrides.find((override) => override.uuid === header.uuid);
    if (overrideHeader?.text) {
      return overrideHeader.text;
    }

    return header.name || i18n('toc.settings.header.no-permission');
  };

  const checkExclude = (header) => {
    const overrideHeader = settings.overrides.find((override) => override.uuid === header.uuid);
    if (overrideHeader && overrideHeader.excluded === true) {
      return true;
    }
    return false;
  };

  const hasValidHeaders = (headers) => {
    if (!headers || headers.length === 0) return false;
    return headers.some((header) => {
      if (settings.levels.includes(header.type) && !checkExclude(header)) return true;
      return hasValidHeaders(header.children);
    });
  };

  if (!hasValidHeaders(headerList)) return null;

  return (
    <ol style={{ userSelect: 'none' }} contentEditable={false}>
      {headerList.map((header, idx) => {
        if (checkExclude(header) === true) return null;
        return (
          <li key={idx}>
            <div className="toc-item">
              <a href={`#anchor-${header.uuid}`}>
                <span className="toc-header">{headerName(header)}</span>
                <span className="toc-pagenumber">{getPageNumber(header.pageNumber, pageOffset)}</span>
              </a>
            </div>
            <OrderedList headerList={header.children} settings={settings} pageOffset={pageOffset} />
          </li>
        );
      })}
    </ol>
  );
};

const getPageNumber = (pageNumber, offset = 0) => {
  const newPageNumber = pageNumber + offset;
  return newPageNumber < 1 ? '' : newPageNumber;
};
