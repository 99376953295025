import { Element } from 'slate';
import { ReferenceTable } from 'app/slate/components/ReferenceTable';
export const isReferenceTable = (node) => Element.isElement(node) && node.type === 'reference-table';

const referenceTable =
  (options = {}) =>
  (editor) => {
    const { renderElement, isVoid } = editor;
    editor.isVoid = (element) => isReferenceTable(element) || isVoid(element);

    editor.renderElement = (props) => {
      if (isReferenceTable(props.element)) {
        return <ReferenceTable {...props} readOnly={options.readOnly} />;
      } else {
        return renderElement(props);
      }
    };

    return editor;
  };

export default referenceTable;
