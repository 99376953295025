import { useState } from 'react';
import { refHandler } from 'app/utils/helper-functions';
import { AddComment } from 'app/components/editor/sidebar/comments/AddComment';
import { StyledElement } from 'app/slate/components/StyledElement';

export const Paragraph = ({ element, attributes, children, withComments }) => {
  const [ref, setRef] = useState(null);

  const anchor = `anchor-${element.uuid}`;
  return (
    <div {...attributes} ref={refHandler(attributes, setRef)}>
      {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
      <a contentEditable="false" href={`#${anchor}`} id={anchor}></a>
      {withComments && ref ? <AddComment paragraph={ref} element={element} /> : null}
      <StyledElement element={element} attributes={attributes} children={children} />
    </div>
  );
};
