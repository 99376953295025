import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom';
import { i18n } from 'app/utils/i18n';
import { StyledDataSheet } from 'app/components';
import { CurrentFileBlob } from './UpdateReferenceData';
import { wrapper } from './wrapper-helper';
import { useUpdateReferenceData } from '../reference-data-queries';

const findCellDiffs = (table1 = [], table2 = []) => {
  const diffedCells = [];
  const rowCount = Math.max(table1?.length, table2?.length);
  for (let rowIdx = 0; rowIdx < rowCount; rowIdx++) {
    const row1 = table1.at(rowIdx) ?? [];
    const row2 = table2.at(rowIdx) ?? [];

    const colCount = Math.max(row1.length, row2.length);

    for (let colIdx = 0; colIdx < colCount; colIdx++) {
      const col1 = row1.at(colIdx);
      const col2 = row2.at(colIdx);

      const val1 = col1?.value;
      const val2 = col2?.value;

      if (val1 !== val2) {
        diffedCells.push({ row: rowIdx, col: colIdx });
      }
    }
  }

  return diffedCells;
};

export function CompareUpdate(props) {
  const {
    pDocument,
    selectedData,
    sheetData,
    currentReferenceData,
    currentFile,
    maybeFile,
    maybeSelection,
    maybeSheet,
    onNewFileClick,
    onNewSelectionClick,
    onAbortClick,
    onSaveSuccess,
  } = props;
  const { documentId } = useParams();
  const { mutate: updateReferenceData } = useUpdateReferenceData();

  const highlightedCells = findCellDiffs(currentReferenceData.selectionContent, selectedData.data);

  const handleSaveClick = () => {
    const body = {
      name: currentReferenceData.name,
      documentSuperId: documentId,
      fileRevisionId: maybeFile.revisionId,
      fileSuperId: maybeFile.superId,
      selection: {
        ...maybeSelection,
        sheet: maybeSheet,
      },
      selectionContent: selectedData.data,
      superId: currentReferenceData.superId,
      revisionId: uuidv4(),
    };

    updateReferenceData(
      {
        document: pDocument,
        referenceData: body,
      },
      {
        onSuccess: onSaveSuccess,
      }
    );
  };

  return wrapper(
    i18n('reference-data.update-values.modal.title'),
    [selectedData, sheetData],
    () => (
      <div className="d-flex gap-r-2">
        <div>
          <h3>{i18n('reference-data.current-file')}</h3>
          {currentReferenceData.selectionContent ? (
            <>
              <CurrentFileBlob file={currentFile}></CurrentFileBlob>
              <StyledDataSheet data={currentReferenceData.selectionContent} readOnly={true} highlightedCells={highlightedCells} />
            </>
          ) : (
            <p className="text-nowrap">{i18n('reference-data.no-file-linked')}</p>
          )}
        </div>
        <div>
          <h3>{i18n('reference-data.new-file')}</h3>
          <CurrentFileBlob file={maybeFile}>
            <div>
              <div className="d-flex gap-r-1 justify-content-end">
                <div onClick={onNewFileClick}>{i18n('reference-data.change-file')}</div>
                <div onClick={onNewSelectionClick}>{i18n('reference-data.change-selection')}</div>
              </div>
              <div className="text-end font-weight-light text-notransform text-muted">{i18n('reference-data.compare.style-change-notice')}</div>
            </div>
          </CurrentFileBlob>
          <StyledDataSheet data={selectedData.data} readOnly={true} highlightedCells={highlightedCells} />
        </div>
      </div>
    ),
    () => (
      <div className="modal-btn-group">
        <button className="button clear" onClick={onAbortClick}>
          {i18n('common.cancel')}
        </button>
        <button className="button primary" onClick={handleSaveClick}>
          {i18n('common.save')}
        </button>
      </div>
    )
  );
}
