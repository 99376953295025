import Modal from './Modal';
import { i18n } from 'app/utils/i18n';

const ConfirmationModal = ({ message, show = false, onConfirm, onDecline, busy = false }) => {
  return (
    <Modal show={show} onClose={onDecline}>
      <div className="text-center p-5">{message}</div>
      <div className="d-flex justify-content-end mt-4">
        <button onClick={onDecline} type="button" className="btn">
          {i18n('common.cancel')}
        </button>
        <button disabled={busy} type="button" onClick={onConfirm} className="btn btn-danger">
          {i18n('common.confirm')}
        </button>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
