import { useEffect } from 'react';

const Title = (props) => {
  useEffect(() => {
    document.title = props.children || '';
  }, [props.children]);
  return null;
};

export default Title;
