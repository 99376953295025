import React from 'react';
import { Transforms, Editor as SlateEditor } from 'slate';
import { useSlate } from 'slate-react';
import { Loader } from 'app/components';
import { DataImage } from 'app/components';
import { i18n } from 'app/utils/i18n';
import { useEditorSelector } from 'app/state/contexts/EditorContext';
import { useImages } from 'app/api/documents/image-queries';

export const ImportImage = ({ onClose }) => {
  const editor = useSlate();
  const pDocument = useEditorSelector((editor) => editor.pDocument);
  const { data: images } = useImages(pDocument.content.images);

  const handleSelect = (image) => {
    const slateImage = {
      type: 'image',
      imageSuperId: image.superId,
      children: [],
    };
    const point = SlateEditor.end(editor, editor.lastSelection);

    if (point) {
      Transforms.insertNodes(editor, slateImage, {
        at: point,
      });
    }
    onClose();
  };

  return (
    <div className="image--modal">
      {!images ? (
        <Loader />
      ) : (
        <div>
          <div className="image--list">
            {images.length === 0 && <h1 className="text-center">{i18n('imageimport.no-images')}</h1>}
            {images.map((image, i) => (
              <div className="image" key={i} onClick={() => handleSelect(image)}>
                <DataImage {...image} />
                <span>{image.filename}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
