import { useCreateFontStyle, useGetDocument } from 'app/api/documents/document-queries';
import { useDeleteFontStyle, useSaveFontStyle, useStyle } from 'app/api/style-queries';
import { FileZone, Spinner } from 'app/components';
import { UserContext } from 'app/state/contexts';
import { useContext } from 'react';
import { useParams } from 'react-router';
import { CodeBlock } from './project-css';

export function FontSettings(props) {
  const { documentId } = props;
  const { selectedOrganization } = useContext(UserContext);
  const documentQuery = useGetDocument(documentId, selectedOrganization);
  const { mutate: createFontStyle } = useCreateFontStyle();

  const handleUploadSuccess = ({ data: file }) => {
    createFontStyle({
      fileSuperId: file.superId,
      documentSuperId: documentId,
    });
  };

  if (documentQuery.isLoading) {
    return <Spinner />;
  }

  if (!documentQuery.isSuccess) {
    return null;
  }

  return (
    <div className="d-flex flex-column gap-3">
      <FileZone type="font" documentId={documentId} onSuccess={handleUploadSuccess} />
      {documentQuery.data.content.design
        .filter((s) => s.type === 'font')
        .map((font) => (
          <FontStyle key={font.styleId} styleId={font.styleId} />
        ))}
    </div>
  );
}

function FontStyle({ styleId }) {
  const { documentId } = useParams();
  const { selectedOrganization } = useContext(UserContext);
  const styleQuery = useStyle({ styleId, organization: selectedOrganization, documentSuperId: documentId });
  const saveQuery = useSaveFontStyle({ documentSuperId: documentId, styleId });
  const deleteQuery = useDeleteFontStyle({ styleId, documentSuperId: documentId });

  const handleSaveStyle = (_, content) => {
    saveQuery.mutate(content);
  };

  const handleDeleteStyle = () => {
    deleteQuery.mutate();
  };

  if (styleQuery.isLoading) {
    return <Spinner />;
  }

  if (!styleQuery.isSuccess) {
    return null;
  }

  return <CodeBlock style={styleQuery.data} key={styleQuery.data.styleId} onSave={handleSaveStyle} onDelete={handleDeleteStyle} />;
}
