export const calulcateCurrentPage = (pDocument, section, pageId, tableOfContentSection) => {
  const pageIndex = section.content?.findIndex((page) => page.uuid === pageId);
  const physicalPage = tableOfContentSection?.range?.start + pageIndex;

  const offset = calculatePageOffset(pDocument.content?.pageNumbering);

  return { physicalPage, documentPage: physicalPage + offset };
};

export const calculatePageOffset = (pageNumberSettings) => {
  if (!pageNumberSettings) {
    return 0;
  }
  return pageNumberSettings.countFrom - pageNumberSettings.showFrom;
};
