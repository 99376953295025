import { useSlate } from 'slate-react';
import { isBlockActive, toggleBlock, isSimpleBlockActive } from 'app/slate/functions';
import { forwardRef } from 'react';

export const BlockButton = forwardRef(({ format, icon, css, styling }, ref) => {
  const editor = useSlate();
  return (
    <button
      ref={ref}
      className={`${css} ${
        isBlockActive(editor, format, styling) || ((format === 'numbered-list' || format === 'bulleted-list') && isSimpleBlockActive(editor, format))
          ? 'active'
          : ''
      }`}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format, styling);
      }}
    >
      <i className={`fa-regular fa-${icon}`} />
    </button>
  );
});
