import React, { useContext, useState } from 'react';
import request from 'app/api/request';
import moment from 'moment';
import { UserContext } from 'app/state/contexts/UserContext';
import { i18n } from 'app/utils/i18n';
import { Modal, IconButton, NavbarDefault, DraggableTable } from 'app/components';
import { useHistory } from 'react-router-dom';

const CreateOrganization = ({ closeModal }) => {
  const { refreshOrganizations } = useContext(UserContext);
  const [name, setName] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    request
      .post('/gaby/organizations', {
        name,
      })
      .then(() => refreshOrganizations())
      .then(() => {
        setName('');
        closeModal?.();
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <input className="form-control" type="text" value={name} onChange={(e) => setName(e.target.value)} />

      <div className="mt-4 d-flex justify-content-end">
        <button className="button clear" type="button" onClick={closeModal}>
          {i18n('common.cancel')}
        </button>
        <button className="button">{i18n('common.save')}</button>
      </div>
    </form>
  );
};

export const Organizations = () => {
  const { selectedOrganization, organizations, selectOrganization } = useContext(UserContext);
  const [showModal, setShowModal] = useState();
  const history = useHistory();
  const items = organizations.map((organization) => ({
    title: organization.name,
    detail: moment(organization.createdAt).format('YYYY-MM-DD HH:mm:ss'),
    onClick: () => {
      history.push('/');
      selectOrganization(organization.id);
    },
  }));

  return (
    <div className="project--details grid--container full--height columns">
      <div className="content">
        <div className="content-title">
          <h1>{organizations?.find((organization) => organization.id === selectedOrganization)?.name}</h1>
        </div>
        <div className="project--overview">
          <div className="columns two mb-2">
            <h2>{i18n('organisations.list.title')}</h2>
            <div className="action--align--left">
              <IconButton onClick={() => setShowModal(true)} icon="plus" />
            </div>
          </div>
          <div>
            <DraggableTable items={items} />
          </div>
        </div>
      </div>
      <NavbarDefault />
      <Modal titleLabelKey="organisations.new-org.modal.title" show={showModal} onClose={() => setShowModal(false)}>
        <CreateOrganization closeModal={() => setShowModal(false)} />
      </Modal>
    </div>
  );
};
