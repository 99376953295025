import React, { useCallback, useContext, useEffect, useState } from 'react';
import request from 'app/api/request';
import Modal from 'app/components/Modal';
import { UserContext } from 'app/state/contexts/UserContext';
import UserList from './UserList';
import ServerErrorHandler from 'app/ErrorHandler';
import { i18n } from 'app/utils/i18n';

const Users = () => {
  const { selectedOrganization } = useContext(UserContext);
  const [users, setUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const [userName, setUserName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [isNewAdmin, setIsNewAdmin] = useState(false);
  const roleAdmin = 'ROLE_ORGANIZATION_ADMIN';
  const roleUser = 'ROLE_ORGANIZATION_USER';
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const fetchUsers = useCallback(() => {
    request.get(`/registry/users?attributeId=${selectedOrganization}&size=1000`).then((res) => {
      setUsers(res.data._embedded?.users);
    });
  }, [selectedOrganization]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const filterUsers = (users = [], currentRole) => {
    return users?.filter((user) => {
      return user.attributeRoles.find((role) => role.attributeId === selectedOrganization && role.roles.find((role) => role.role === currentRole));
    });
  };

  const resetForm = () => {
    setUserName('');
    setFirstName('');
    setLastName('');
    setPassword('');
  };

  const createUser = (event) => {
    event.preventDefault();
    if (!userName || (password && (!userName || !firstName || !lastName))) {
      setShowErrorMessage(true);
      return;
    }

    toggleModal();
    setShowErrorMessage(false);
    const newUser = {
      userName: userName,
      password: password,
      firstName: firstName,
      lastName: lastName,
      forceChangePassword: true,
      email: [userName],
    };

    // TODO This should be invite only by email and not actually adding them to the system completely
    new Promise((resolve, reject) => {
      request
        .post(`/registry/users`, newUser)
        .then(() => resolve())
        .catch((response) => {
          if (response.status !== 409) {
            reject(response);
          }
          resolve();
        });
    })
      .then(() => request.get(`/registry/users?username=${userName}`))
      .then((response) => {
        const user = response.data._embedded.users[0];
        return request.post(`/registry/roles/users/${user.id}`, {
          userId: user.id,
          roles: [
            {
              id: isNewAdmin ? 4 : 5,
              role: isNewAdmin ? roleAdmin : roleUser,
              type: 'EXT',
              externalId: '0',
            },
          ],
          attributeId: selectedOrganization,
        });
      })
      .then(() => {
        fetchUsers();
      })
      .then(resetForm())
      .catch(ServerErrorHandler);
  };

  const handleCancel = () => {
    toggleModal();
    setShowErrorMessage(false);
    resetForm();
  };

  const handleAddUser = (isAdmin) => {
    toggleModal();
    setIsNewAdmin(isAdmin);
  };

  return (
    <div>
      <UserList listTitle={i18n('users.customer-admin.list.title')} users={filterUsers(users, roleAdmin)} onAdd={handleAddUser} isAdmin />
      <UserList listTitle={i18n('users.user-rights.list.title')} users={filterUsers(users, roleUser)} onAdd={handleAddUser} />

      <Modal show={showModal} onClose={toggleModal}>
        <form onSubmit={createUser}>
          <div className="mb-4">
            <label htmlFor="username" className="form-label">
              {i18n('users.new-user.modal.field.username')}
            </label>
            <input
              type="text"
              name="username"
              value={userName}
              placeholder=""
              onChange={(event) => {
                setUserName(event.target.value);
              }}
              className="form-control"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="firstname" className="form-label">
              {i18n('users.new-user.modal.field.firstname')}
            </label>
            <input
              type="text"
              name="firstname"
              value={firstName}
              placeholder=""
              onChange={(event) => {
                setFirstName(event.target.value);
              }}
              className="form-control"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="lastname" className="form-label">
              {i18n('users.new-user.modal.field.lastname')}
            </label>
            <input
              type="text"
              name="lastname"
              value={lastName}
              placeholder=""
              onChange={(event) => {
                setLastName(event.target.value);
              }}
              className="form-control"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="form-label">
              {i18n('users.new-user.modal.field.password')}
            </label>
            <input
              type="password"
              name="password"
              value={password}
              placeholder=""
              onChange={(event) => {
                setPassword(event.target.value);
              }}
              className="form-control"
            />
            {showErrorMessage ? <p className="form-validation-error-message">{i18n('generic.form-validation.error-message')}</p> : null}
          </div>
          <div className="d-flex justify-content-end">
            <button type="button" className="button clear" onClick={handleCancel}>
              {i18n('common.button.cancel')}
            </button>
            <button type="submit" className="button primary">
              {i18n('common.button.save')}
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default Users;
