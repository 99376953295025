import React, { forwardRef } from 'react';

export const TippyDropDownContent = ({ children, className = '' }) => {
  return <div className={`tippy-dropdown-content bg-light border border-gray-300 ${className}`}>{children}</div>;
};

export const TippyDropDown = forwardRef(({ text = '', className = '', Component = null, disabled = false }, ref) => {
  return (
    <button
      className={`btn-tippy-dropdown form-control-sm d-flex border bg-white justify-content-between align-items-center ${className}`}
      disabled={disabled}
      ref={ref}
    >
      {typeof Component === 'function' ? (
        <Component />
      ) : (
        <>
          {text}
          <i className="ms-2 fa-regular fa-angle-down" />
        </>
      )}
    </button>
  );
});
