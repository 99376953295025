import { useState, useContext } from 'react';
import { usePopper } from 'react-popper';
import { EditorContext } from 'app/state/contexts/EditorContext';

const ReadOnlyMark = ({ readOnly }) => {
  const { editorContainerRef } = useContext(EditorContext);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(editorContainerRef.current, popperElement, {
    placement: 'right-start',
  });

  if (!readOnly) {
    return null;
  }

  return (
    <div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
      <div className="read-only-mark">
        <i className="fa-regular fa-eye" />
      </div>
    </div>
  );
};

export default ReadOnlyMark;
