import { useState, useEffect } from 'react';
import request from 'app/api/request';
import { setDocumentPermissions as setReduxDocumentPermissions, setSectionPermissions as setReuxSectionPermissions } from 'app/state/redux/documentSlice';
import { useDispatch } from 'react-redux';

export const usePermissions = (documentSuperId) => {
  const [documentPermissions, setDocumentPermissions] = useState([]);
  const [sectionPermissions, setSectionPermissions] = useState({});
  const dispatch = useDispatch();

  const fetchPermissionData = async () => {
    request
      .get(`/gaby/documents/${documentSuperId}/current-user-permissions`)
      .then((res) => res.data)
      .then((res) => {
        setDocumentPermissions(res.userDocumentPermissions);
        dispatch(setReduxDocumentPermissions(res.userDocumentPermissions));
        const sectionDictionary = {};
        res.userSectionPermissions.forEach((permission) => (sectionDictionary[permission.sectionSuperId] = permission.permission));
        dispatch(setReuxSectionPermissions(sectionDictionary));
        setSectionPermissions(sectionDictionary);
      });
  };

  useEffect(() => {
    fetchPermissionData();
  }, [documentSuperId]); // eslint-disable-line react-hooks/exhaustive-deps

  return { documentPermissions, sectionPermissions, fetchPermissionData };
};
