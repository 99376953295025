import { dispatchMessage } from 'app/useToasts';
import { i18n } from 'app/utils/i18n';

/**
 * Wrapper for Fuse messageActions.
 *
 * @param {*} type
 * @param {*} message
 */
export const showMessage = (type = 'warning', message = '') => {
  dispatchMessage(type, { text: message });
};

export const errorHandler = (e) => {
  let message = i18n('request.error');

  if (e?.status) {
    switch (e.status) {
      case 404:
        message = i18n('request.error.404');
        break;
      default:
        break;
    }
  }

  showMessage('error', message);
};
