import React, { useState } from 'react';
import logo from 'app/images/Xplir_logo_rgb.svg';
import { i18n } from 'app/utils/i18n';
import { LazyTippy } from 'app/components';

const ModalContent = ({ setToggleSupportModal, toggleSupportModal }) => {
  return (
    <div className="card support-modal">
      <div className="card-header columns col-auto-1fr-auto p-3">
        <img src={logo} alt="logo" className="rounded-circle p-1 support-modal-header-img" />
        <p className="fw-bold">{i18n('support.modal.header-title')}</p>
        <i className="fa-regular fa-square-minus" onClick={() => setToggleSupportModal(!toggleSupportModal)} />
      </div>
      <div className="card-body p-3">
        <div className="columns col-auto-1fr-auto support-modal-helpdesk p-3">
          <i className="fa-regular fa-book-open-reader rounded-circle p-3" />
          <div>
            <h3>{i18n('support.modal.helpdesk-title')}</h3>
            <p>{i18n('support.modal.helpdesk-text')}</p>
          </div>
          <i className="fa-regular fa-arrow-right" />
        </div>
        <div className="text-center mt-4">
          <p className="mb-2">{i18n('support.modal.contact-title')}</p>
          <a href="mailto:support@xplir.com" className="text-uppercase fw-bold support-modal-contact-link">
            {i18n('support.modal.contact-text')}
          </a>
        </div>
      </div>
    </div>
  );
};

const SupportModal = ({ bottomOffset }) => {
  const [toggleSupportModal, setToggleSupportModal] = useState(false);

  const handleSupportClick = () => {
    setToggleSupportModal(!toggleSupportModal);
  };

  return (
    <LazyTippy
      visible={toggleSupportModal}
      onClickOutside={() => setToggleSupportModal(!toggleSupportModal)}
      content={<ModalContent toggleSupportModal={toggleSupportModal} setToggleSupportModal={setToggleSupportModal} />}
      offset={[-210, 5]}
      interactive={true}
      onTrigger={() => setToggleSupportModal(!toggleSupportModal)}
    >
      <div className="support-button aspect-1x1 rounded-circle" onClick={() => handleSupportClick()}>
        <i className="fa-regular fa-circle-question"></i>
      </div>
    </LazyTippy>
  );
};

export default SupportModal;
