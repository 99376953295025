import { i18n } from 'app/utils/i18n';
import { wrapper } from './wrapper-helper';
import { CurrentFileBlob } from './UpdateReferenceData';

export function SelectSheet(props) {
  const { file, maybeFile, sheetOptions, currentReferenceData, onNewFileClick, onSelectSheet } = props;

  return wrapper(
    i18n('reference-data.update-sheet.modal.title'),
    [file],
    () => (
      <div>
        <CurrentFileBlob file={maybeFile}>
          <div onClick={onNewFileClick}>{i18n('reference-data.update-sheet.link.choose-new-file')}</div>
        </CurrentFileBlob>
        <table>
          <tbody>
            {sheetOptions.map((option) => (
              <tr key={option.label} onClick={() => onSelectSheet(option.label)}>
                {option.label === currentReferenceData.selection?.sheet ? (
                  <td>
                    <span className="fs-6 text-blue-800">
                      <i className="fa-regular fa-link"></i> {option.label}
                    </span>
                    <br />
                    <span className="text-gray-700">{i18n('reference-data.update-sheet.current-sheet')}</span>
                  </td>
                ) : (
                  <td>{option.label}</td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ),
    () => <></>
  );
}
