class AuthStore {
  organizationPermissions = [];

  hasPermission(permission, organizationId) {
    if (organizationId === undefined) {
      return this.organizationPermissions.some(({ permission: p }) => p === permission);
    }

    return this.organizationPermissions.some(({ organizationId: o, permission: p }) => p === permission && (o === organizationId || o === null));
  }

  hasGlobalPermission(p) {
    return this.hasPermission(p, null);
  }

  set attributeRoles(attributeRoles) {
    this.organizationPermissions = attributeRoles.flatMap((org) => org.role.permissions.map((permission) => ({ organizationId: org.attributeId, permission })));
  }

  get authToken() {
    return getCookie('nnmp-token');
  }
}
const authStore = new AuthStore();

export function getCookie(name) {
  var nameEQ = name + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return null;
}

export default authStore;
