import { useMemo } from 'react';
import { withHistory } from 'slate-history';
import { v4 as uuidv4 } from 'uuid';
import { withReact } from 'slate-react';
import { createEditor, Transforms, Node, Range, Editor as SlateEditor } from 'slate';
import withDefaultRenders from 'app/slate/extensions/default-renderers';
import { compose } from 'ramda';

const useEditor = (extensions) => {
  return useMemo(() => {
    const withExtensions = compose(...extensions, withDefaultRenders, withHistory, withReact);
    const editor = createEditor();
    editor.decorate = () => [];
    editor.deleteFragment = () =>
      SlateEditor.withoutNormalizing(editor, () => {
        Array.from(
          Node.texts(editor, {
            from: editor.selection.anchor.path,
            to: editor.selection.focus.path,
          })
        )
          .reverse()
          .map(([node, path]) =>
            Range.intersection(
              {
                anchor: { path, offset: 0 },
                focus: { path, offset: node.text.length },
              },
              editor.selection
            )
          )
          .forEach((range) => {
            if (Range.isCollapsed(range)) {
              return;
            }
            Transforms.delete(editor, { at: range });
          });
      });

    const { normalizeNode } = editor;
    editor.normalizeNode = (entry) => {
      const [node] = entry;
      if (!SlateEditor.isEditor(node)) {
        normalizeNode(entry);
        return;
      }

      const descendants = Node.descendants(node);

      const used = new Set();
      for (const [_node, _path] of descendants) {
        if (!_node.uuid) {
          continue;
        }
        if (used.has(_node.uuid)) {
          Transforms.setNodes(
            editor,
            {
              uuid: uuidv4(),
            },
            {
              at: _path,
            }
          );
        }
        used.add(_node.uuid);
      }
    };

    preventDeletionOverMultiplePages(editor);

    const customEditor = withExtensions(editor);

    // Paste HTML as Text into document
    customEditor.insertData = (data) => {
      if (data) {
        const html = data.getData('text/html');
        if (html) {
          // TODO: Hantera genom att behålla utvalda element och formatering
          const text = data.getData('text/plain');

          customEditor.insertText(text);
          return;
        }
      }

      customEditor.insertData(data);
    };
    return customEditor;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

const preventDeletionOverMultiplePages = (editor) => {
  const { insertText, deleteFragment, insertBreak } = editor;

  editor.insertText = (entry) => {
    if (isMultiPageSelection(editor)) {
      console.log('Text insertion prevented, as text selection spans multiple pages');
    } else {
      insertText(entry);
    }
  };

  editor.deleteFragment = (entry) => {
    if (isMultiPageSelection(editor)) {
      console.log('Text deletion prevented, as text selection spans multiple pages');
    } else {
      deleteFragment(entry);
    }
  };

  editor.insertBreak = (entry) => {
    if (isMultiPageSelection(editor)) {
      console.log('Line break prevented, as text selection spans multiple pages');
    } else {
      insertBreak(entry);
    }
  };
};

const isMultiPageSelection = (editor) => {
  const anchorPage = editor.selection?.anchor.path[0];
  const focusPage = editor.selection?.focus.path[0];
  return anchorPage !== focusPage;
};

export default useEditor;
