import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import request from 'app/api/request';
import { OldModal } from 'app/components';
import { useParams } from 'react-router-dom';
import { UserContext } from 'app/state/contexts/UserContext';
import Tippy from '@tippyjs/react';
import { followCursor } from 'tippy.js';
import { LazyTippy } from 'app/components';
import { ToolMenu } from 'app/components';
import Select from 'react-select';
import ServerErrorHandler from 'app/ErrorHandler';
import { i18n } from 'app/utils/i18n';
import { showMessage } from 'app/utils/messages';

const NewGroupForm = ({ documentId, fetchData, onGroupCreated, existingPermissionGroup }) => {
  const [newPermissionGroupName, setNewPermissionGroupName] = useState(existingPermissionGroup ? existingPermissionGroup.name : '');
  const inputRef = useRef('');

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const createPermissionGroup = (event) => {
    event.preventDefault();
    if (!newPermissionGroupName) {
      return null;
    }

    if (existingPermissionGroup) {
      request
        .put(`/gaby/permission-groups/${existingPermissionGroup.id}`, {
          name: newPermissionGroupName,
          documentSuperId: documentId,
        })
        .then(() => {
          setNewPermissionGroupName('');
          onGroupCreated();
        });
    } else {
      request
        .post('/gaby/permission-groups', {
          name: newPermissionGroupName,
          documentSuperId: documentId,
        })
        .then(() => {
          setNewPermissionGroupName('');
          fetchData();
          onGroupCreated();
        })
        .catch(ServerErrorHandler);
    }
  };

  return (
    <div className="add-new-popup wider-popup popup-with-button">
      <div className="comment--response">
        <form onSubmit={createPermissionGroup}>
          <input
            className="input-variant wider-input"
            type="text"
            name="username"
            value={newPermissionGroupName}
            ref={inputRef}
            placeholder=""
            onChange={(event) => {
              setNewPermissionGroupName(event.target.value);
            }}
          />
          <button type="submit" className="button btn btn-secondary">
            {existingPermissionGroup ? 'Uppdatera gruppnamn' : 'Skapa ny grupp'}
          </button>
        </form>
      </div>
    </div>
  );
};

const NewUserForm = ({ fetchData, permissionGroup, onUserAdded }) => {
  const [userName, setUserName] = useState('');
  const inputRef = useRef('');

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const handleAddUser = (event) => {
    event.preventDefault();
    if (!userName) {
      return null;
    }

    request
      .get(`/registry/users?username=${userName}`)
      .then((response) => {
        const user = response.data?._embedded?.users.find((user) => user.userName === userName);
        if (user) {
          request
            .post(`/gaby/permission-groups/${permissionGroup.id}/users`, { userId: user.id })
            .then(() => {
              fetchData();
              setUserName('');
              onUserAdded();
            })
            .catch(ServerErrorHandler);
        } else {
          showMessage('warning', i18n('section-permissions.user-not-found'));
        }
      })
      .catch(ServerErrorHandler);
  };

  return (
    <div className="add-new-popup wider-popup popup-with-button">
      <div className="comment--response">
        <form onSubmit={handleAddUser}>
          <input
            className="input-variant wider-input"
            type="text"
            name="username"
            value={userName}
            ref={inputRef}
            placeholder={i18n('section-permissions.group.field.add-new-user')}
            onChange={(event) => {
              setUserName(event.target.value);
            }}
          />
          <button type="submit" className="button btn btn-secondary">
            {i18n('section-permissions.group.button.add-new-user')} {userName}
          </button>
        </form>
      </div>
    </div>
  );
};

const SectionPermissions = () => {
  const { documentId } = useParams();
  const { selectedOrganization } = useContext(UserContext);
  const [selectedDocument, setSelectedDocument] = useState({});
  const [users, setUsers] = useState([]);
  const [permissions, setPermissions] = useState({
    permissionGroups: [],
  });
  const [showNewGroupForm, setShowNewGroupForm] = useState(false);
  const permissionSectionMenuItems = (item) => [
    { text: i18n('section-permissions.group.dropdown-item.change-name'), icon: 'fa-regular fa-pen', onClick: () => ChangeName(item) },
    { text: i18n('section-permissions.group.dropdown-item.change-time-limit'), icon: 'fa-regular fa-clock', disabled: true },
    { text: i18n('section-permissions.group.dropdown-item.delete-group'), icon: 'fa-regular fa-trash-can', onClick: () => deletePermissionGroup(item) },
    { text: i18n('section-permissions.group.dropdown-item.move-down'), icon: 'fa-regular fa-arrow-down', disabled: true },
  ];
  const menuItemsEditPermissionGroupUser = (groupMember) => [
    { text: i18n('section-permissions.user.dropdown-item.change-all'), icon: 'fa-regular fa-key', disabled: true },
    { text: i18n('section-permissions.group.dropdown-item.remove-user'), icon: 'fa-regular fa-trash-can', onClick: () => removeUserFromGroup(groupMember) },
    { text: i18n('section-permissions.group.dropdown-item.move-to'), icon: 'fa-regular fa-folder', disabled: true },
  ];
  const [existingPermissionGroup, setExistingPermissionGroup] = useState(null);

  //TODO Maybe fetch this list from backend, gaby-documents or client-config
  const AVAILABLE_SECTION_PERMISSIONS = [
    {
      title: i18n('section-permissions.dropdown.permission.write.title'),
      icon: 'fa-regular fa-pen',
      subtitle: i18n('section-permissions.dropdown.permission.write.text'),
      permissionName: 'WRITE',
    },
    {
      title: i18n('section-permissions.dropdown.permission.comment.title'),
      icon: 'fa-regular fa-message',
      subtitle: i18n('section-permissions.dropdown.permission.comment.text'),
      permissionName: 'COMMENT',
    },
    {
      title: i18n('section-permissions.dropdown.permission.remove.title'),
      icon: 'fa-regular fa-eye-slash',
      subtitle: i18n('section-permissions.dropdown.permission.remove.text'),
      permissionName: 'NO_PERMISSION',
    },
  ];

  const deleteModal = useRef(null);

  const ChangeName = (group) => {
    setExistingPermissionGroup(group);
    setShowNewGroupForm(true);
  };

  const handleGroupDelete = (group, event) => {
    event.preventDefault();

    request
      .del(`/gaby/permission-groups/${group.id}`, null)
      .then(() => {
        handleGroupDeleted();
      })
      .catch((e) => {
        deleteModal.current.close();
        ServerErrorHandler(e);
      });
  };

  const deletePermissionGroup = (group) => {
    setExistingPermissionGroup(group);
    deleteModal.current.open();
  };

  const removeUserPermission = (sectionSuperId, userId, permission) => {
    request.del(`/gaby/sections/${sectionSuperId}/permissions/${permission}/users/${userId}`, null).then(() => {
      fetchData();
    });
  };

  const removeUserFromGroup = (user) => {
    request.del(`/gaby/permission-groups/${user.permissionGroupId}/users/${user.userId}`, null).then(() => {
      fetchData();
    });
  };

  const changeSectionPermission = (sectionSuperId, userId, selectedPermission) => {
    const currentSectionPermission = findUserPermissionForSection(sectionSuperId, userId);
    if (selectedPermission === 'NO_PERMISSION' && currentSectionPermission) {
      removeUserPermission(sectionSuperId, userId, currentSectionPermission);
    } else if (selectedPermission !== 'NO_PERMISSION') {
      request.put(`/gaby/sections/${sectionSuperId}/permissions/${selectedPermission}/users`, { userId: userId, permission: selectedPermission }).then(() => {
        fetchData();
      });
    }
  };

  const findUserPermissionForSection = (sectionSuperId, userId) => {
    return permissions.userSectionPermissions.find((permission) => permission.sectionSuperId === sectionSuperId && permission.userId === userId)?.permission;
  };

  const fetchData = useCallback(() => {
    request.get(`/gaby/documents/${documentId}/permissions`).then((res) => {
      setPermissions(res.data);
    });
    //TODO get username for user in backend with intra service call instead
    request.get(`/registry/users?size=1000`).then((res) => {
      setUsers(res.data._embedded?.users);
    });

    request.get(`/gaby/documents?superId=${documentId}&organization=${selectedOrganization}&current=true`).then((res) => setSelectedDocument(res.data[0]));
  }, [documentId, selectedOrganization]);

  const SectionMenuItem = ({ menuItem, onClick }) => {
    const handleOnClick = () => {
      onClick(menuItem.permissionName);
    };
    return (
      <div className="tool-menu-item" onClick={handleOnClick}>
        <i className={`fa-regular ${menuItem.icon}`} />
        <span className="tool-menu-title">{menuItem.title}</span>
        <p className="tool-menu-subtitle">{menuItem.subtitle}</p>
      </div>
    );
  };

  const SelectPermission = ({ menuItems, onClick, sectionSuperId, userId }) => {
    const handleOnClick = (menuItemId) => {
      onClick(sectionSuperId, userId, menuItemId);
    };
    return (
      <div className="tool-menu-wrapper section-permission-tool-menu">
        {menuItems?.map((m, i) => {
          return <SectionMenuItem menuItem={m} key={i} onClick={handleOnClick} />;
        })}
      </div>
    );
  };

  const SelectedSectionPermission = ({ superId, permission, userId, index }) => {
    return (
      <Tippy
        interactive
        placement="bottom"
        followCursor="initial"
        trigger="click"
        plugins={[followCursor]}
        content={
          <SelectPermission
            menuItems={AVAILABLE_SECTION_PERMISSIONS}
            sectionSuperId={superId}
            userId={userId}
            permissionName={permission?.permissionName}
            onClick={changeSectionPermission}
          />
        }
        key={index}
      >
        <i className={`${permission?.icon ? permission.icon : 'fa-regular fa-eye-slash'} selected-permission-icon`} />
      </Tippy>
    );
  };

  const handleNewGroupClick = () => {
    setShowNewGroupForm(true);
    setExistingPermissionGroup(null);
  };

  const handleGroupCreated = useCallback(() => {
    setShowNewGroupForm(false);
    fetchData();
  }, [fetchData]);

  const handleGroupDeleted = useCallback(() => {
    deleteModal.current.close();
    fetchData();
  }, [fetchData]);

  const onUserAdded = useCallback(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  return (
    <div>
      {permissions.permissionGroups.map((group, i) => (
        <div key={group.id} className="section-permission-wrapper">
          <div className={i === 0 ? 'permission-section-header head-w-new-group-btn' : 'permission-section-header'}>
            <h2 className="permission-title">{group.name}</h2>
            {i !== 0 ? (
              <Tippy interactive={true} placement="bottom" trigger="click" content={<ToolMenu menuItems={permissionSectionMenuItems(group)} />}>
                <i className="fa-regular fa-ellipsis-v more toggle-icon edit-permission-group" />
              </Tippy>
            ) : null}
            {i === 0 ? (
              <LazyTippy
                interactive={true}
                visible={showNewGroupForm}
                onClickOutside={() => setShowNewGroupForm(false)}
                placement="bottom"
                content={
                  <NewGroupForm
                    documentId={documentId}
                    fetchData={fetchData}
                    onGroupCreated={handleGroupCreated}
                    existingPermissionGroup={existingPermissionGroup}
                  />
                }
              >
                <button className="button light-blue" type="button" onClick={() => handleNewGroupClick()}>
                  {i18n('section-permissions.button.new-group')}
                </button>
              </LazyTippy>
            ) : (
              <div className="permission-group-history columns">
                <p>{i18n('section-permissions.dropdown.history.title')}</p>
                <form>
                  <Select type="text" name="Historik" value="Version 1" placeholder="Version 1" options={[]} isDisabled />
                </form>
              </div>
            )}
            <LazyTippy
              interactive={true}
              trigger="click"
              offset={[20, 10]}
              placement="bottom"
              content={<NewUserForm fetchData={fetchData} permissionGroup={group} onUserAdded={onUserAdded} users={users} />}
            >
              <button className="fab" type="button">
                <i className="fa-regular fa-plus font-15" />
              </button>
            </LazyTippy>
          </div>

          {/* TODO - Make kebab menu of this instead
          onClick={() => { 
              removeUserPermission(section.superId, user.id, findUserPermissionForSection(section.superId, user.id));
            }}*/}

          <div className="responsive-table">
            <table className="permissions-table">
              <thead>
                <tr>
                  <td className="permission-thead-menu td-space"></td>
                  {selectedDocument.content?.sections.map((section) => (
                    <td key={section.superId} className="section-thead">
                      <span>{section.name} </span>
                    </td>
                  ))}
                </tr>
              </thead>
              <tbody>
                {group.UserPermissionGroups.map((member) => {
                  const user = users.find((user) => user.id === member.userId);
                  if (!user) {
                    return (
                      <tr key={member.permissionGroupId + '-' + member.userId}>
                        <td>{i18n('common.loading-message')}</td>
                      </tr>
                    );
                  }

                  return (
                    <tr key={user.id}>
                      <td className="td-space">
                        <div className="d-flex">
                          <div className="mr-3">
                            {user.firstName} {user.lastName}
                          </div>
                          <Tippy
                            interactive={true}
                            offset={[90, -140]}
                            trigger="click"
                            content={<ToolMenu menuItems={menuItemsEditPermissionGroupUser(member)} />}
                          >
                            <i className="fa-regular fa-ellipsis-v more toggle-icon edit-permission-group edit-permission-group-user" />
                          </Tippy>
                        </div>
                      </td>
                      {selectedDocument.content?.sections.map((section) => (
                        <td key={section.superId}>
                          {AVAILABLE_SECTION_PERMISSIONS.map((permission, i) => (
                            <div key={group.id + section.superId + user.id + permission.permissionName}>
                              {findUserPermissionForSection(section.superId, user.id) === permission.permissionName ? (
                                <span>
                                  <SelectedSectionPermission superId={section.superId} permission={permission} userId={user.id} index={i} />
                                </span>
                              ) : null}
                            </div>
                          ))}
                          {!findUserPermissionForSection(section.superId, user.id) ? (
                            <SelectedSectionPermission superId={section.superId} permission={null} userId={user.id} index={0} />
                          ) : null}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ))}
      <OldModal ref={deleteModal} modalWrapperClass="delete-modal">
        <i
          className="fa-regular fa-xmark text-right close"
          onClick={() => {
            deleteModal.current.close();
          }}
        />
        <form className="form" onSubmit={(e) => handleGroupDelete(existingPermissionGroup, e)}>
          <div>
            <i className="fa-regular fa-trash-can modal-icon" />
            <h3 className="modal-body-title">{i18n('section-permissions.modal.delete.title')}</h3>
            <p className="modal-body-text">
              {i18n('section-permissions.modal.delete.text')} {existingPermissionGroup?.name}?
            </p>
          </div>
          <div className="modal-footer">
            <button type="button" className="button clear" onClick={() => deleteModal.current.close()}>
              {i18n('common.button.cancel')}
            </button>
            <button type="submit" className="button primary" onClick={(e) => handleGroupDelete(existingPermissionGroup, e)}>
              {i18n('document-permissions.remove-user.modal.button.remove')}
            </button>
          </div>
        </form>
      </OldModal>
    </div>
  );
};

export default SectionPermissions;
