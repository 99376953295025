import ServerErrorHandler from 'app/ErrorHandler';
import request from 'app/api/request';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { v4 as uuidv4 } from 'uuid';
import { i18n } from 'app/utils/i18n';

const queryKeys = {
  template: 'TEMPLATE',
  templates: 'TEMPLATES',
  selectedTemplates: 'TEMPLATES_SELECTED',
};

export function useTemplates({ organization, documentSuperId, type }) {
  return useQuery(
    [queryKeys.templates, organization, documentSuperId, type],
    async () => {
      const params = [
        documentSuperId ? 'documentSuperId=' + documentSuperId : '',
        type ? 'type=' + type : '',
        organization ? 'organization=' + organization : '',
      ]
        .filter((i) => i)
        .join('&');
      const res = await request.get(`/gaby/templates?${params}`);
      return res.data;
    },
    {
      onError: ServerErrorHandler,
    }
  );
}

export function useTemplate(revisionId) {
  return useQuery(
    [queryKeys.template, revisionId],
    async () => {
      const res = await request.get(`/gaby/templates/${revisionId}`);
      return res.data;
    },
    {
      enabled: !!revisionId,
      onError: ServerErrorHandler,
    }
  );
}

export function useSaveTemplate() {
  const queryClient = useQueryClient();
  return useMutation(
    async (template) => {
      await request.post('/gaby/templates', template);
    },
    {
      onError: ServerErrorHandler,
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.templates);
      },
    }
  );
}

export function useRenameDocumentTemplate() {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ documentSuperId, templateRevisionId, newName }) => {
      return request.post(`/gaby/documents/${documentSuperId}/actions`, {
        action: 'rename_document_template',
        templateRevisionId,
        newName: newName,
      });
    },
    {
      onError: ServerErrorHandler,
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.template);
        queryClient.invalidateQueries(queryKeys.templates);
      },
    }
  );
}

export function useCopyTemplate() {
  const queryClient = useQueryClient();
  return useMutation(
    async (template) => {
      const newTemplate = {
        ...template,
        superId: uuidv4(),
        revisionId: uuidv4(),
        name: `${i18n('templates.copyof')} ${template.name}`,
        className: '',
      };
      return await request.post('/gaby/templates', newTemplate);
    },
    {
      onError: ServerErrorHandler,
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.templates);
      },
    }
  );
}

export function usePromoteTemplate() {
  const queryClient = useQueryClient();
  return useMutation(
    async (template) => {
      const newTemplate = {
        ...template,
        revisionId: uuidv4(),
        organization: null,
      };
      return await request.post('/gaby/templates', newTemplate);
    },
    {
      onError: ServerErrorHandler,
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.templates);
      },
    }
  );
}

export function useDemoteTemplate() {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ template, organization }) => {
      const newTemplate = {
        ...template,
        revisionId: uuidv4(),
        organization,
      };
      return await request.post('/gaby/templates', newTemplate);
    },
    {
      onError: ServerErrorHandler,
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.templates);
      },
    }
  );
}

export function useDeleteTemplate() {
  const queryClient = useQueryClient();
  return useMutation(
    async (template) => {
      await request.del(`/gaby/templates/${template.superId}`);
    },
    {
      onError: ServerErrorHandler,
      onSuccess: (_, template) => {
        queryClient.invalidateQueries(queryKeys.templates);
        queryClient.invalidateQueries(queryKeys.template, template.revisionId);
      },
    }
  );
}

export function useSelectedTemplates(documentSuperId) {
  return useQuery(
    [queryKeys.selectedTemplates, documentSuperId],
    async () => {
      const res = await request.get(`/gaby/templates/selected/${documentSuperId}`);
      return res.data;
    },
    {
      onError: ServerErrorHandler,
    }
  );
}

export function useUpdateSelectedTemplates(documentSuperId) {
  const queryClient = useQueryClient();

  return useMutation(
    async (templateIds) => {
      await request.put(`/gaby/templates/selected/${documentSuperId}`, templateIds);
    },
    {
      onError: ServerErrorHandler,
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.selectedTemplates);
      },
    }
  );
}
