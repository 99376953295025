import { useEffect, useState, useRef } from 'react';
import request from 'app/api/request';
import moment from 'moment';
import { useSelector } from 'react-redux';

/**
 * @returns true if the user has the lock, false otherwise
 */
const useLock = (documentId, sectionId) => {
  const [writeLock, setWriteLock] = useState(false);
  const timer = useRef(null);
  const hasWritePermission = useSelector((state) => state.document.sectionPermissions)?.[sectionId] === 'WRITE';

  useEffect(() => {
    const getLock = () => {
      return request
        .post(`/gaby/locks/${documentId}`, {
          onId: sectionId,
        })
        .then((res) => res.data)
        .then((lock) => {
          setWriteLock(lock);
          return lock;
        })
        .catch(console.error);
    };

    if (hasWritePermission) {
      getLock().then(async (lock) => {
        if (lock?.isValidForUser && lock?.isValidNow) {
          // The user has the lock, set a timer to remember to get a new one before it expires.
          const currentTime = moment(new Date());
          const validUntil = moment(lock.validUntil, 'YYYY MM DD hh mm ss SSSZ');
          const timeout = validUntil.diff(currentTime);
          if (parseInt(timeout) === timeout && timeout > 0) {
            if (timer.current) {
              clearInterval(timer.current);
            }
            timer.current = setTimeout(() => getLock(), timeout);
          }
        }
        // read only, another user has the lock (OR something has gone horribly wrong)
      });
    }

    return () => {
      clearInterval(timer.current);
      timer.current = null;
    };
  }, [sectionId, documentId, hasWritePermission]);

  return hasWritePermission && writeLock?.isValidForUser && writeLock?.isValidNow;
};

export default useLock;
