import { useEffect, useState } from 'react';
import request from 'app/api/request';
import ServerErrorHandler from 'app/ErrorHandler';
import {
  setDocument as setReduxDocument,
  setLanguages as setReduxLanguages,
  setLocale as setReduxLocale,
  setReferenceData as setReduxReferenceData,
  setSection as setReduxSection,
  setVersions as setReduxVersions,
} from 'app/state/redux/documentSlice';
import { mapLanguages } from 'app/utils/translations';
import { useEditorDispatch } from 'app/state/contexts/EditorContext';
import { useCss } from 'app/api/style-queries';
import { useCreateTemplateStyle } from './document-queries';

export const useDocument = (
  documentId,
  documentRevisionId,
  sectionId,
  sectionRevisionId,
  translationId,
  showErrors = true,
  useDraft = false,
  usingStaticStructure,
  withStyle,
  useReferenceData,
  embedd = false
) => {
  const [document, setDocument] = useState(null);
  const [section, setSection] = useState(null);
  const editorDispatch = useEditorDispatch();
  const [referenceData, setReferenceData] = useState([]);
  const { data: css } = useCss({ documentSuperId: documentId, documentRevisionId, useDraft, withStyle, embedd });
  const { mutate: createTemplateStyle } = useCreateTemplateStyle(documentId);

  useEffect(() => {
    if (usingStaticStructure) {
      const section = {
        content: usingStaticStructure,
      };
      setSection(section);
      editorDispatch(setReduxSection(section));
    }
  }, [usingStaticStructure, editorDispatch]);

  useEffect(() => {
    fetchDocumentVersions(editorDispatch, documentId)
      .then(() => {
        const queryParams = ['compileSass=true'];
        if (useDraft) {
          queryParams.push('useDraft=true');
        }
        if (withStyle) {
          queryParams.push('withStyle=' + withStyle);
        }

        const query = queryParams.join('&');
        if (documentRevisionId) {
          return request.get(`/gaby/documents/${documentRevisionId}?${query}`).then((res) => res.data);
        } else {
          return request
            .get(`/gaby/documents?superId=${documentId}&current=true&${query}`)
            .then((res) => res.data)
            .then((docs) => {
              if (docs) {
                return docs[docs.length - 1];
              }
            });
        }
      })
      .then((doc) => {
        setDocument(doc);
        editorDispatch(setReduxDocument(doc));

        if (useReferenceData && doc.content.referenceData?.length) {
          const promises = doc.content.referenceData.map((reference) => request.get(`/gaby/reference-data/${reference.revisionId}`).then((res) => res.data));
          Promise.all(promises).then((data) => {
            setReferenceData(data);
            editorDispatch(setReduxReferenceData(data));
          });
        }

        if (sectionId) {
          const languages = mapLanguages(doc, sectionId);
          editorDispatch(setReduxLanguages(languages));
          const selectedSectionRevisionId = sectionRevisionId || doc.content.sections.find((section) => section.superId === sectionId).revisionId;

          const translationRevisionId = translationId ? languages.find((lang) => lang.superId === translationId).revisionId : null;
          return request
            .get(`/gaby/sections/${translationRevisionId ? translationRevisionId : selectedSectionRevisionId}`)
            .then((res) => res.data)
            .then((section) => {
              if (section) {
                setSection(section);
                editorDispatch(setReduxSection(section));

                const locale = languages.find((lang) => lang.superId === section.superId).locale;
                editorDispatch(setReduxLocale(locale));
              }
            });
        }
      })
      .catch((e) => {
        if (showErrors) {
          ServerErrorHandler(e);
        }
      });
  }, [documentId, sectionId, documentRevisionId, sectionRevisionId, translationId, withStyle]); // eslint-disable-line react-hooks/exhaustive-deps

  const addTemplateToDocument = (template) => {
    if (document.content.design.find((style) => style.templateSuperId === template.superId)) {
      return;
    }

    createTemplateStyle(
      { template },
      {
        onSuccess: (data) => {
          setDocument(data);
          editorDispatch(setReduxDocument(data));
        },
      }
    );
  };
  return { document, section, css, referenceData, addTemplateToDocument };
};

function fetchDocumentVersions(dispatch, documentId) {
  return request
    .get(`/gaby/versions/${documentId}`)
    .then((res) => res.data)
    .then((versions) => {
      dispatch(setReduxVersions(versions));
    });
}
