import { useState, useEffect } from 'react';
import request from 'app/api/request';
import { ReactEditor, useSlate } from 'slate-react';
import { Transforms } from 'slate';
import { useEditorSelector } from 'app/state/contexts/EditorContext';
import { DataImage } from 'app/components';

export const DocumentImage = (props) => {
  const editor = useSlate();
  const { imageSuperId, style } = props.element;
  const [imageData, setImageData] = useState();
  const image = useEditorSelector((editor) => editor.pDocument.content.images?.find?.((image) => image.superId === imageSuperId));

  useEffect(() => {
    if (!image) {
      return;
    }
    request
      .get(`/gaby/files/${image.revisionId}`)
      .then((res) => res.data)
      .then((image) => {
        setImageData(image);
      });
  }, [image]);

  const onDelete = () => {
    const path = ReactEditor.findPath(editor, props.element);
    Transforms.removeNodes(editor, { at: path });
  };

  return (
    <div className="editor-image--container" style={style} {...props.attributes} contentEditable={false}>
      {!props.readOnly && (
        <div className="controls--container">
          <button onClick={onDelete}>
            <i className="fa-regular fa-trash-can" />
          </button>
        </div>
      )}
      {imageData ? <DataImage {...imageData} /> : null}
      {props.children}
    </div>
  );
};
