import { useEffect } from 'react';
import { ReactEditor } from 'slate-react';
import { Node } from 'slate';
import { setSelectionId as setReduxSelectionId } from 'app/state/redux/documentSlice';
import { useEditorDispatch } from 'app/state/contexts/EditorContext';

/**
 * This hook is used to keep track of the selection in the editor. Used by comments.
 */
const useSelection = (editor, shadowHost) => {
  const editorDispatch = useEditorDispatch();

  useEffect(() => {
    if (!shadowHost?.shadowRoot) {
      return;
    }

    const mouseUpHandler = (event) => {
      if (event.type === 'blur') {
        editorDispatch(setReduxSelectionId(null));
        return;
      }

      const shadowRoot = shadowHost.shadowRoot;
      const selection = (shadowRoot.getSelection ? shadowRoot : shadowRoot.ownerDocument).getSelection();

      // Selection.isCollapsed does not work in chromium
      // https://bugs.chromium.org/p/chromium/issues/detail?id=447523
      if (!selection || selection.anchorOffset === selection.focusOffset) {
        editorDispatch(setReduxSelectionId(null));
        return;
      }

      const slateNode = ReactEditor.toSlateNode(editor, selection.anchorNode);
      const slatePath = ReactEditor.findPath(editor, slateNode);
      const nodes = Node.ancestors(editor, slatePath, {
        reverse: true,
      });
      const [element] = Array.from(nodes).find(([node]) => node.uuid);
      editorDispatch(setReduxSelectionId(element.uuid));
    };

    const events = ['mouseup', 'keyup'];

    const shadowRoot = shadowHost.shadowRoot;
    const options = {
      capture: true,
    };

    events.forEach((e) => shadowRoot.addEventListener(e, mouseUpHandler, options));
    events.forEach((e) => window.addEventListener(e, mouseUpHandler, options));
    return () => {
      if (shadowRoot) {
        events.forEach((e) => shadowRoot.removeEventListener(e, mouseUpHandler, options));
      }
      events.forEach((e) => window.removeEventListener(e, mouseUpHandler, options));
    };
  }, [editor, shadowHost, editorDispatch]);
};

export default useSelection;
