import React from 'react';
import PropTypes from 'prop-types';

const InfoBlockCheckBox = ({ caption = '', isChecked = false, handleCheckboxChange = null, className = '', children = null }) => {
  return (
    <div className={`py-3 ${className}`}>
      <p className="text-uppercase fs-7 text-gray-900 mb-1">{caption}</p>
      {children || <input className="fs-3" type="checkbox" checked={isChecked} onChange={(e) => handleCheckboxChange(e.target.checked)} disabled />}
    </div>
  );
};

InfoBlockCheckBox.propTypes = {
  caption: PropTypes.string,
  isChecked: PropTypes.bool,
  className: PropTypes.string,
};

export default InfoBlockCheckBox;
