import request from 'app/api/request';
import { LanguageSelector } from 'app/components';
import { useParams } from 'react-router';
import { setDocument } from 'app/state/redux/documentSlice';
import { useEditorDispatch, useEditorSelector } from 'app/state/contexts/EditorContext';

const Locale = () => {
  const editorDispatch = useEditorDispatch();
  const { documentId } = useParams();
  const reduxDocument = useEditorSelector((editor) => editor.pDocument);

  const handleChange = (lang) => {
    request
      .post(`/gaby/documents/${documentId}/actions`, {
        action: 'update_document_locale',
        data: {
          revisionId: reduxDocument.revisionId,
          superId: reduxDocument.superId,
          locale: lang,
        },
      })
      .then((res) => {
        request
          .get(`/gaby/documents/${res.data.revisionId}`)
          .then((res) => res.data)
          .then((document) => {
            editorDispatch(setDocument(document));
          });
      });
    return null;
  };

  return (
    <div className="w-25">
      <LanguageSelector sm className="tippy-box" onChange={handleChange} locale={reduxDocument?.content?.locale || 'sv-SE'} />
    </div>
  );
};

export default Locale;
