import React from 'react';
import { Element } from 'slate';
import { TableOfContent } from 'app/slate/components/TableOfContent';

const isTableOfContent = (node) => Element.isElement(node) && node.type === 'table-of-content';

const tableOfContent =
  (options = {}) =>
  (editor) => {
    const { renderElement, isVoid } = editor;
    editor.isVoid = (element) => isTableOfContent(element) || isVoid(element);

    editor.renderElement = (props) => {
      if (isTableOfContent(props.element)) {
        return <TableOfContent {...props} readOnly={options.readOnly} />;
      } else {
        return renderElement(props);
      }
    };

    return editor;
  };

export default tableOfContent;
