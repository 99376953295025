import { useState, useEffect, useContext, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { EditorContext, useEditorSelector } from 'app/state/contexts/EditorContext';
import { centeredPopperElement } from 'app/utils/helper-functions';
import { CommentContext } from 'app/state/contexts/CommentContext';

const Portal = ({ children }) => {
  const { editorContainerRef } = useContext(EditorContext);

  if (!editorContainerRef.current) return null;
  return createPortal(children, editorContainerRef.current);
};

export const AddComment = ({ paragraph, element }) => {
  const selectionId = useEditorSelector((editor) => editor.selectionId);
  const [show, setShow] = useState(true);
  const [popperElement, setPopperElement] = useState(null);
  const referenceElement = useMemo(() => centeredPopperElement(paragraph), [paragraph]);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'right-start',
  });
  const { addComment, setCommentsFocusRequested } = useContext(CommentContext);

  const handleClick = (e) => {
    e.preventDefault();
    console.debug('Click! Add comment uuid', element.uuid);
    const citation = window.getSelection().toString() || null;
    addComment({ onId: element.uuid, onType: element.type, citation });
    setCommentsFocusRequested(true);
  };

  useEffect(() => {
    setShow(selectionId && selectionId === element.uuid);
  }, [element.uuid, selectionId]);

  if (!show) {
    return null;
  }

  return (
    <Portal>
      <div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
        <div className="comment--add-coment">
          <i onMouseDown={handleClick} className="fa-regular fa-message-plus" />
        </div>
      </div>
    </Portal>
  );
};

export const useComments = (uuid) => {
  const { comments } = useContext(CommentContext);
  return comments.filter((comment) => comment.onId === uuid);
};
