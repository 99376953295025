import { useContext } from 'react';
import { DiffContext } from 'app/state/contexts/DiffContext';
import { getDiffClass, getNodeId } from 'app/slate/compareUtils';

const DiffedElement = (props) => {
  const { renderElement, side, element } = props;
  const { diffState } = useContext(DiffContext);
  const diffs = diffState[side];
  const change = diffs[getNodeId(element)];

  if (change?.state === 'missing') {
    return <div className={`diffed-${element.type} diffed-${getDiffClass(side)}`}>{renderElement({ ...props, diff: change?.computedDiff })}</div>;
  }

  if (change?.type === 'page') {
    return renderElement({ ...props, extraOffset: change.offset });
  }

  return renderElement({ ...props, diff: change?.computedDiff });
};

export default DiffedElement;
