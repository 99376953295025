import React, { useEffect, useState } from 'react';
import { i18n } from 'app/utils/i18n';
import { useList, useUpdateList } from 'app/api/foundation/list-queries';
import { useParams } from 'react-router-dom';
import { IconButton, Modal } from 'app/components';

const ItemEdit = ({ item = undefined, closeModal, addItem, updateItem, busy = false }) => {
  const [code, setCode] = useState(item?.code || '');
  const [value, setValue] = useState(item?.value || '');
  const [sortOrder, setSortOrder] = useState(item?.sortOrder || '0');

  function handleSubmit(e) {
    e.preventDefault();
    if (code && value && sortOrder) {
      if (item) {
        updateItem({ ...item, code, value, sortOrder });
      } else {
        addItem({ code, value, sortOrder });
      }
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <label className={'col-form-label'} htmlFor="code">
        {i18n('lists.values.code')}
      </label>
      <input className="form-control" type="text" name="name" id="name" value={code} onChange={(e) => setCode(e.target.value)} />
      <label className={'col-form-label'} htmlFor="value">
        {i18n('lists.values.value')}
      </label>
      <input className="form-control" type="text" name="value" id="value" value={value} onChange={(e) => setValue(e.target.value)} />
      <label className={'col-form-label'} htmlFor="sortOrder">
        {i18n('lists.values.sortorder')}
      </label>
      <input className="form-control" type="text" name="sortOrder" id="value" value={sortOrder} onChange={(e) => setSortOrder(e.target.value)} />
      <div className="mt-4 d-flex justify-content-end">
        <button className="button clear" type="button" onClick={closeModal}>
          {i18n('common.cancel')}
        </button>
        <button className="button" disabled={busy}>
          {i18n('common.save')}
        </button>
      </div>
    </form>
  );
};

const ListEdit = () => {
  const [name, setName] = useState('');
  const [listValues, setListValues] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [busy, setBusy] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [listItemToEdit, setListItemToEdit] = useState();

  const { id } = useParams();
  const { data: list } = useList(id);
  const { mutate: updateList } = useUpdateList(id);

  useEffect(() => {
    if (list) {
      setName(list.name);
      setListValues(list.values);
      closeModal();
      setBusy(false);
    }
  }, [list]);

  function closeModal() {
    setShowModal(false);
  }

  function handleNewClick() {
    setShowModal(true);
  }

  function handleListClick(item) {
    setListItemToEdit(item);
    setShowModal(true);
  }

  function handleAddItem(item) {
    const updatedItems = [...listValues, item];
    doUpdate({ ...list, values: updatedItems });
  }

  function handleUpdateItem(item) {
    const updatedItems = listValues.map((v) => {
      if (item.id === v.id) {
        return item;
      }

      return v;
    });

    doUpdate({ ...list, values: updatedItems });
  }

  function handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }

    setIsSubmitted(true);

    if (name) {
      const updatedList = { ...list, name, values: listValues };
      doUpdate(updatedList);
    }
  }

  function doUpdate(list) {
    setBusy(true);

    updateList(list, {
      onSuccess: (data) => {
        if (data) {
          setName(data.name);
          setListValues(data.values);
          closeModal();
          setBusy(false);
          setListItemToEdit();
        }
      },
    });
  }

  return (
    <>
      <div className="content-title">
        <h1 className="">{i18n('lists.title')}</h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label className={'col-form-label'} htmlFor="name">
              {i18n('lists.name')}
            </label>
            <input className="form-control" type="text" name="name" id="name" value={name} onChange={(e) => setName(e.target.value)} />
            {isSubmitted && !name ? <div className="invalid-feedback d-block">Please enter a name.</div> : null}
          </div>

          <table>
            <thead>
              <tr>
                <th>{i18n('lists.values.code')}</th>
                <th>{i18n('lists.values.value')}</th>
                <th>{i18n('lists.values.sortOrder')}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {listValues.map((item, index) => {
                return (
                  <tr key={index} onClick={() => handleListClick(item)}>
                    <td>{item.code}</td>
                    <td>{item.value}</td>
                    <td>{item.sortOrder}</td>
                    <td>
                      <IconButton icon="pen" />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div className="mt-4 d-flex justify-content-between">
            <IconButton onClick={handleNewClick} icon="plus" />
            <button className="button light-blue" type="submit" disabled={busy}>
              {i18n('common.save')}
            </button>
          </div>
        </form>
        {showModal ? (
          <Modal titleLabelKey="organisations.new-org.modal.title" show={showModal} onClose={closeModal}>
            <ItemEdit item={listItemToEdit} closeModal={closeModal} addItem={handleAddItem} updateItem={handleUpdateItem} busy={busy} />
          </Modal>
        ) : null}
      </div>
    </>
  );
};

export default ListEdit;
