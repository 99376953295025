import React from 'react';
import { i18n } from 'app/utils/i18n';
import LanguageIcon from './LanguageIcon';
import { Dropdown } from 'app/components';

export const langs = [
  { lang: 'sv', name: 'Svenska', labelKey: 'lang.sv', locale: 'sv-SE' },
  { lang: 'en', name: 'Engelska', labelKey: 'lang.en', locale: 'en-US' },
];

const LanguageSelector = ({ disabled = false, onChange, locale, sm }) => {
  const items = langs.map((lang) => ({ value: lang.locale, title: i18n(lang.labelKey), icon: <LanguageIcon language={lang.lang} /> }));

  const handleSelectLanguage = (lang) => {
    onChange?.(lang);
  };

  return <Dropdown sm={sm} disabled={disabled} value={locale} onChange={handleSelectLanguage} items={items} />;
};

export default LanguageSelector;
