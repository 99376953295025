import Tippy from '@tippyjs/react';
import { queryKeys, useChangeDocumentName, useDeleteDocument, useGetDocuments, useNewDocument, useRestoreDocument } from 'app/api/documents/document-queries';
import { FileZone, IconButton, IfHasPermission, Modal, SimpleTable } from 'app/components';
import { UserContext } from 'app/state/contexts/UserContext';
import authStore from 'app/utils/authStore';
import { i18n } from 'app/utils/i18n';
import moment from 'moment';
import { useContext, useState } from 'react';
import { useQueryClient } from 'react-query';
import { showMessage } from '../utils/messages';

function validName(documentName) {
  return documentName.trim().length > 0 && documentName.trim().length < 65;
}

const Projects = () => {
  const { selectedOrganization } = useContext(UserContext);

  const [modalNewNameOpen, setModalNewNameOpen] = useState(false);
  const [activeDocument, setActiveDocument] = useState();
  const [errorDocumentName, setErrorDocumentName] = useState(false);
  const [documentName, setDocumentName] = useState('');
  const [showDeleted, setShowDeleted] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [orderBy, setOrderBy] = useState('name');
  const [orderDirection, setOrderDirection] = useState('asc');

  const newDocument = useNewDocument();

  const { mutate: changeDocumentName } = useChangeDocumentName();
  const { mutate: deleteDocument } = useDeleteDocument();
  const { mutate: restoreDocument } = useRestoreDocument();

  const { data: projects, isLoading } = useGetDocuments(selectedOrganization, showDeleted, orderBy, orderDirection);

  const queryClient = useQueryClient();

  const canDelete = authStore.hasPermission('DOCUMENT_DELETE', selectedOrganization);

  const handleNameEdit = (project) => {
    setActiveDocument(project);
    setDocumentName(project.name);
    setModalNewNameOpen(true);
  };

  const handleNameChangeSubmit = (e) => {
    e.preventDefault();
    if (!validName(documentName)) {
      setErrorDocumentName(true);
      return;
    }
    changeDocumentName(
      {
        documentSuperId: activeDocument.superId,
        documentRevisionId: activeDocument.revisionId,
        newName: documentName,
      },
      {
        onSuccess: handleModalClose,
      }
    );
  };

  const handleCreateSuccess = () => {
    queryClient.invalidateQueries([queryKeys.documents, { selectedOrganization }]);
    showMessage('success', i18n('dashboard.document-import-success'));
  };

  const handleNewProjectSubmit = (event) => {
    event.preventDefault();
    if (!documentName) {
      setErrorDocumentName(true);
      return;
    }

    let invalidDocumentName = documentName.trim().length < 1 || documentName.trim().length > 64;
    if (invalidDocumentName) {
      setErrorDocumentName(true);
      return;
    }

    newDocument.mutate(
      {
        documentName: documentName.trim(),
        selectedOrganization: selectedOrganization,
      },
      {
        onSuccess: () => {
          setModalOpen(false);
          setDocumentName('');
          setErrorDocumentName(false);
        },
      }
    );
  };

  const handleModalClose = () => {
    setModalNewNameOpen(false);
    setModalOpen(false);
    setDocumentName('');
    setErrorDocumentName(false);
  };

  const rows = (projects ?? []).map((project) => {
    const dropdownItems = (() => {
      const items = [
        {
          title: i18n('project-list.document.edit-name'),
          icon: 'pen',
          onClick: () => handleNameEdit(project),
        },
      ];
      if (canDelete) {
        if (project.deletedAt) {
          items.push({
            title: i18n('project-list.document.restore'),
            icon: 'trash-undo',
            onClick: () => restoreDocument({ documentSuperId: project.superId, selectedOrganization }),
          });
        } else {
          items.push({
            title: i18n('project-list.document.delete'),
            icon: 'trash',
            onClick: () => deleteDocument({ documentSuperId: project.superId, selectedOrganization }),
          });
        }
      }
      return items;
    })();

    return {
      key: project.superId,
      cells: [
        { key: `${project.superId}name`, component: <span>{project.name}</span> },
        { key: `${project.superId}superCreatedAt`, component: <span>{moment(project.superCreatedAt).format('YYYY-MM-DD HH:mm')}</span> },
        { key: `${project.superId}createdAt`, component: <span>{moment(project.createdAt).format('YYYY-MM-DD HH:mm')}</span> },
      ],
      linkTo: `/documents/${project.superId}`,
      deleted: !!project.deletedAt,
      dropdownItems: dropdownItems,
    };
  });

  const OrderByWrapper = ({ orderAttribute, children }) => {
    return (
      <div
        onClick={() => {
          if (orderBy === orderAttribute) {
            setOrderDirection(orderDirection === 'asc' ? 'desc' : 'asc');
          }
          setOrderBy(orderAttribute);
        }}
        className="order-by-wrapper"
        style={{
          borderBottom: `2px solid ${orderBy === orderAttribute ? '#4db894' : 'transparent'}`,
        }}
      >
        {children}
        <div className="icon-wrapper">
          <i
            className={`fa-light fa-sort-up`}
            style={{
              transform: orderDirection === 'asc' ? 'rotate(180deg)' : '',
              marginBottom: orderDirection === 'asc' ? '8px' : '',
              visibility: orderBy === orderAttribute ? 'visible' : 'hidden',
            }}
          />
        </div>
      </div>
    );
  };

  const headers = [
    {
      key: i18n('dashboard.ongoing-projects.title'),
      component: (
        <div style={{ display: 'flex' }}>
          <OrderByWrapper orderAttribute="name">
            <h2>{i18n('dashboard.ongoing-projects.title')}</h2>
          </OrderByWrapper>
          <div className="form-check check-input">
            <input
              className="form-check-input"
              type="checkbox"
              id="toggleShowDeleted"
              checked={showDeleted}
              onChange={(e) => setShowDeleted(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="toggleShowDeleted">
              {i18n('dashboard.ongoing-projects.checkbox.show-deleted')}
            </label>
          </div>
        </div>
      ),
    },
    {
      key: i18n('dashboard.ongoing-projects.created'),
      component: (
        <OrderByWrapper orderAttribute="superCreatedAt">
          <h2>{i18n('dashboard.ongoing-projects.created')}</h2>
        </OrderByWrapper>
      ),
    },
    {
      key: i18n('dashboard.ongoing-projects.updated'),
      component: (
        <OrderByWrapper orderAttribute="createdAt">
          <h2>{i18n('dashboard.ongoing-projects.updated')}</h2>
        </OrderByWrapper>
      ),
    },
    {
      key: 'projectImportAndAdd',
      component: (
        <div className="document-import-wrapper">
          <IfHasPermission permission="DOCUMENT_IMPORT">
            <Tippy
              interactive={true}
              offset={[-130, 10]}
              placement="bottom"
              trigger="click"
              content={
                <div className="add-new-popup">
                  <FileZone type="json" selectedOrganization={selectedOrganization} url="/gaby/documents/import" onSuccess={handleCreateSuccess} />
                </div>
              }
            >
              <button className="fab">
                <i className="fa-regular fa-file-import font-15" />
              </button>
            </Tippy>
          </IfHasPermission>
          <IfHasPermission permission="DOCUMENT_CREATE">
            <IconButton icon="plus" onClick={() => setModalOpen(true)} />
          </IfHasPermission>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="project--overview">
        <SimpleTable headers={headers} rows={rows} isLoading={isLoading} className="projects" />
      </div>

      <Modal titleLabelKey="project-list.new-name.modal.title" show={modalNewNameOpen} onClose={handleModalClose}>
        <form onSubmit={handleNameChangeSubmit}>
          <div className="mt-4">
            <input type="text" className="form-control" ref={(o) => o?.focus()} value={documentName} onChange={(e) => setDocumentName(e.target.value)} />
            <span className="text-help">{errorDocumentName ? i18n('project-list.new-name.field.nameError') : ''}</span>
          </div>
          <div className="d-flex justify-content-end mt-4">
            <button type="button" className="button clear" onClick={handleModalClose}>
              {i18n('common.button.cancel')}
            </button>
            <button type="submit" className="button primary">
              {i18n('common.button.save')}
            </button>
          </div>
        </form>
      </Modal>
      <Modal titleLabelKey="dashboard.new-project.modal.title" show={modalOpen} onClose={handleModalClose}>
        <form onSubmit={handleNewProjectSubmit}>
          <input type="text" className="form-control mb-4" value={documentName} onChange={(event) => setDocumentName(event.target.value)} />
          <span className="text-help">{errorDocumentName ? i18n('dashboard.new-project.modal.field.nameError') : ''}</span>
          <div className="d-flex justify-content-end">
            <button type="button" className="button clear" onClick={handleModalClose}>
              {i18n('common.button.cancel')}
            </button>
            <button className="btn btn-primary">{i18n('common.button.save')}</button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default Projects;
