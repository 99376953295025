import { useRef, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Transforms, Element } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';

const isNote = (node) => Element.isElement(node) && node.type === 'note';

export const NoteMenu = ({ reference, element }) => {
  const ref = useRef();
  const editor = useSlate();

  useLayoutEffect(() => {
    const el = ref.current;

    if (!el) {
      return;
    }

    el.style.opacity = '1';
    el.style.top = `${reference.current.offsetTop + reference.current.offsetParent.offsetTop + 40}px`;
    el.style.left = `${reference.current.offsetLeft + reference.current.offsetParent.offsetLeft + 645}px`;
  });

  const handleClick = (e) => {
    e.preventDefault();
    console.debug('Click! Delete note', ReactEditor.findPath(editor, element));
    Transforms.removeNodes(editor, {
      at: ReactEditor.findPath(editor, element),
      match: (node) => isNote(node),
    });
  };

  return createPortal(
    <div ref={ref} className="comment--add-coment">
      <i onMouseDown={handleClick} className="fa-regular fa-square-minus" />
    </div>,
    document.body
  );
};

const NoteInfo = ({ reference }) => {
  const ref = useRef();

  useLayoutEffect(() => {
    const el = ref.current;

    if (!el || !reference) {
      return;
    }

    el.style.opacity = '1';
    el.style.top = `${reference.offsetTop + reference.offsetParent.offsetTop}px`;
    el.style.left = `${reference.offsetLeft + reference.offsetParent.offsetLeft - 180}px`;
  });
  return createPortal(
    <div ref={ref} className="comment--add-coment">
      Note
    </div>,
    document.body
  );
};

export const Note = (props) => {
  const { attributes, element, children } = props;
  const [hovered, setHovered] = useState(false);
  const [mHovered, setMHovered] = useState(false);

  const showMenu = hovered || mHovered;

  return (
    <div {...attributes} className="note" onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      {!showMenu ? null : (
        <NoteMenu reference={attributes.ref} element={element} onMouseEnter={() => setMHovered(true)} onMouseLeave={() => setMHovered(false)} />
      )}
      <NoteInfo reference={attributes.ref.current} />
      {children}
    </div>
  );
};

export const insertNote = (editor) => {
  if (!editor.selection) {
    return;
  }

  Transforms.insertNodes(
    editor,
    {
      type: 'note',
      children: [
        {
          type: 'paragraph',
          children: [{ text: '' }],
        },
      ],
    },
    {
      at: editor.selection.focus,
    }
  );
};

const notes = (editor) => {
  const { renderElement } = editor;

  editor.renderElement = (props) => {
    if (isNote(props.element)) {
      return <Note {...props} />;
    } else {
      return renderElement(props);
    }
  };

  return editor;
};

export default notes;
