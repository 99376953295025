const { forwardRef } = require('react');

export const Clickable = forwardRef(function Clickable(props, ref) {
  const { href, children, ...rest } = props;
  return (
    <a ref={ref} role="button" tabIndex={0} {...rest}>
      {children}
    </a>
  );
});
