import { Modal as BootstrapModal } from 'react-bootstrap';
import { i18n } from 'app/utils/i18n';

const Modal = ({ titleLabelKey, children = null, show, onClose, size }) => {
  return (
    <BootstrapModal show={show} onHide={onClose} size={size}>
      {titleLabelKey ? (
        <BootstrapModal.Header className={titleLabelKey ? 'bg-blue-200' : 'bg-white'}>
          <h3 className="modal-title">{i18n(titleLabelKey)}</h3>
          <button onClick={onClose} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </BootstrapModal.Header>
      ) : null}

      <BootstrapModal.Body>{children}</BootstrapModal.Body>
    </BootstrapModal>
  );
};

export default Modal;
