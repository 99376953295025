import { useMutation, useQuery, useQueryClient } from 'react-query';
import request from 'app/api/request';
import { i18n } from 'app/utils/i18n';
import { MissingSheet, parseSheetOptions, parseXlsxData } from './utils';
import { queryKeys as documentQueryKeys } from 'app/api/documents/document-queries';
import ServerErrorHandler from 'app/ErrorHandler';
import { showMessage } from 'app/utils/messages';

export const queryKeys = {
  referenceData: 'REFERENCE_DATA',
  referenceDataList: 'REFERENCE_DATA_LIST',
  parsedSheet: 'PARSED_SHEET',
  xlsxFile: 'XLSX_FILE',
  xlsxFiles: 'XLSX_FILES',
};

export function useXlsxFile(fileRevisionId) {
  return useQuery(
    [queryKeys.xlsxFile, fileRevisionId],
    async () => {
      const { data } = await request.get(`/gaby/files/${fileRevisionId}`);
      return {
        selectedXlsx: data,
        sheetOptions: parseSheetOptions(data),
      };
    },
    {
      enabled: !!fileRevisionId,
      onError: ServerErrorHandler,
    }
  );
}

export function useXlsxFiles(documentId) {
  return useQuery(
    [queryKeys.xlsxFiles, documentId],
    async () => {
      const res = await request.get(`/gaby/files?documentSuperId=${documentId}&type=xlsx`);
      return res.data;
    },
    {
      onError: ServerErrorHandler,
    }
  );
}

export function useReferenceData(referenceDataId, documentId) {
  // TODO this is sometimes called in situations when we actually need to fetch
  // a specifi version of a reference data. This method always fetches the latest one.
  return useQuery(
    [queryKeys.referenceData, { referenceDataId, documentId }],
    async () => {
      const res = await request.get(`/gaby/reference-data?documentSuperId=${documentId}&superId=${referenceDataId}&current=true&metadata=true`);
      return res.data[0];
    },
    {
      onError: ServerErrorHandler,
    }
  );
}

export function useUpdateReferenceData() {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ document, referenceData }) => {
      const res = await request.post(`/gaby/reference-data`, referenceData);

      return await request.post(`/gaby/documents/${document.superId}/actions`, {
        action: 'update_reference_data',
        revisionId: document.content.referenceData.find((item) => item.superId === referenceData.superId).revisionId,
        data: {
          revisionId: res.data.revisionId,
          superId: res.data.superId,
        },
      });
    },
    {
      onError: ServerErrorHandler,
      onSuccess: (_, { document, referenceData }) => {
        queryClient.invalidateQueries(queryKeys.referenceDataList);
        queryClient.invalidateQueries(queryKeys.referenceData, {
          referenceDataId: referenceData.superId,
          documentId: document.superId,
        });
        queryClient.invalidateQueries(documentQueryKeys.document, document.superId);
      },
    }
  );
}

export function useCreateReferenceData() {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ document, referenceData }) => {
      const res = await request.post(`/gaby/reference-data`, referenceData);

      return await request.post(`/gaby/documents/${document.superId}/actions`, {
        action: 'create_reference_data',
        data: {
          revisionId: res.data.revisionId,
          superId: res.data.superId,
        },
      });
    },
    {
      onError: ServerErrorHandler,
      onSuccess: (_, { document }) => {
        queryClient.invalidateQueries(queryKeys.referenceDataList);
        queryClient.invalidateQueries(documentQueryKeys.document, document.superId);
      },
    }
  );
}

export function useDeleteReferenceData() {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ documentId, referenceData }) => {
      await request.post(`/gaby/documents/${documentId}/actions`, {
        action: 'delete_reference_data',
        revisionId: referenceData.revisionId,
      });
    },
    {
      onSuccess: (_, { documentId, referenceData }) => {
        queryClient.invalidateQueries(queryKeys.referenceDataList);
        queryClient.invalidateQueries(queryKeys.referenceData, {
          documentId,
          referenceDataId: referenceData.superId,
        });
        queryClient.invalidateQueries(documentQueryKeys.document, documentId);
      },
      onError: (error) => {
        const response = JSON.parse(error.request.response);
        if (response.message !== 'reference-in-use') {
          ServerErrorHandler(error);
        }
      },
    }
  );
}

export function useReferenceList(document) {
  return useQuery(
    [queryKeys.referenceDataList, document?.revisionId],
    async () => {
      if (!Array.isArray(document.content.referenceData)) {
        return Promise.resolve([]);
      }
      return await Promise.all(
        document.content.referenceData.map(async (link) => {
          const { data } = await request.get(`/gaby/reference-data/${link.revisionId}`);
          return data;
        })
      );
    },
    {
      enabled: !!document,
      onError: ServerErrorHandler,
    }
  );
}

export function useParsedSheet(data, selectedSheet, locale) {
  return useQuery(
    [queryKeys.parsedSheet, data?.revisionId, { selectedSheet, locale }],
    () => {
      // TODO use service worker or pass over to server
      return new Promise((resolve, reject) => {
        setImmediate(() => {
          try {
            resolve(parseXlsxData(data, selectedSheet, locale));
          } catch (e) {
            reject(e);
          }
        });
      });
    },
    {
      retry: false,
      enabled: !!data && !!selectedSheet && !!locale,
      onError: (error) => {
        if (!error instanceof MissingSheet) showMessage('error', i18n('reference-data.failed-parsing-xlsx'));
      },
    }
  );
}

export function useSelectedData(sheetData, selection, currentReferenceData) {
  const status = {
    isLoading: sheetData.isLoading,
    isSuccess: sheetData.isSuccess,
    isError: sheetData.isError,
    isIdle: sheetData.isIdle,
  };

  if (!sheetData.isSuccess) {
    return status;
  }

  if (!selection) {
    return {
      isLoading: false,
      isSuccess: false,
      isError: true,
      isIdle: false,
    };
  }

  const startRow = Math.min(selection.start.i, selection.end.i);
  const endRow = Math.max(selection.start.i, selection.end.i) + 1; // +1 is needed as .slice([end]) is excluding
  const startColumn = Math.min(selection.start.j, selection.end.j);
  const endColumn = Math.max(selection.start.j, selection.end.j) + 1; // +1 is needed as .slice([end]) is excluding

  const selectedData = sheetData.data.slice(startRow, endRow).map((row) => row.slice(startColumn, endColumn));

  const old = currentReferenceData.selectionContent;
  selectedData.forEach((row, y) => {
    row.forEach((cell, x) => {
      const oldCell = old?.[y]?.[x];
      cell.format = oldCell?.format;
      cell.styling = oldCell?.styling;
      cell.background = oldCell?.background;
      cell.border = oldCell?.border;
    });
  });

  return {
    ...status,
    data: selectedData,
  };
}
