import request from 'app/api/request';
import ServerErrorHandler from 'app/ErrorHandler';
import { useQueryClient, useMutation, useQuery } from 'react-query';
import { showMessage } from 'app/utils/messages';
import { i18n } from 'app/utils/i18n';

export const queryKeys = {
  labels: 'LABELS',
  label: 'LABEL',
  labelsAll: 'LABELS_ALL',
};

export function useLabels(params) {
  return useQuery(
    [queryKeys.labels],
    async () => {
      const urlParams = Object.entries(params)
        .filter(([key, value]) => !!value)
        .map(([key, value]) => {
          const searchValue = encodeURIComponent(`${value}${key === 'key' || key === 'text' ? '%' : ''}`);
          return `${key}=${searchValue}`;
        });

      const res = await request.get(`/foundation/labels?size=20&${urlParams}`).catch((err) => {
        if (err.status === 404) {
          return null;
        } else {
          throw err;
        }
      });

      return { items: res?.data?._embedded?.labels || [], page: res?.data?.page };
    },
    {
      onError: ServerErrorHandler,
    }
  );
}

export function useLabelsByKey(key) {
  return useQuery([queryKeys.label, key], {
    queryFn: async () => {
      const res = await request.get(`/foundation/labels?key=${key}`).catch((err) => {
        if (err.status === 404) {
          return null;
        } else {
          throw err;
        }
      });

      return res?.data?._embedded?.labels || [];
    },
    onError: ServerErrorHandler,
    enabled: false,
  });
}

export function useCreateLabel() {
  return useMutation(
    async ({ texts, key, visibility }) => {
      return await Promise.all(
        Object.entries(texts).map(([locale, text]) => {
          return request.post(`/foundation/labels`, { text, key, visibility, locale });
        })
      );
    },
    {
      onError: ServerErrorHandler,
      onSuccess: (data) => {
        showMessage('success', i18n('label.created'));
        return data;
      },
    }
  );
}

export function useUpdateLabel() {
  return useMutation(
    async (labels) => {
      return await Promise.all(
        labels.map((label) => {
          return request.put(`/foundation/labels/${label.id}`, label);
        })
      );
    },
    {
      onError: ServerErrorHandler,
      onSuccess: (data) => {
        showMessage('success', i18n('label.updated'));
        return data;
      },
    }
  );
}

export function useAllLabels(locale = '') {
  return useQuery([queryKeys.labelsAll, locale], {
    queryFn: async () => {
      const params = locale ? `size=20000&locale=${locale}` : 'size=20000';
      const res = await request.get(`/foundation/labels?${params.toString()}`).catch((err) => {
        if (err.status === 404) {
          return null;
        } else {
          throw err;
        }
      });

      return res?.data?._embedded?.labels || [];
    },
    onError: ServerErrorHandler,
  });
}

export function useImportLabels() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ content, mode }) => {
      return request.put(`/foundation/labels/list?mode=${mode}`, content?.labels);
    },
    {
      onError: ServerErrorHandler,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [queryKeys.labels] });
      },
    }
  );
}
