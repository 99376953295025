import { Leaf } from 'app/slate/components/Leaf';
import { Element } from 'app/slate/components/Element';

const defaultRenderers = (editor) => {
  editor.renderElement = (props) => <Element {...props} />;
  editor.renderLeaf = (props) => <Leaf {...props} showFormatting={editor.showFormatting} />;
  return editor;
};

export default defaultRenderers;
