import { useEffect, useState } from 'react';

export function dispatchMessage(eventType, message) {
  const event = new CustomEvent(eventType, {
    detail: message,
  });
  document.dispatchEvent(event);
}

export const EventType = {
  ADD_MESSAGE: 'ADD_MESSAGE',
  UPDATE_MESSAGE: 'UPDATE_MESSAGE',
  REMOVE_MESSAGE: 'REMOVE_MESSAGE',
};

/**
 * Hook for listening to messages sent by MessageDispatcher.
 */
function useToasts() {
  const [messages, setMessages] = useState(new Map());

  useEffect(() => {
    document.addEventListener(EventType.ADD_MESSAGE, onAddMessage);
    document.addEventListener(EventType.UPDATE_MESSAGE, onAddMessage);
    document.addEventListener(EventType.REMOVE_MESSAGE, onRemoveMessage);

    return () => {
      document.removeEventListener(EventType.ADD_MESSAGE, onAddMessage);
      document.removeEventListener(EventType.UPDATE_MESSAGE, onAddMessage);
      document.removeEventListener(EventType.REMOVE_MESSAGE, onRemoveMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  function onAddMessage(e) {
    const message = getMessageFromEvent(e);

    if (message) {
      const updatedMessages = new Map(messages);
      updatedMessages.set(message.id, message);

      if (message.removeTime) {
        setTimeout(() => {
          removeMessage(message);
        }, message.removeTime);
      }

      setMessages(updatedMessages);
    }
  }

  function onRemoveMessage(e) {
    const message = getMessageFromEvent(e);

    if (message) {
      removeMessage(message);
    }
  }

  function removeMessage(message) {
    const updatedMessages = new Map(messages);
    updatedMessages.delete(message.id);

    setMessages(updatedMessages);
  }

  return { messages };
}

function isCustomEvent(event) {
  return 'detail' in event;
}

function getMessageFromEvent(e) {
  if (isCustomEvent(e)) {
    const message = e.detail;
    return message;
  }

  return undefined;
}

export default useToasts;
