import { useCallback } from 'react';
import { i18n } from 'app/utils/i18n';
import { wrapper } from './wrapper-helper';
import { CurrentFileBlob } from './UpdateReferenceData';
import { StyledDataSheet } from 'app/components';

export function SelectRange(props) {
  const { sheetData, setSelection, maybeFile, maybeSheet, onNewFileClick, onChooseSheetClick, onAbortClick, onSaveSelectionClick } = props;

  const handleRangeSelect = useCallback(
    (selection) => {
      setSelection({
        ...selection,
        sheet: maybeSheet,
      });
    },
    [maybeSheet, setSelection]
  );

  return wrapper(
    i18n('reference-data.update-selection.modal.title'),
    [sheetData],
    () => (
      <>
        <CurrentFileBlob file={maybeFile}>
          <div onClick={onNewFileClick}>{i18n('reference-data.update-sheet.link.choose-new-file')}</div>
          <div onClick={onChooseSheetClick}>{i18n('reference-data.choose-sheet')}</div>
        </CurrentFileBlob>
        <div className="flex">
          <div
            className="data-sheet-fixed-wrapper"
            style={{
              width: sheetData.data[0].length * 100 + 'px',
              height: sheetData.data.length * 20 + 'px',
            }}
          >
            <StyledDataSheet data={sheetData.data} onSelect={handleRangeSelect} />
          </div>
        </div>
      </>
    ),
    () => (
      <>
        <div className="modal-btn-group">
          <button className="button clear" onClick={onAbortClick}>
            {i18n('common.cancel')}
          </button>
          <button className="button primary" onClick={onSaveSelectionClick}>
            {i18n('reference-data.update-selection.save')}
          </button>
        </div>
      </>
    )
  );
}
