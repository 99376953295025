import React, { useContext, useEffect } from 'react';
import { i18n } from 'app/utils/i18n';
import { setActiveNode } from 'app/state/redux/documentSlice';
import { EditorContext, useEditorDispatch, useEditorSelector } from 'app/state/contexts/EditorContext';
import { useGetDocumentTableOfContent } from 'app/api/documents/document-queries';

export const DocumentHeaders = ({ containerRef }) => {
  const { shadowHost } = useContext(EditorContext);
  const section = useEditorSelector((editor) => editor.section);
  const selectedNodeId = useEditorSelector((editor) => editor.activeNode)?.uuid;
  const editorDispatch = useEditorDispatch();
  const locale = useEditorSelector((editor) => editor.locale);

  const pDocument = useEditorSelector((editor) => editor.pDocument);
  const { data } = useGetDocumentTableOfContent(pDocument.revisionId, { sectionSuperId: section.superId, locale, headerHierarchy: ['h1', 'h2'] });

  const onHeaderClick = (superId) => {
    const yOffset = -117;
    const element = shadowHost.shadowRoot.querySelector(`#anchor-${superId}`);
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
    const node = findNode(superId, section.content);
    if (node) {
      editorDispatch(setActiveNode(node));
    }
  };

  useEffect(() => {
    const element = document.querySelector(`#header-preview-${selectedNodeId}`);
    if (shouldScroll(element, containerRef.current)) {
      const yOffset = -61; // offset for the sticky header
      const y = element.offsetTop + yOffset;
      containerRef.current.scrollTo({ top: y, behavior: 'smooth' });
    }
  }, [selectedNodeId, containerRef]);

  return (
    <div className="preview--container">
      <div className="px-3 pt-4 pb-3 w-100 bg-white sticky-top">
        <div className="fw-bold">{i18n('doc-editor.sidebar.disposition')}</div>
      </div>
      <div className="px-3 w-100">
        <OrderedList activeId={selectedNodeId} headerList={data?.content} onHeaderClick={onHeaderClick} />
      </div>
    </div>
  );
};

const OrderedList = ({ activeId, headerList, onHeaderClick }) => {
  const filteredHeaders = headerList?.filter((header) => header.text !== '') || [];

  if (filteredHeaders.length === 0) {
    return headerList?.map((header) => <OrderedList key={header.uuid} headerList={header.children} />) || null;
  }

  return (
    <ol className="list-unstyled">
      {headerList.map((header) => (
        <li key={header.uuid}>
          <button
            id={`header-preview-${header.uuid}`}
            className={headerItemClassName(header, activeId === header.uuid)}
            onClick={() => onHeaderClick(header.uuid)}
          >
            {header.name}
          </button>
          <div className="ms-3">
            <OrderedList activeId={activeId} headerList={header.children} onHeaderClick={onHeaderClick} />
          </div>
        </li>
      ))}
    </ol>
  );
};

const shouldScroll = (element, scrollElement) => {
  if (element == null) {
    return false;
  }

  const parentHeight = scrollElement.clientHeight;
  const elementTop = element.getBoundingClientRect().top;
  const elementBottom = element.getBoundingClientRect().bottom;

  if (elementTop < 0) {
    return true;
  }

  if (elementBottom > parentHeight) {
    return true;
  }

  return false;
};

function findNode(uuid, nodes) {
  for (const node of nodes) {
    if (node.uuid === uuid) return node;
    if (node.children) {
      const child = findNode(uuid, node.children);
      if (child) return child;
    }
  }
}

function headerItemClassName(header, isActive) {
  let textColor = '';
  let textSize = 'fs-2';

  if (header.type !== 'h1') {
    textColor = 'text-gray-800';
    textSize = 'fs-4';
  }
  if (isActive) {
    textColor = 'text-blue-800';
  }

  return `${textSize} ${textColor} item w-100 border-0 text-start rounded px-2 py-1 mb-2 ${isActive ? 'active bg-blue-200' : 'bg-transparent'}`;
}
