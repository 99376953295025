const css = `
@media screen {
.editor {
  position: relative;
  display: grid;
}
}
.editor {
  padding-top: 60px;
  row-gap: 60px;
  z-index: 1;
}               

.layout-leaf-comment { 
  background: #ffd56e;
}

.editor-image--container,
.editor-table--container,
.editor-table-of-content--container {
  position: relative;
}
.editor-image--container .controls--container,
.editor-table--container .controls--container,
.editor-table-of-content--container .controls--container {
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 5px;
  right: 5px;
  background: #f2f2f2;
  border-radius: 4px;
  box-shadow: 1px 1px 2px black;
  transition: all 300ms ease-in-out;
  opacity: 0;
  padding: 2px;
}
.editor-image--container .controls--container button,
.editor-table--container .controls--container button,
.editor-table-of-content--container .controls--container button {
  background: transparent;
  height: 30px;
  width: 30px;
  border: 0;
  cursor: pointer;
  color: red;
}
.editor-image--container:hover .controls--container,
.editor-table--container:hover .controls--container,
.editor-table-of-content--container:hover .controls--container {
  opacity: 1;
}

.editor-table-of-content--content:empty::after {
  content: "[Empty table of content]";
  color: red;
}

@media print {
  .editor {
    padding-top: 0;
    row-gap: 0;
  }
  .page-content {
    page-break-after: always;
  }
}
`;

export default css;
