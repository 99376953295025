/**
 * This file is just a placeholder and will be populated by FetchConfig.js.
 *
 * Do not put any config here, all config comes from gaby-systems.
 */

module.exports = {
  relativeUrls: true,
  host: {},
  defaultLocale: 'sv',
  auth: {
    loginUrl: '/auth/login',
  },
  urls: {
    featuretoggle: '/foundation/featuretoggle',
    lists: '/foundation/lists',
    labels: '/foundation/labels',
    users: '/registry/users',
    menu: '/foundation/menu',
    reports: '/',
  },
};
