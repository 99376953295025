import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { SupportModal, NavbarDefault } from 'app/components';
import Lists from './Lists';
import ListEdit from './ListEdit';
import ListNew from './ListNew';
import { useRouteMatch } from 'react-router-dom';

const ListRouter = () => {
  const match = useRouteMatch();

  return (
    <div>
      <div className="grid--container full--height columns">
        <div className="content">
          <Switch>
            <Route path={`${match.url}/new`}>
              <ListNew />
            </Route>
            <Route path={`${match.url}/:id`}>
              <ListEdit />
            </Route>
            <Route path="" exact>
              <Lists />
            </Route>
          </Switch>
        </div>
      </div>
      <NavbarDefault />
      <SupportModal />
    </div>
  );
};

export default ListRouter;
