import { i18n } from 'app/utils/i18n';
import filesaver from 'filesaver.js';
import { useAllLabels } from 'app/api/foundation/labels-queries';

const downloadFile = ({ content = '', fileName = 'file.txt', fileEncoding = 'utf-8', fileType = 'text/csv;charset=utf-8;' }) => {
  const encodedContent = new TextEncoder(fileEncoding, { NONSTANDARD_allowLegacyEncoding: true }).encode(content);
  const blob = new Blob([encodedContent], { fileType });
  filesaver.saveAs(blob, fileName);
};

const exportLabels = (labels) => {
  return {
    labels: labels.map((l) => ({
      id: l.id,
      key: l.key,
      text: l.text,
      locale: l.locale,
      visibility: l.visibility,
    })),
  };
};

function LabelDownloadButton() {
  const date = new Date();
  const fileName = `labels_${date.getFullYear()}_${date.getMonth() + 1}_${date.getDay() + 1}.json`;

  const { data, isLoading } = useAllLabels();

  const handleDownload = () => {
    downloadFile({
      content: JSON.stringify(exportLabels(data)),
      fileName,
    });
  };

  return (
    <button className="btn btn-secondary me-2" onClick={handleDownload} disabled={isLoading}>
      <i className="fa fa-download" /> {i18n('labels.download.button')}
    </button>
  );
}

export default LabelDownloadButton;
