import { useState } from 'react';
import { NavTabs } from 'app/components';
import { SupportModal } from 'app/components';
import { i18n } from 'app/utils/i18n';

export const TemplateSidebar = (props) => {
  const { style, documentStyle, onStyleChange, onDocumentStyleChange, errorMessage } = props;

  const [selectedTab, setSelectedTab] = useState(0);

  const tabs = [{ text: i18n('template-editor.page-structure') }, { text: i18n('template-editor.document-style') }];

  const onClickTab = (index) => {
    setSelectedTab(index);
  };

  const renderTabs = () => {
    switch (selectedTab) {
      case 0:
        return <textarea className="bg-light w-100 border-0 resize-none" rows={30} value={style} onChange={(e) => onStyleChange(e.target.value)}></textarea>;
      case 1:
      default:
        return (
          <textarea
            className="bg-light w-100 border-0 resize-none"
            rows={30}
            value={documentStyle}
            onChange={(e) => onDocumentStyleChange(e.target.value)}
          ></textarea>
        );
    }
  };

  return (
    <div className="template-toolbar flex-1 ms-4 mt-5">
      {errorMessage && (
        <div className="alert alert-danger" role="alert">
          <p>Något är fel med stylingen, korrigera koden för att spara på nytt.</p>
          {<code style={{ whiteSpace: 'pre-wrap' }}>{errorMessage}</code>}
        </div>
      )}
      <NavTabs items={tabs} activeIndex={selectedTab} onClick={onClickTab} />
      <div className="bg-light p-3 border-gray-300 border-bottom border-start border-end">{renderTabs()}</div>
      {props.children}
      <SupportModal />
    </div>
  );
};
