import React, { useState, useEffect, useCallback, useImperativeHandle, forwardRef } from 'react';
import { createPortal } from 'react-dom';
import { i18n } from 'app/utils/i18n';

export const ModalContentWrapper = ({ titleLabelKey = '', children = null }) => {
  return (
    <div className="py-r-5 px-r-9">
      {titleLabelKey ? <h1 className="mt-0 fs-r-3 mb-r-2 fw-bolder">{i18n(titleLabelKey)}</h1> : null}
      {children}
    </div>
  );
};

const modalElement = document.getElementById('modal-root');

const Modal = ({ children, defaultOpened = false, modalWrapperClass, onOpen, onClose }, ref) => {
  const [isOpen, setIsOpen] = useState(defaultOpened);

  const close = useCallback(() => {
    setIsOpen(false);
    onClose?.();
  }, [onClose]);

  const open = useCallback(() => {
    onOpen?.();
    setIsOpen(true);
  }, [onOpen]);

  const handleEscape = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        close();
      }
    },
    [close]
  );

  useEffect(() => {
    if (isOpen) document.addEventListener('keydown', handleEscape, false);

    return () => {
      document.removeEventListener('keydown', handleEscape, false);
    };
  }, [handleEscape, isOpen]);

  useImperativeHandle(
    ref,
    () => ({
      open,
      close,
    }),
    [open, close]
  );

  return createPortal(
    isOpen ? (
      <div className={modalWrapperClass ? `modal--wrapper ${modalWrapperClass}` : 'modal--wrapper'}>
        <div className="modal--overlay" onClick={close} />
        <div className="modal--container">{children}</div>
      </div>
    ) : null,
    modalElement
  );
};

export default forwardRef(Modal);
