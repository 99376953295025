import React, { useState, useContext } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import { i18n } from 'app/utils/i18n';
import { UserContext } from 'app/state/contexts/UserContext';
import { useHistory } from 'react-router';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button className="fs-3 fw-medium px-4 bg-transparent h-100 border-0 border-start" ref={ref} onClick={onClick}>
    {children}
  </button>
));

const CustomMenu = React.forwardRef(({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
  const [value, setValue] = useState('');

  const showFilter = children.length > 5;

  return (
    <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
      {showFilter ? (
        <div className="px-3">
          <Form.Control
            autoFocus
            className="my-2 w-100"
            placeholder={i18n('organization-menu.filter')}
            onChange={(e) => setValue(e.target.value)}
            value={value}
            size="sm"
          />
        </div>
      ) : null}

      <ul className="list-unstyled mb-0">
        {React.Children.toArray(children).filter(
          (child) => !value || child.props.children.props['data-name'].toLocaleLowerCase().includes(value.toLocaleLowerCase())
        )}
      </ul>
    </div>
  );
});

export default function OrganizationMenu() {
  const { organizations, selectedOrganization, selectOrganization } = useContext(UserContext);
  const history = useHistory();

  const handleSelectOrganization = (orgId) => {
    history.push('/');
    selectOrganization(orgId);
  };
  if (!organizations) {
    return null;
  }
  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle}>{organizations.find((organization) => organization.id === selectedOrganization)?.name || '???'}</Dropdown.Toggle>
      <Dropdown.Menu className="border-gray-300 rounded-sm min-wr-15" as={CustomMenu}>
        {organizations?.map((organization) => (
          <Dropdown.Item className="px-3 py-2" onClick={() => handleSelectOrganization(organization.id)} key={organization.id} eventKey={organization.id}>
            {organization.id === selectedOrganization ? (
              <div data-name={organization.name}>
                <span className="text-blue-800">{organization.name}</span>
                <i className="text-blue-800 fa-regular fa-check ms-3" />
              </div>
            ) : (
              <div data-name={organization.name}>{organization.name}</div>
            )}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
