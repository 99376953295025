import React, { useState, useContext } from 'react';
import { i18n } from 'app/utils/i18n';
import { useEditorSelector } from 'app/state/contexts/EditorContext';
import { useGetDocument, useDocumentAreaSave, useGetDocumentTableOfContent } from 'app/api/documents/document-queries';
import { UserContext } from 'app/state/contexts';
import { Dropdown } from 'app/components';
import { LanguageSelector } from 'app/components';
import { useDelayedSave } from 'app/utils/hooks/delayed-save';

export const AreaSettings = ({ documentId }) => {
  const { selectedOrganization } = useContext(UserContext);
  const { data: pDocument } = useGetDocument(documentId, selectedOrganization);

  const headerType = pDocument.content.header?.type || null;
  const footerType = pDocument.content.footer?.type || null;

  const { mutateAsync: saveArea } = useDocumentAreaSave();

  function onChangeHeaderType(type) {
    switch (type) {
      case 'headings':
        onChangeHeaderOptions(type, defaultHeadingValues);
        break;
      case 'simple':
        onChangeHeaderOptions(type, defaultSimpleValues);
        break;
      default:
        onChangeHeaderOptions(null);
    }
  }

  function onChangeFooterType(type) {
    switch (type) {
      case 'headings':
        onChangeFooterOptions(type, defaultHeadingValues);
        break;
      case 'simple':
        onChangeFooterOptions(type, defaultSimpleValues);
        break;
      default:
        onChangeFooterOptions(null);
    }
  }

  function onChangeHeaderOptions(type, options) {
    saveArea({
      documentSuperId: documentId,
      documentRevisionId: pDocument?.revisionId,
      header: type ? { type, options } : null,
    });
  }

  function onChangeFooterOptions(type, options) {
    saveArea({
      documentSuperId: documentId,
      documentRevisionId: pDocument?.revisionId,
      footer: type ? { type, options } : null,
    });
  }

  const areaTypes = [
    { title: i18n('project-details.area.type.none'), value: null },
    { title: i18n('project-details.area.type.simple'), value: 'simple' },
    { title: i18n('project-details.area.type.headings'), value: 'headings' },
  ];

  return (
    <div className="mb-5 pb-5">
      <h2 className="fs-1">{i18n('project-details.area.header')}</h2>
      <div className="w-25 mb-4">
        <Dropdown items={areaTypes} onChange={onChangeHeaderType} value={headerType} />
      </div>
      <Customization onChange={onChangeHeaderOptions} language={pDocument.content.locale} area={pDocument.content.header} documentId={documentId} />
      <h2 className="fs-1 mt-5">{i18n('project-details.area.footer')}</h2>
      <div className="w-25 mb-4">
        <Dropdown items={areaTypes} onChange={onChangeFooterType} value={footerType} />
      </div>
      <Customization onChange={onChangeFooterOptions} language={pDocument.content.locale} area={pDocument.content.footer} documentId={documentId} />
    </div>
  );
};

const Customization = ({ area, language, ...props }) => {
  const type = area?.type;
  if (type === 'headings') {
    return <HeadingArea options={area.options || defaultHeadingValues} {...props} />;
  } else if (type === 'simple') {
    return <SimpleArea options={area.options || defaultSimpleValues} {...props} />;
  }

  return null;
};

const HeadingArea = ({ documentId, onChange, options }) => {
  const { selectedOrganization } = useContext(UserContext);
  const { data: pDocument } = useGetDocument(documentId, selectedOrganization);
  const [language, setLanguage] = useState(pDocument?.content.locale);
  const [value, setValue] = useState(options);
  const [save] = useDelayedSave(onChange, 1000);

  const { data } = useGetDocumentTableOfContent(pDocument.revisionId, {
    start: 'DOCUMENT_START',
    stop: 'DOCUMENT_END',
    headerHierarchy: ['h1'],
    locale: language,
  });

  function handleChange(header, newOverride) {
    const otherOverrides = value?.[language]?.overrides.filter((override) => override.uuid !== header.uuid) || [];
    const newValue = { ...value, [language]: { overrides: [...otherOverrides, { uuid: header.uuid, text: newOverride, revisionId: header.revisionId }] } };
    setValue(newValue);
    save('headings', newValue);
  }

  function getOverride(value, header) {
    return value?.[language]?.overrides.find((override) => override.uuid === header.uuid)?.text;
  }

  return (
    <>
      <div className="mt-4 w-25">
        <LanguageSelector sm locale={language} onChange={setLanguage} />
      </div>
      <div className="row w-50 mt-3">
        <div className="col-4">
          <h3 className="subheading text-blue-600">{i18n(`toc.hierarchy.headers`)}</h3>
        </div>
        <div className="col-8">
          <h3 className="subheading text-blue-600">{i18n(`toc.hierarchy.override`)}</h3>
        </div>
        {data?.content.map((header) => (
          <React.Fragment key={header.uuid}>
            <div className="col-4 d-flex align-items-center">
              <input
                className="form-check-input ms-0 me-3 mt-0 flex-shrink-0"
                checked={getOverride(value, header) !== null}
                onChange={(e) => handleChange(header, e.target.checked ? '' : null)}
                type="checkbox"
                role="switch"
              />
              <label className={`col-form-label col-form-label-sm ${getOverride(value, header) === null ? 'text-decoration-line-through' : ''}`}>
                {header.name}
              </label>
            </div>
            <div className="col-7">
              <input
                className="form-control form-control-sm"
                disabled={getOverride(value, header) === null}
                type="text"
                value={getOverride(value, header) || ''}
                onChange={(e) => handleChange(header, e.target.value)}
              />
            </div>
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

const SimpleArea = ({ onChange, options }) => {
  const pDocument = useEditorSelector((editor) => editor.pDocument);
  const locale = useEditorSelector((editor) => editor.locale);
  const [save] = useDelayedSave(onChange, 250);
  const [value, setValue] = useState(options);

  const items = Object.entries(pDocument?.content?.metadata?.texts?.[locale] || {})
    .map(([key, value]) => ({ value: key, title: value }))
    .filter((item) => item.title);

  const handleMetaTextChange = (e) => {
    const remove = !e.target.checked;
    const text = e.target.value;
    let newValue;
    if (remove) {
      newValue = { ...value, metadataText: value.metadataText.filter((t) => t !== text) };
    } else {
      newValue = { ...value, metadataText: [...value.metadataText, text] };
    }
    save('simple', newValue);
    setValue(newValue);
  };

  const handlePageNumberChange = (e) => {
    const newValue = { ...value, pageNumber: e.target.checked };
    save('simple', newValue);
    setValue(newValue);
  };

  return (
    <div>
      <div className="mb-4 form-switch row ps-0">
        <label className="col-2  d-flex align-items-center">{i18n('project-details.area.page-number')}</label>
        <div className="col-5">
          <input className="form-check-input ms-0" checked={value?.pageNumber} onChange={handlePageNumberChange} type="checkbox" role="switch" />
        </div>
      </div>
      {items?.length > 0 ? (
        <>
          <h3 className="fs-2">{i18n('project-details.area.text')}</h3>
          <div className="mb-4 row">
            {items.map((item) => (
              <div key={item.value} className="row">
                <div className="col-2">{item.title}</div>
                <div className="col-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={value?.metadataText?.includes(item.value)}
                    value={item.value}
                    onChange={handleMetaTextChange}
                  />
                </div>
              </div>
            ))}
          </div>
        </>
      ) : null}
    </div>
  );
};

const defaultSimpleValues = { pageNumber: false, headers: false, metadataText: [] };
const defaultHeadingValues = { overrides: [] };
