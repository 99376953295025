import { Spinner } from 'app/components';

export function wrapper(title, loaders, content, footer) {
  const isLoading = loaders.some((loader) => loader.isLoading);
  const isSuccess = loaders.every((loader) => loader.isSuccess);
  return (
    <>
      <div className="header d-flex">
        <h3>{title}</h3>
      </div>
      <div className="body overflow-auto">{isLoading || !isSuccess ? <Spinner /> : content()}</div>
      <div className="px-r-1-25 py-r-0-75">{footer()}</div>
    </>
  );
}
