export function parseLocaleSpecificTitle(title, locale) {
  // Creating a case-insensitive regex pattern that also correctly handles different positions of locale codes
  const pattern = new RegExp(`${locale}:(.*?)(?=[A-Z]{2}:|$)`, 'i');
  const matches = title.match(pattern);
  // If a match is found, return the first captured group; otherwise, return the original title
  const result = matches && matches[1] ? matches[1].trim() : title;
  return result;
}

export const toolbarCSSExtractor = (css, locale, type = 'toolbar') => {
  const toolbarClass = type === 'financial' ? '.financial-toolbar' : '.toolbar';
  if (!!css?.match) {
    // Find toolbars that are global, eg. directly below .page-content
    const globalToolbarRegex = new RegExp(`\\.page-content ${toolbarClass}\\s?{.*?(?<=}(\\n|$))`, 'gsm');

    // Find toolbars specific to a template (page structure)
    const templateToolbarRegex = new RegExp(`\\.xrp-template([-_*a-zA-Z0-9]+)?\\s?${toolbarClass}\\s?{.*?(?<=}(\\n|$))`, 'gsm');

    // Find the class wrapping .financial-toolbar (eg. .page-content or .xrp-template-XXX)
    const wrapperClassRegex = new RegExp(`(([-_a-zA-Z*])*)(?=(.*).${toolbarClass} {.*)`);

    // Extract json between "/*! { foo: "bar" } */"
    const jsonExtractorRegex = new RegExp(/(?<=\/\*!)[\s\S]*?(?=\*\/)/, 'm');

    let globalToolbar = css.match(globalToolbarRegex) ?? [];
    let templateToolbars = css.match(templateToolbarRegex)?.filter(onlyUnique) ?? []; // Duplicates can occur when page specific styling exist
    const toolbarCss = [...globalToolbar, ...templateToolbars];
    if (!!toolbarCss) {
      const toolbars = toolbarCss.map((toolbar) => {
        let wrapperClass = toolbar.match(wrapperClassRegex).find((className) => className) ?? '';
        let toolbarDefinition = {};

        try {
          let jsonString = toolbar.match(jsonExtractorRegex)?.[0];
          if (jsonString) {
            toolbarDefinition = JSON.parse(jsonString.replace(/[\n\r]+/g, ' '));
            for (let key in toolbarDefinition) {
              toolbarDefinition[key] = parseLocaleSpecificTitle(toolbarDefinition[key], locale);
            }
          } else {
            throw new Error('JSON string is undefined or null');
          }
        } catch (e) {
          throw new Error(`Error parsing JSON from CSS, please double check the syntax! ${e.message}`);
        }

        return {
          options: Object.entries(toolbarDefinition).map(([selector, title]) => ({
            selector,
            title,
          })),
          wrapperClass,
          templateId: wrapperClass.split('xrp-template-')[1], // undefined if global
        };
      });
      return toolbars;
    }
  }
  return [];
};

function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}
