import { createSlice } from '@reduxjs/toolkit';
import { MAIN_EDITOR_ID } from 'app/state/contexts/EditorContext';

const CLEAN_EDITOR_STATE = {
  pDocument: {},
  section: {},
  references: [],
  languages: [],

  activeNode: {},
  activeSection: {},
  isDirty: false,
  locale: 'sv-SE',
  readOnly: false,
  selectionId: null,
};

const editor = (state, action) => {
  const id = action.editorId || MAIN_EDITOR_ID;
  if (!state.editor[id]) {
    state.editor[id] = { ...CLEAN_EDITOR_STATE };
  }
  return state.editor[id];
};

export const documentSlice = createSlice({
  name: 'document',
  initialState: {
    // Everything that needs to be editor aware when multiple editors are open
    editor: {
      [MAIN_EDITOR_ID]: { ...CLEAN_EDITOR_STATE },
    },
    documentPermissions: [],
    versions: [],
    templates: [],
    sectionPermissions: {},
  },
  reducers: {
    setDocument: (state, action) => {
      editor(state, action).pDocument = action.payload;
    },
    setDocumentPermissions: (state, action) => {
      state.documentPermissions = action.payload;
    },
    setLanguages: (state, action) => {
      /**
       * An array of languages that the current section support
       */
      editor(state, action).languages = action.payload;
    },
    setReferenceData: (state, action) => {
      editor(state, action).references = action.payload;
    },
    setTemplates: (state, action) => {
      state.templates = action.payload;
    },

    setSection: (state, action) => {
      editor(state, action).section = action.payload;
    },
    setSectionPermissions: (state, action) => {
      state.sectionPermissions = action.payload;
    },

    setActiveNode: (state, action) => {
      /**
       * Makes it possible for toolbars to know if selected text is a <h1> or <p>
       */
      editor(state, action).activeNode = action.payload;
    },
    setActiveSection: (state, action) => {
      /**
       * Represents what page is currently selected.
       * TODO: Rename, Not to be confused with Sections, This is a document PAGE!
       */
      editor(state, action).activeSection = action.payload;
    },

    setLocale: (state, action) => {
      /**
       * The current locale, eg. sv-SE
       */
      editor(state, action).locale = action.payload;
    },
    setIsDirty: (state, action) => {
      editor(state, action).isDirty = action.payload;
    },
    setReadOnly: (state, action) => {
      editor(state, action).readOnly = action.payload;
    },
    setSelectionId: (state, action) => {
      /**
       * The UUID of the currently selected part of the document, used to anchor comments to text
       */
      editor(state, action).selectionId = action.payload;
    },
    setVersions: (state, action) => {
      state.versions = Array.isArray(action.payload) ? action.payload : [];
    },
    clearStore: (state) => {
      state.editor = {
        [MAIN_EDITOR_ID]: CLEAN_EDITOR_STATE,
      };
      state.documentPermissions = [];
      state.versions = [];
      state.templates = [];
    },
  },
});

export const {
  clearStore,
  setActiveNode,
  setActiveSection,
  setDocument,
  setDocumentPermissions,
  setIsDirty,
  setLanguages,
  setLocale,
  setReadOnly,
  setReferenceData,
  setSection,
  setSectionPermissions,
  setSelectionId,
  setTemplates,
  setVersions,
} = documentSlice.actions;

export default documentSlice.reducer;
