import { useState, useEffect, useContext, createContext, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router';
import request from 'app/api/request';
import { UserContext } from 'app/state/contexts/UserContext';

export const CommentContext = createContext();

export const CommentContextProvider = ({ children }) => {
  const { sectionId } = useParams();
  const [comments, setComments] = useState([]);
  const { user } = useContext(UserContext);
  const [commentsFocusRequested, setCommentsFocusRequested] = useState(false);

  const fetchComments = useCallback(
    (comments) => {
      request
        .get(`/gaby/comments?sectionSuperId=${sectionId}`)
        .then((res) => res.data)
        .then((savedComments) => {
          setComments(savedComments);
          const draftedComments =
            comments?.filter((c) => {
              return !savedComments.find((sc) => sc.id === c.id);
            }) || [];
          setComments([...savedComments, ...draftedComments]);
        });
    },
    [sectionId]
  );

  const saveComment = (comment) =>
    request.post(`/gaby/comments`, comment).then(() => {
      fetchComments(comments);
    });

  const addComment = ({ onId, onType, citation }) => {
    const newComment = {
      id: uuidv4(),
      userId: user.id,
      onId,
      onType,
      citation,
      message: '',
      sectionSuperId: sectionId,
      createdAt: new Date(),
      updatedAt: new Date(),
      deleted: false,
      draft: true,
    };

    setComments([...comments, newComment]);
    return newComment;
  };

  const deleteComment = (id, onId, shadowHost) => {
    const element = shadowHost.shadowRoot.querySelector(`#anchor-${onId}`);
    element?.parentElement?.classList.remove('layout-leaf-comment');
    setComments(comments.filter((comment) => comment.id !== id));
    request.del(`/gaby/comments/${id}`).then(() => {
      fetchComments();
    });
  };

  const saveDraft = ({ comment, message }) => {
    const newComment = {
      ...comment,
      message,
      draft: false,
    };
    setComments(
      comments.map((c) => {
        if (c.id !== comment.id) {
          return c;
        }

        return newComment;
      })
    );

    saveComment(newComment);
  };

  const saveResponse = ({ comment, message }) => {
    const newComment = addComment({
      onId: comment.id,
      onType: 'comment',
    });

    saveDraft({ comment: newComment, message });
  };

  useEffect(() => {
    fetchComments();
  }, [sectionId, fetchComments]);

  return (
    <CommentContext.Provider value={{ comments, addComment, deleteComment, saveDraft, saveResponse, commentsFocusRequested, setCommentsFocusRequested }}>
      {children}
    </CommentContext.Provider>
  );
};
