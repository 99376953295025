import React, { useContext } from 'react';
import { NavbarPortal } from 'app/components';
import logo from 'app/images/Xplir_logo_RepPil_vektor_rgb.svg';
import { Link } from 'react-router-dom';
import { UserContext } from 'app/state/contexts/UserContext';
import MainMenu from './MainMenu';
import OrganizationMenu from './OrganizationMenu';
const NavbarDefault = () => {
  const { user } = useContext(UserContext);

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <NavbarPortal>
      <div className="d-flex justify-content-between">
        <div className="navbar--logo">
          <Link to="/">
            <img alt="logo" src={logo} />
          </Link>
        </div>
        <div className="d-flex">
          <OrganizationMenu />
          <MainMenu />
          <div className="d-flex align-items-center px-3">
            <i className="fa-regular fa-bell"></i>
          </div>
        </div>
      </div>
    </NavbarPortal>
  );
};

export default NavbarDefault;
