const i18nData = { labels: new Map() };

export function i18n(code) {
  return i18nData.labels.get(code) || code;
}

export function populateI18n(labels) {
  const mappedLabels = labels.map(({ key, text }) => [key, text]);
  i18nData.labels = new Map(mappedLabels);
}
