import React, { useContext } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { UserContext } from 'app/state/contexts/UserContext';
import { UserCircle } from 'app/components';
import { i18n } from 'app/utils/i18n';
import request from 'app/api/request';
import authStore from 'app/utils/authStore';
import { Link } from 'react-router-dom';
import { IfHasPermission } from 'app/components';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button className="d-flex align-items-center fs-3 fw-medium px-4 bg-transparent h-100 border-0 border-start border-end" ref={ref} onClick={onClick}>
    {children}
  </button>
));

const CustomMenu = React.forwardRef(({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
  return (
    <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
      <ul className="list-unstyled mb-0">{children}</ul>
    </div>
  );
});

export default function OrganizationMenu() {
  const { user, organizations } = useContext(UserContext);

  const showAdminSection = authStore.hasPermission('LABELS_CREATE') || authStore.hasPermission('LISTS_CREATE') || organizations.length > 1;

  const handleClickLogout = () => {
    request.post('/auth/logout.do').then(() => {
      window.location.href = '/';
    });
  };

  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle}>
        <UserCircle user={user} />
        <div className="ms-3">
          {user.firstName} {user.lastName}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="border-gray-300 rounded-sm pt-3 min-w-100" as={CustomMenu}>
        {showAdminSection ? (
          <>
            <div className="text-gray-700 fw-semibold fs-4 mx-3">{i18n('menu.admin-header')}</div>
            <Dropdown.Item className="px-3 py-2" as={Link} to="/organizations">
              {i18n('menu.organisations')}
            </Dropdown.Item>
            <IfHasPermission permission="LABELS_CREATE">
              <Dropdown.Item className="px-3 py-2" as={Link} to="/admin/labels">
                {i18n('menu.admin.labels')}
              </Dropdown.Item>
            </IfHasPermission>
            <IfHasPermission permission="LISTS_CREATE">
              <Dropdown.Item className="px-3 py-2" as={Link} to="/admin/lists">
                {i18n('menu.admin.lists')}
              </Dropdown.Item>
            </IfHasPermission>
          </>
        ) : null}

        <div className="text-gray-700 fw-semibold fs-4 mx-3 mt-3">{i18n('menu.profile-header')}</div>
        <Dropdown.Item className="px-3 py-2" as={Link} to="/myprofile">
          <i className="fa-regular fa-user me-3" />
          {i18n('menu.my-profile')}
        </Dropdown.Item>
        <Dropdown.Item className="px-3 py-2" onClick={handleClickLogout}>
          <i className="fa-regular fa-right-from-bracket me-3" />
          {i18n('menu.logout')}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
