import { useCallback, useRef } from 'react';

export const useDelayedSave = (func, delay = 3000) => {
  const saveDelay = useRef();

  const cancel = () => {
    if (saveDelay.current) {
      clearTimeout(saveDelay.current);
    }
  };

  const save = useCallback(
    (...args) => {
      cancel();
      saveDelay.current = setTimeout(func.bind(null, ...args), delay);
    },
    [func, delay]
  );

  return [save, cancel];
};
