import { useState, useContext } from 'react';
import { StructurePreview } from './page-structure/StructurePreview';
import { i18n } from 'app/utils/i18n';
import { useSelectedTemplates, useUpdateSelectedTemplates, useTemplates } from 'app/api/template-queries';
import { Spinner, Modal } from 'app/components';
import { useParams } from 'react-router';
import { UserContext } from 'app/state/contexts';
import { usePermissions } from 'app/utils/hooks/document-permission';

const StructureModal = ({ show, selected, onClose, pageStructures }) => {
  const { documentId } = useParams();

  const [newSelected, setNewSelected] = useState(selected);
  const { mutate: saveSelectedTemplates } = useUpdateSelectedTemplates(documentId);

  const handlePreviewClick = (superId) => {
    if (newSelected.includes(superId)) {
      setNewSelected(newSelected.filter((s) => s !== superId));
    } else {
      setNewSelected([...newSelected, superId]);
    }
  };

  const handleSave = () => {
    saveSelectedTemplates(newSelected, { onSuccess: onClose });
  };

  return (
    <Modal titleLabelKey={i18n('theme.structure.select-title')} show={show} onClose={onClose} size="xl">
      {pageStructures.length === 0 ? (
        i18n('theme.structure.no-available')
      ) : (
        <div className="d-flex flex-wrap justify-content-start gap-r-2">
          {pageStructures?.map?.((page) => (
            <div key={page.superId} className="mb-3">
              <StructurePreview onClick={() => handlePreviewClick(page.superId)} template={page} active={newSelected.includes(page.superId)} />
            </div>
          ))}
        </div>
      )}
      <div className="d-flex justify-content-end mt-5">
        <button type="button" className="btn" onClick={onClose}>
          {i18n('common.button.cancel')}
        </button>
        <button type="button" className="btn btn-primary" onClick={handleSave}>
          {i18n('common.button.save')}
        </button>
      </div>
    </Modal>
  );
};

export const StructureList = () => {
  const { documentId } = useParams();
  const { selectedOrganization } = useContext(UserContext);
  const [showSelectModal, setShowSelectModal] = useState(false);
  const { data: selectedTemplates, isLoading: selectedTemplatesIsLoading } = useSelectedTemplates(documentId);
  const { documentPermissions } = usePermissions(documentId);

  const { data, isLoading } = useTemplates({
    organization: selectedOrganization,
    type: 'template',
    documentSuperId: documentId,
  });

  if (isLoading || selectedTemplatesIsLoading) {
    return <Spinner />;
  }

  const handleClickAddStructure = () => {
    setShowSelectModal(true);
  };
  const handleCloseSelectModal = () => {
    setShowSelectModal(false);
  };

  return (
    <>
      <div className="d-flex flex-row justify-content-between align-items-center mb-3">
        <h2 className="m-0">{i18n('theme.structure')}</h2>
        {documentPermissions.includes('DESIGN') ? (
          <button
            type="button"
            onClick={handleClickAddStructure}
            className="btn-clear rounded-circle aspect-1x1 bg-green-700 text-white d-flex justify-content-center align-items-center "
            style={{ height: '43px', fontSize: '15px' }}
          >
            <i className="fa-regular fa-ellipsis-vertical fa-center-fix"></i>
          </button>
        ) : null}
      </div>
      {data.length === 0 ? (
        i18n('theme.structure.no-selected')
      ) : (
        <div className="d-flex flex-wrap justify-content-start gap-r-2">
          {data
            .filter((page) => selectedTemplates.includes(page.superId))
            .map?.((page) => (
              <div key={page.superId} className="mb-3">
                <StructurePreview template={page} />
              </div>
            ))}
        </div>
      )}
      <StructureModal pageStructures={data} selected={selectedTemplates} show={showSelectModal} onClose={handleCloseSelectModal} />
    </>
  );
};
