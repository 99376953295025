import { useContext } from 'react';
import { Editor, Node } from 'slate';
import { getNodeId, leafsInRange } from '../compareUtils';
import { DiffContext } from 'app/state/contexts/DiffContext';
import DiffedElement from 'app/slate/components/DiffedElement';
import DiffedLeaf from 'app/slate/components/DiffedLeaf';

const compare =
  ({ side, readOnly }) =>
  (editor) => {
    const { renderElement, renderLeaf, decorate } = editor;

    editor.decorate = ([editor, editorPath]) => {
      const originalDecorations = decorate([editor, editorPath]);
      if (!Editor.isEditor(editor)) {
        return originalDecorations;
      }

      const { diffState } = useContext(DiffContext); // eslint-disable-line react-hooks/rules-of-hooks
      const diffs = diffState?.[side];

      if (!diffs) {
        return originalDecorations;
      }

      const decorations = [];
      const nodes = Array.from(Node.descendants(editor));

      for (const [node, nodePath] of nodes) {
        if (!diffs[getNodeId(node)]) {
          continue;
        }

        const changes = diffs[getNodeId(node)];

        if (changes.state !== 'changed') {
          continue;
        }

        switch (changes.type) {
          case 'table':
            changes.computedDiff
              ?.filter((change) => change[0] === 0 || change[0] === -1)
              .reduce(
                (acc, change) => {
                  if (change[0] === -1) {
                    const range = Editor.range(node);
                    acc[1].push({ ...range });
                  }

                  acc[0] += change[1].length;
                  return acc;
                },
                [0, []]
              )[1]
              .forEach((decoration) => decorations.push(decoration));
            break;
          default:
            changes.computedDiff
              ?.filter((change) => change[0] === 0 || change[0] === -1)
              .reduce(
                (acc, change) => {
                  if (change[0] === -1) {
                    const lir = leafsInRange(nodePath, node, acc[0], acc[0] + change[1].length, side, change);
                    acc[1].push(lir);
                  }

                  acc[0] += change[1].length;
                  return acc;
                },
                [0, []]
              )[1]
              .forEach((decoration) => decorations.push(decoration));
        }
      }

      return [...originalDecorations, ...decorations];
    };

    editor.renderElement = (props) => {
      return <DiffedElement {...props} readOnly={readOnly} side={side} renderElement={renderElement} />;
    };

    editor.renderLeaf = (props) => {
      return <DiffedLeaf {...props} side={side} renderLeaf={renderLeaf} />;
    };

    return editor;
  };

export default compare;
