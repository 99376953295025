import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from 'app/utils/i18n';

class Pagination extends React.Component {
  constructor(props) {
    super(props);

    this.onPaginate = this.onPaginate.bind(this);
  }

  onPaginate(page, e) {
    if (e) {
      e.preventDefault();
    }
    this.props.onPaginate(page);
  }

  showPageSizePicker() {
    return this.props.updatePageSize && typeof this.props.updatePageSize === 'function' && this.props.pageSize;
  }

  renderPaginatorButton(pageNo) {
    const active = pageNo === this.props.page.number ? 'active page-item' : 'page-item';

    return (
      <li key={'p-' + pageNo} className={active}>
        <a href="/#" className="page-link" onClick={this.onPaginate.bind(this, pageNo)}>
          {pageNo + 1}
        </a>
      </li>
    );
  }

  renderPrevButton() {
    const onClick = this.onPaginate.bind(this, this.props.page.number - 1);
    if (this.props.page.number > 0) {
      return (
        <li key="prev" className="page-item">
          <a href="/#" className="page-link pagination-arrow" onClick={onClick}>
            <i className="fa fa-angle-left" />
          </a>
        </li>
      );
    } else {
      return (
        <li key="prev" className="disabled page-item">
          <a href="/#" className="page-link pagination-arrow">
            <i className="fa fa-angle-left" />
          </a>
        </li>
      );
    }
  }

  renderNextButton() {
    const onClick = this.onPaginate.bind(this, this.props.page.number + 1);
    if (this.props.page.number + 1 < this.props.page.totalPages) {
      return (
        <li className="page-item" key="next">
          <a href="/#" className="page-link pagination-arrow" onClick={onClick}>
            <i className="fa fa-angle-right" />
          </a>
        </li>
      );
    } else {
      return (
        <li key="next" className="disabled page-item">
          <a href="/#" className="page-link pagination-arrow">
            <i className="fa fa-angle-right" />
          </a>
        </li>
      );
    }
  }

  renderPagination() {
    // No paginator at all if only 1 (or 0) pages unless we show page size picker
    if (this.props.page.totalPages < 2 && !this.showPageSizePicker()) {
      return null;
    }

    // Show ellipsis if current number is higher than ellipsisItems + 1
    const showStartEllipsis = this.props.page.totalPages > this.props.maxItems && this.props.page.number > this.props.ellipseItems + 1;
    // Show ellipsis if current number is lower than totalPages - ellipsisItems - 2
    const showFinishEllipsis =
      this.props.page.totalPages > this.props.maxItems && this.props.page.number < this.props.page.totalPages - this.props.ellipseItems - 2;
    // Start page is 0 if current number is lower than ellipsisItems + 1
    const startPage =
      this.props.page.totalPages <= this.props.maxItems || this.props.page.number <= this.props.ellipseItems + 1
        ? 0
        : this.props.page.number - this.props.ellipseItems;
    // If we show finishEllipsis, finish page is current number + ellipsisItems + 1, otherwise totalPage
    const finishPage = showFinishEllipsis ? this.props.page.number + this.props.ellipseItems + 1 : this.props.page.totalPages;

    // construct the button array now
    const buttons = [];
    if (showStartEllipsis) {
      buttons.push(this.renderPaginatorButton(0));
      buttons.push(
        <li className="disabled page-item" key="start_ellipsis">
          <a href="/" className="page-link">
            ...
          </a>
        </li>
      );
    }

    for (var i = startPage; i < finishPage; i++) {
      buttons.push(this.renderPaginatorButton(i));
    }

    if (showFinishEllipsis) {
      buttons.push(
        <li className="disabled page-item" key="end-ellipsis">
          <a href="/" className="page-link">
            ...
          </a>
        </li>
      );
      buttons.push(this.renderPaginatorButton(this.props.page.totalPages - 1));
    }

    return (
      <div>
        {this.props.showTotalElements ? (
          <p className="pagination-total">
            {i18n('table.pagination.totalElements')} {this.props.page.totalElements}
          </p>
        ) : null}
        <ul className="pagination">
          <li key="first" className={this.props.page.number === 0 ? 'disabled page-item' : 'page-item'}>
            <a href="/" className="page-link pagination-arrow" onClick={this.onPaginate.bind(this, 0)}>
              <i className="fa fa-angle-double-left" />
            </a>
          </li>
          {this.renderPrevButton()}
          {buttons}
          {this.renderNextButton()}
          <li key="last" className={this.props.page.number + 1 === this.props.page.totalPages ? 'disabled page-item' : 'page-item'}>
            <a href="/" className="page-link pagination-arrow" onClick={this.onPaginate.bind(this, this.props.page.totalPages - 1)}>
              <i className="fa fa-angle-double-right" />
            </a>
          </li>
        </ul>
      </div>
    );
  }

  render() {
    return <div className="pagination-wrapper">{this.renderPagination()}</div>;
  }
}

Pagination.defaultProps = {
  showTotalElements: false,
  page: { totalPages: -1, totalElements: 10, number: 0, size: 10 },
  maxItems: 10, // Max items shown before using ellipsis
  ellipseItems: 3, // Number of pages shown before and after current page if we show ellipsis
};

Pagination.propTypes = {
  showTotalElements: PropTypes.bool,
  page: PropTypes.object,
  maxItems: PropTypes.number,
  ellipseItems: PropTypes.number,
};

export default Pagination;
