import React, { useCallback, useEffect, useRef, useState } from 'react';
import request from 'app/api/request';
import { OldModal } from 'app/components';
import { useParams } from 'react-router-dom';
import { ToolMenu } from 'app/components';
import Tippy from '@tippyjs/react';
import ServerErrorHandler from 'app/ErrorHandler';
import { LazyTippy } from 'app/components';
import { i18n } from 'app/utils/i18n';

const NewUserForm = ({ permissionGroup, documentId, handleUserAdded }) => {
  const [userName, setUserName] = useState('');
  const inputRef = useRef('');

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const handleAddUser = (event) => {
    event.preventDefault();
    if (!userName) {
      return null;
    }

    request
      .get(`/registry/users?username=${userName}`)
      .then((response) => {
        request
          .post(`/gaby/documents/${documentId}/permissions/${permissionGroup.permission}/users`, {
            userId: response.data._embedded.users.find((user) => user.userName === userName).id,
          })
          .then(() => {
            handleUserAdded();
            setUserName('');
          })
          .catch(ServerErrorHandler);
      })
      .catch(ServerErrorHandler);
  };

  return (
    <div className="add-new-popup wider-popup popup-with-button">
      <div className="comment--response">
        <form onSubmit={handleAddUser}>
          <input
            className="input-variant wider-input"
            type="text"
            name="username"
            value={userName}
            ref={inputRef}
            placeholder={i18n('document-permissions.field.placeholder.new-user')}
            onChange={(event) => setUserName(event.target.value)}
          />
          <button type="submit" className="button btn btn-secondary">
            {i18n('document-permissions.button.new-user')} {userName}
          </button>
        </form>
      </div>
    </div>
  );
};

const DocumentPermissions = () => {
  const { documentId } = useParams();
  const [users, setUsers] = useState([]);
  const [permissions, setPermissions] = useState({ permissionGroups: [] });

  const [userToRemove, setUserToRemove] = useState(null);
  const menuItemsEditDocumentGroupUser = (permission, user) => [
    {
      text: i18n('document-permissions.list.menu-item.remove-user'),
      icon: 'fa-regular fa-trash-can',
      onClick: () => {
        setUserToRemove({ userId: user.id, userName: user.userName, permission: permission });
        removeUserModal.current.open();
      },
    },
  ];

  //TODO Maybe fetch this list from backend, gaby-documents or client-config
  const AVAILABLE_DOCUMENT_PERMISSIONS = [
    { title: i18n('document-permissions.permission.project-manager'), permission: 'ADMIN' },
    { title: i18n('document-permissions.permission.design'), permission: 'DESIGN' },
    { title: i18n('document-permissions.permission.coder'), permission: 'CODER' },
    { title: i18n('document-permissions.permission.reference-data'), permission: 'DB' },
  ];

  const removeUserModal = useRef(null);

  const removeUserPermission = (event) => {
    event.preventDefault();
    console.log('remove ', userToRemove);
    request.del(`/gaby/documents/${documentId}/permissions/${userToRemove.permission}/users/${userToRemove.userId}`, {}).then(() => {
      fetchData();
    });
    removeUserModal.current.close();
  };

  const fetchData = useCallback(() => {
    request.get(`/gaby/documents/${documentId}/permissions`).then((res) => {
      setPermissions(res.data);
    });
    //TODO get username for user in backend with intra service call instead
    request.get(`/registry/users?size=1000`).then((res) => {
      setUsers(res.data._embedded?.users);
    });
  }, [documentId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      {AVAILABLE_DOCUMENT_PERMISSIONS.map((permission) => (
        <div key={permission.title}>
          <div className="permission-section-header columns col-1fr-auto">
            <h2 className="permission-title">{permission.title}</h2>
            <LazyTippy
              interactive={true}
              trigger="click"
              offset={[20, 10]}
              placement="bottom"
              content={<NewUserForm handleUserAdded={fetchData} permissionGroup={permission} users={users} documentId={documentId} />}
            >
              <button className="fab" type="button">
                <i className="fa-regular fa-plus font-15" />
              </button>
            </LazyTippy>
          </div>
          <div>
            <table className="permissions-table">
              <tbody>
                {!permissions.userDocumentPermissions
                  ? null
                  : permissions.userDocumentPermissions
                      .filter((userDocumentPermission) => userDocumentPermission.permission === permission.permission)
                      .map((member) => {
                        const user = users.find((user) => user.id === member.userId);
                        if (!user) {
                          return (
                            <tr key={permission.permission + member.userId}>
                              <td>{i18n('common.loading-message')}</td>
                            </tr>
                          );
                        }

                        return (
                          <tr key={user.id}>
                            <td className="columns col-1fr-auto">
                              {user.firstName} {user.lastName}
                              <Tippy
                                interactive={true}
                                offset={[90, -80]}
                                trigger="click"
                                content={<ToolMenu menuItems={menuItemsEditDocumentGroupUser(permission.permission, user)} />}
                              >
                                <i className="fa-regular fa-ellipsis-v more toggle-icon edit-permission-group" />
                              </Tippy>
                            </td>
                          </tr>
                        );
                      })}
              </tbody>
            </table>
          </div>
        </div>
      ))}

      <OldModal ref={removeUserModal} modalWrapperClass="delete-modal">
        <i
          className="fa-regular fa-xmark text-right close"
          onClick={() => {
            removeUserModal.current.close();
          }}
        />
        <form className="form" onSubmit={removeUserPermission}>
          <div>
            <i className="fa-regular fa-trash-can modal-icon" />
            <h3 className="modal-body-title">{i18n('document-permissions.remove-user.modal.title')}</h3>
            <p className="modal-body-text">
              {i18n('document-permissions.remove-user.modal.text')} {userToRemove?.userName} ?
            </p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="button clear"
              onClick={() => {
                removeUserModal.current.close();
              }}
            >
              {i18n('common.button.cancel')}
            </button>
            <button type="submit" className="button primary">
              {i18n('document-permissions.remove-user.modal.button.remove')}
            </button>
          </div>
        </form>
      </OldModal>
    </div>
  );
};

export default DocumentPermissions;
