import React from 'react';
import UserProperties from './UserProperties';
import ChangePassword from './ChangePassword';

const Overview = () => {
  return (
    <div className="row mt-r-2-5">
      <div className="col-md-6">
        <UserProperties />
      </div>
      <div className="col-md-6">
        <ChangePassword />
      </div>
    </div>
  );
};

export default Overview;
