import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useSlate } from 'slate-react';
import { NavbarPortal } from 'app/components';
import logo from 'app/images/Xplir_logo_icon.svg';
import { UserContext } from 'app/state/contexts/UserContext';

export const NavbarTemplateEditor = ({ document, template }) => {
  const editor = useSlate();
  const { organizations, selectedOrganization } = useContext(UserContext);
  const org = organizations.find((o) => o.id === selectedOrganization);

  return (
    <NavbarPortal fullWidth>
      <div className="navbar--editor">
        <div className="logo">
          <img alt="logo" src={logo} />
        </div>
        <Link className="navigation" to={`/documents/${document.superId}/settings`}>
          <button className="button--small clear">
            <i className="fa-regular fa-chevron-left" />
          </button>
        </Link>
        <div className="project">
          <Link className="me-3" to={`/documents/${document.superId}`}>
            <p className="sub-heading mb-1">{`${org?.name || ''} > ${document.name}`} </p>
            <div className="d-flex align-items-center">
              <h4>{template.name} </h4>
              <span className="ms-3 text-gray-900 fs-7"></span>
            </div>
          </Link>
        </div>
        <div className="controls">
          <button
            className="button--small clear"
            onClick={() => {
              editor.undo();
            }}
          >
            <i className="fa-regular fa-arrow-rotate-left" />
          </button>
          <button
            className="button--small clear"
            onClick={() => {
              editor.redo();
            }}
          >
            <i className="fa-regular fa-arrow-rotate-right" />
          </button>
          <div className="navbar-user-icon-wrapper border--left">
            <div className="user--circle">NA</div>
          </div>
          <div className="navbar-notification-icon-wrapper">
            <i className="fa-regular fa-bell"></i>
          </div>
        </div>
      </div>
    </NavbarPortal>
  );
};
