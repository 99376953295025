import React from 'react';
import PropTypes from 'prop-types';

const InfoBlock = ({ caption = '', text = '', className = '', children = null }) => {
  return (
    <div className={`py-3 ${className}`}>
      <p className="text-uppercase fs-7 text-gray-900 mb-1">{caption}</p>
      {children || <p className="fs-3">{text}</p>}
    </div>
  );
};

InfoBlock.propTypes = {
  caption: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
};

export default InfoBlock;
