import { useState } from 'react';

const moveItem = (items, from, to) => {
  const toIdx = items.findIndex((s) => s === to);
  const reorderedItems = items.filter((s) => s !== from);
  reorderedItems.splice(toIdx, 0, from);
  return reorderedItems;
};

export const useDraggableList = (list, onDrop) => {
  const [reorderedList, setReorderedList] = useState(null);
  const [draggedItem, setDraggedItem] = useState(null);

  return {
    reorderedList,
    draggedItem,
    draggableAttributes: (section) => ({
      draggable: true,
      onDragStart: (event) => {
        event.dataTransfer.dropEffect = 'move';
        setReorderedList(list);
        setDraggedItem(section);
      },
      onDragEnter: (event) => {
        event.preventDefault();
        if (section === draggedItem) {
          return;
        }

        setReorderedList(moveItem(reorderedList, draggedItem, section));
      },
      onDragOver: (event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
      },
      onDragLeave: () => {},
      onDragEnd: () => {
        setReorderedList(null);
        setDraggedItem(null);
      },
      onDrop: (event) => {
        event.preventDefault();
        onDrop(reorderedList);
      },
    }),
  };
};
