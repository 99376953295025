import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { SupportModal, NavbarDefault } from 'app/components';
import Labels from './Labels';
import LabelEdit from './LabelEdit';
import { useRouteMatch } from 'react-router-dom';

const LabelRouter = () => {
  let match = useRouteMatch();

  return (
    <div>
      <div className="grid--container full--height columns">
        <div className="content">
          <Switch>
            <Route path={`${match.url}/new`}>
              <LabelEdit isNew />
            </Route>
            <Route path={`${match.url}/:id`}>
              <LabelEdit />
            </Route>
            <Route path="" exact>
              <Labels />
            </Route>
          </Switch>
        </div>
      </div>
      <NavbarDefault />
      <SupportModal />
    </div>
  );
};

export default LabelRouter;
