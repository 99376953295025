import React, { useState } from 'react';
import { i18n } from 'app/utils/i18n';
import { Modal } from 'app/components';
import { useImportLabels } from 'app/api/foundation/labels-queries';

function LabelImportButton() {
  const [modalOpen, setModalOpen] = useState(false);
  const [mode, setMode] = useState('');
  const [content, setContent] = useState(null);
  const [busy, setBusy] = useState(false);

  const { mutate: importLabels } = useImportLabels();

  const modes = [
    { code: 'NEW_LABELS', value: i18n('labels.mode.new') },
    { code: 'NEW_AND_DELETE_LABELS', value: i18n('labels.mode.delete') },
    { code: 'NEW_AND_UPDATE_LABELS', value: i18n('labels.mode.update') },
  ];

  function toggleModal() {
    setModalOpen(!modalOpen);
  }

  function handleFileChange(e) {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = function (e) {
      setContent(e.target.result);
    };

    reader.readAsText(file);
  }

  function submitForm(e) {
    e.preventDefault();

    if (mode) {
      setBusy(true);

      try {
        importLabels(
          { content: JSON.parse(content), mode },
          {
            onSuccess: () => {
              setBusy(false);
              toggleModal();
            },
          }
        );
      } catch (e) {
        console.error(e);
      }
    }
  }

  return (
    <>
      <button className="btn btn-secondary me-2" onClick={toggleModal}>
        <i className="fa fa-upload" /> {i18n('label.import.show.modal.button')}
      </button>
      <Modal show={modalOpen}>
        <div className="header d-flex">
          <h3>{i18n('label.import.modal.header')}</h3>
          <div className="close" onClick={toggleModal}>
            <i className="fa-regular fa-xmark" />
          </div>
        </div>
        <div className="body">
          <form onSubmit={submitForm}>
            <div>
              <label className={'col-form-label'} htmlFor="mode">
                {i18n('label.import.mode')}
              </label>
            </div>
            <select value={mode} onChange={(e) => setMode(e.target.value)} id="mode" className="mb-3">
              <option value="">--Please choose an option--</option>
              {modes.map((o) => (
                <option key={o.code} value={o.code}>
                  {o.value}
                </option>
              ))}
            </select>

            <div>
              <label className={'col-form-label'}>{i18n('label.import.file')}</label>
            </div>
            <input type="file" onChange={handleFileChange} accept=".json" />
            <div className="d-flex justify-content-end">
              <button type="button" className="button clear" onClick={toggleModal}>
                {i18n('common.button.cancel')}
              </button>
              <button type="submit" className="button primary" disabled={busy}>
                {i18n('common.button.save')}
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default LabelImportButton;
