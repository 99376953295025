import React, { useCallback, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';

export const EditorContext = React.createContext(null);

export const MAIN_EDITOR_ID = 'main-editor';
export const MultiEditorContext = React.createContext(MAIN_EDITOR_ID);

export const useEditorSelector = (selector, editorId) => {
  const contextEditorId = useContext(MultiEditorContext);
  return useSelector((state) => {
    const editor = state.document.editor[editorId ?? contextEditorId];
    return selector(editor);
  });
};
export const useEditorDispatch = () => {
  const dispatch = useDispatch();
  const editorId = useContext(MultiEditorContext);
  return useCallback(
    (action) => {
      action.editorId = editorId;
      return dispatch(action);
    },
    [editorId, dispatch]
  );
};
