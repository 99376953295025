import React, { useContext, useEffect } from 'react';
import { Link, NavLink, Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import ReferenceData from 'app/views/documents/reference-data/ReferenceData';
import Pictures from './pictures/Pictures';
import Locale from './locale/Locale';
import Overview from './Overview';
import { NavbarDefault, NavPills } from 'app/components';
import SectionPermissions from './permissions/SectionPermissions';
import DocumentPermissions from './permissions/DocumentPermissions';
import Metadata from './metadata/Metadata';
import { DesignSettings } from './settings/DesignSettings';
import { UserContext } from 'app/state/contexts/UserContext';
import { usePermissions } from 'app/utils/hooks/document-permission';
import { i18n } from 'app/utils/i18n';
import { SupportModal } from 'app/components';
import { setDocument } from 'app/state/redux/documentSlice';
import { useGetDocument } from 'app/api/documents/document-queries';
import { useEditorDispatch } from 'app/state/contexts/EditorContext';

const Project = () => {
  const { documentId } = useParams();
  let { path, url } = useRouteMatch();
  const { selectedOrganization, organizations } = useContext(UserContext);
  const { documentPermissions } = usePermissions(documentId);
  const items = [
    { text: i18n('project-details.users.nav-link.section-permissions'), url: `${url}/permissions/sections` },
    { text: i18n('project-details.users.nav-link.document-permissions'), url: `${url}/permissions/documents` },
  ];
  const companyName = organizations.find((organization) => selectedOrganization === organization.id).name;
  const editorDispatch = useEditorDispatch();
  const { data: document } = useGetDocument(documentId, selectedOrganization);

  useEffect(() => {
    if (document) {
      editorDispatch(setDocument(document));
    }
    // eslint-disable-next-line
  }, [document]);

  if (!document) {
    return <div>loading...</div>;
  }

  return (
    <div className="project--details grid--container full--height columns">
      <div className="content overflow-hidden">
        <div className="project--details-header">
          <div className="content-title">
            <div className="company-header">
              <Link to="/">{companyName}</Link>
            </div>
            <h1>{document.name}</h1>
          </div>
        </div>
        <div className="menu--layout">
          <NavLink to={url} exact>
            {i18n('project-details.nav-link.project-overview')}
          </NavLink>
          <NavLink to={`${url}/metadata`}>{i18n('project-details.nav-link.metadata')}</NavLink>
          {documentPermissions.includes('ADMIN') && <NavLink to={`${url}/permissions`}>{i18n('project-details.nav-link.users')}</NavLink>}
          {documentPermissions.includes('DESIGN') && <NavLink to={`${url}/pictures`}>{i18n('project-details.nav-link.pictures')}</NavLink>}
          {documentPermissions.includes('DB') && <NavLink to={`${url}/reference-data`}>{i18n('project-details.nav-link.reference-data')}</NavLink>}
          {documentPermissions.includes('DB') && <NavLink to={`${url}/language`}>{i18n('project-details.nav-link.locale')}</NavLink>}
          {(documentPermissions.includes('DESIGN') || documentPermissions.includes('CODER')) && (
            <NavLink to={`${url}/design`}>{i18n('project-details.nav-link.layout')}</NavLink>
          )}
        </div>
        <div className="project-details-wrapper">
          <Switch>
            <Route path={path} exact>
              <Overview />
            </Route>
            <Route path={`${path}/metadata`} component={Metadata} />
            <Route path={`${path}/permissions`}>
              <NavPills items={items} className="d-inline-flex mt-5" />
              <Switch>
                <Redirect from={`${url}/permissions`} to={`${url}/permissions/sections`} exact />
                <Route path={`${path}/permissions/documents`} component={DocumentPermissions} />
                <Route path={`${path}/permissions/sections`} component={SectionPermissions} />
              </Switch>
            </Route>
            <Route path={`${path}/pictures`} component={Pictures} />
            <Route path={`${path}/reference-data`} component={ReferenceData} />
            <Route path={`${path}/language`} component={Locale} />
            <Route path={`${path}/design`} component={DesignSettings} />
          </Switch>
        </div>
      </div>
      <NavbarDefault />
      <SupportModal />
    </div>
  );
};

export default Project;
