import { NavbarDefault } from 'app/components';
import { useContext } from 'react';
import { NavLink, Route, Switch, useRouteMatch } from 'react-router-dom';

import { IfHasPermission, SupportModal } from 'app/components';
import { UserContext } from 'app/state/contexts/UserContext';
import { i18n } from 'app/utils/i18n';
import Projects from './Projects';
import Users from './users/Users';

const Dashboard = () => {
  const { selectedOrganization, organizations } = useContext(UserContext);

  let { path } = useRouteMatch();

  if (!selectedOrganization) {
    return (
      <div>
        <div>{i18n('common.no-organization-message')}</div>
        <NavbarDefault />
      </div>
    );
  }

  return (
    <div className="project--details grid--container full--height columns">
      <div className="content">
        <div className="content-title">
          <h1>{organizations.find((o) => o.id === selectedOrganization)?.name}</h1>
        </div>
        <div className="menu--layout">
          <NavLink to="" exact>
            {i18n('dashboard.nav-link.overview')}
          </NavLink>
          <IfHasPermission permission="ORGANIZATION_CREATE">
            <NavLink to={`users`}>{i18n('dashboard.nav-link.users')}</NavLink>
          </IfHasPermission>
        </div>
        <Switch>
          <Route path={path} exact component={Projects} />
          <Route path={`${path}users`} component={Users} />
        </Switch>
        <NavbarDefault />
        <SupportModal />
      </div>
    </div>
  );
};

export default Dashboard;
