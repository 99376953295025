import { Editor as SlateEditor, Transforms, Element } from 'slate';
import { defaultParagraph } from './paragraph';
import { isPage } from './page';
import { hasText } from 'app/utils/helper-functions';
import { Layout } from 'app/slate/components/Layout';
export const isLayout = (node) => Element.isElement(node) && node.type === 'layout';

export const emptyLayout = (opts) => {
  const horizontal = opts?.horizontal ?? false;
  const withParagraph = opts?.withParagraph ?? true;

  return {
    type: 'layout',
    className: `layout ${horizontal ? 'horizontal' : 'vertical'}`,
    children: withParagraph ? [defaultParagraph()] : [],
  };
};

const layout =
  (options = {}) =>
  (editor) => {
    const markEmpty = options.markEmpty ?? false;
    const { normalizeNode, renderElement, deleteBackward } = editor;

    const normalizePage = (entry) => {
      const [node, path] = entry;
      if (node.children[0]?.type === 'layout') {
        normalizeNode(entry);
        return;
      }

      if (node.children.length) {
        Transforms.wrapNodes(editor, { type: 'layout', children: [] }, { at: [...path, 0] });
      } else {
        Transforms.insertNodes(editor, emptyLayout(), { at: [...path, 0] });
      }
    };

    const normalizeLayout = (entry) => {
      const [node, path] = entry;
      // We're good if the layout already has children
      const hasChildElements = !!node.children?.find((node) => Element.isElement(node));
      const isLayoutLeaf = !node.children?.find((node) => isLayout(node));
      const isEmptyLayoutLeaf = isLayoutLeaf && !hasText(node);

      if (isEmptyLayoutLeaf !== node.isEmptyLayoutLeaf) {
        Transforms.setNodes(editor, { isEmptyLayoutLeaf }, { at: path });
        return;
      }

      if (isLayoutLeaf !== node.isLayoutLeaf) {
        Transforms.setNodes(editor, { isLayoutLeaf }, { at: path });
        return;
      }

      if (hasChildElements) {
        normalizeNode(entry);
        return;
      }

      Transforms.insertNodes(editor, defaultParagraph(), {
        at: [...path, 0],
      });
    };

    editor.normalizeNode = (entry) => {
      const [node] = entry;
      if (isLayout(node)) {
        normalizeLayout(entry);
      } else if (isPage(node)) {
        normalizePage(entry);
      } else {
        normalizeNode(entry);
      }
    };

    editor.renderElement = (props) => {
      if (isLayout(props.element)) {
        return <Layout {...props} markEmpty={markEmpty} />;
      } else {
        return renderElement(props);
      }
    };

    editor.deleteBackward = (unit) => {
      if (!editor.selection) {
        deleteBackward(unit);
        return;
      }

      // Default behaviour if in middle of text
      if (editor.selection.focus.offset !== 0) {
        deleteBackward(unit);
        return;
      }

      const before = SlateEditor.before(editor, editor.selection.anchor.path);
      if (!before) {
        deleteBackward(unit);
        return;
      }
      const layoutBefore = Array.from(
        SlateEditor.nodes(editor, {
          at: before,
          match: isLayout,
          mode: 'lowest',
        })
      )[0][0];

      const layoutCurrent = Array.from(
        SlateEditor.nodes(editor, {
          match: isLayout,
          mode: 'lowest',
        })
      )[0][0];

      // Prevent text to be moved between layout boxes with backspace
      if (layoutBefore !== layoutCurrent) {
        return;
      }

      deleteBackward(unit);
    };

    return editor;
  };

export default layout;
