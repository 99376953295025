import { DraftController } from './DraftController';
import { StyleSelection } from './StyleSelection';
import { StructureList } from './StructureList';
import { GraphicalVariables } from './GraphicalVariables';

export function ThemeConfiguration() {
  return (
    <>
      <DraftController />
      <GraphicalVariables />
      <hr className="my-5 border-gray-300" />
      <StyleSelection />
      <hr className="my-5 border-gray-300" />
      <StructureList />
    </>
  );
}
