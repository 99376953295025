import { EventType, dispatchMessage } from 'app/useToasts';
import { i18n } from './utils/i18n';

const ServerErrorHandler = (e) => {
  // const request = e.request;
  console.log(e);

  dispatchMessage(EventType.ADD_MESSAGE, {
    type: 'error',
    title: i18n(`server.error.${e.status.toString()}.title`),
    text: i18n(`server.error.${e.status.toString()}.message`),
  });
};
export default ServerErrorHandler;
