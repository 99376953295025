import React, { useState } from 'react';
import { useSlate } from 'slate-react';
import ReactDataSheet from 'libs/react-datasheet';
import { insertMention } from 'app/slate/extensions/mentions';
import { Spinner } from 'app/components';
import { i18n } from 'app/utils/i18n';
import { useEditorSelector } from 'app/state/contexts/EditorContext';

export const ImportReference = ({ onClose }) => {
  const [state, setState] = useState('sheet');
  const [selection, setSelection] = useState();
  const [sheet, setSheet] = useState();
  const states = ['sheet', 'cell'];
  const editor = useSlate();

  const references = useEditorSelector((editor) => editor.references);

  const handleReferenceCellSelection = (reference) => {
    setSheet(reference);
    setState(states[1]);
  };

  const handleCellSelection = (reference, selection) => {
    insertMention(editor, reference, selection);
    onClose();
    setState(states[0]);
    setSelection(null);
  };

  const valueRenderer = (cell) => {
    return cell.value;
  };

  const switcher = (page) => {
    switch (page) {
      case states[0]:
        return (
          <table>
            <tbody>
              {!references.length ? (
                <Spinner />
              ) : (
                references.map((data) => (
                  <tr key={data.revisionId}>
                    <td>{data.name}</td>
                    {!data.selectionContent ? (
                      <h3>{i18n('table-import.no-data')}</h3>
                    ) : (
                      <td className="align-right">
                        <button className="btn btn-primary" onClick={() => handleReferenceCellSelection(data)}>
                          {i18n('table-import.select')}
                        </button>
                      </td>
                    )}
                  </tr>
                ))
              )}
            </tbody>
          </table>
        );
      case states[1]:
        return (
          <div className="select--cell">
            <ReactDataSheet
              data={sheet.selectionContent}
              valueRenderer={valueRenderer}
              overflow="clip"
              onSelect={(selection) => {
                setSelection(selection);
              }}
            />
            <div>
              {!selection ? null : (
                <div>
                  <h3>
                    Infoga värdet {`[${selection.start.i}, ${selection.start.j}]`}: {sheet.selectionContent[selection.start.i][selection.start.j].value}
                  </h3>
                  <button className="button clear" onClick={onClose}>
                    {i18n('table-import.cancel')}
                  </button>
                  <button className="button primary" onClick={() => handleCellSelection(sheet, selection)}>
                    {i18n('table-import.approve')}
                  </button>
                </div>
              )}
            </div>
          </div>
        );
      default:
        return <div className="box error">Unknown state</div>;
    }
  };

  return (
    <div>
      <div className="header d-flex">
        <h3>{i18n('table-import.include-reference')}</h3>
      </div>
      <div className="body">{switcher(state)}</div>
    </div>
  );
};
