import { forwardRef, useState } from 'react';
import Tippy from '@tippyjs/react';
import { ToolMenu } from 'app/components';

const TippyMenuButton = forwardRef(({ onClick }, ref) => {
  return (
    <button className="tippy-menu-button" ref={ref} onClick={onClick}>
      <i className="fa-regular fa-ellipsis-v" />
    </button>
  );
});

const TippyMenu = ({ offset = [-60, -100], menu = [], trigger = 'click' }) => {
  const [visible, setVisible] = useState(false);

  const handleButtonClick = (e) => {
    setVisible(true);
    e.preventDefault();
    e.stopPropagation();
  };

  const handleMenuClick = () => {
    setVisible(false);
  };

  return (
    <Tippy interactive offset={offset} trigger={trigger} content={visible ? <ToolMenu menuItems={menu} onClick={handleMenuClick} /> : null}>
      <TippyMenuButton onClick={handleButtonClick} />
    </Tippy>
  );
};

export default TippyMenu;
