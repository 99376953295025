import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './app/routes';
import request from 'app/api/request';
import ErrorBoundary from 'app/components/ErrorBoundary';
import { QueryClient, QueryClientProvider } from 'react-query';
import Toasts from 'app/Toasts';
import './App.scss';
import config from 'config';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10000,
    },
  },
});

(() => {
  const { _doRequest } = request;
  request._doRequest = (...args) =>
    _doRequest.apply(request, args).catch((e) => {
      if (e.status === 401) {
        window.location.pathname = config.auth?.loginUrl || '/';
      }
      throw e;
    });
})();
function App() {
  console.log('App.js renders');

  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary>
        <Router basename={'/client'}>
          <Routes />
          <Toasts />
        </Router>
      </ErrorBoundary>
    </QueryClientProvider>
  );
}

export default App;
