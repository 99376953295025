export function mapLanguages(document, sectionId) {
  const defaultLocale = document.content.locale || 'sv-SE';
  const languages = [];
  document.content.sections
    .filter((section) => section.superId === sectionId)
    .forEach((section) => {
      languages.push({
        isDefault: true,
        locale: defaultLocale,
        superId: section.superId,
        revisionId: section.revisionId,
      });

      if (section.targetLanguages) {
        section.targetLanguages.forEach((targetLang) => {
          languages.push({
            isDefault: false,
            locale: targetLang.locale,
            superId: targetLang.superId,
            revisionId: targetLang.revisionId,
          });
        });
      }
    });

  return languages;
}
