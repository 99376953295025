import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import { DropdownList } from 'react-widgets';
import { v4 as uuidv4 } from 'uuid';
import { i18n } from 'app/utils/i18n';
import { getSelectedCells, isStylingSelected, getSelectedDropdownValue } from './utils';
import { useReferenceData, useUpdateReferenceData } from './reference-data-queries';
import { useGetDocument } from 'app/api/documents/document-queries';
import { UserContext } from 'app/state/contexts/UserContext';
import { Spinner } from 'app/components';
import { StyledDataSheet } from 'app/components';
import { toolbarCSSExtractor } from 'app/utils/css';

export function DetailsTable() {
  const { documentId, refId } = useParams();
  const { selectedOrganization } = useContext(UserContext);
  const { data: document } = useGetDocument(documentId, selectedOrganization);
  const docCss = document?.css;
  const locale = document?.content.locale;
  const { mutate: updateReferenceData } = useUpdateReferenceData();
  const referenceData = useReferenceData(refId, documentId);
  const { data } = referenceData;

  const defaultFormats = [
    { id: 1, value: 'clear', label: i18n('reference-data.data-table.normal-cell') },
    { id: 2, value: 'thead', label: i18n('reference-data.data-table.table-head') },
    { id: 3, value: '.table--heading', label: i18n('reference-data.data-table.table-title') },
    { id: 4, value: '.table--part-sum', label: i18n('reference-data.data-table.part-sum') },
    { id: 5, value: '.table--sum', label: i18n('reference-data.data-table.sum') },
  ];

  const borderFormats = [
    { id: 1, value: 'clear', label: i18n('reference-data.data-table.no-border') },
    { id: 2, value: 'table--border-top', label: i18n('reference-data.data-table.border-top') },
    { id: 3, value: 'table--border-bottom', label: i18n('reference-data.data-table.border-bottom') },
    { id: 4, value: 'table--border-left', label: i18n('reference-data.data-table.border-left') },
    { id: 5, value: 'table--border-right', label: i18n('reference-data.data-table.border-right') },
    { id: 6, value: 'table--border-top-bottom', label: i18n('reference-data.data-table.border-top-bottom') },
    { id: 7, value: 'table--border-left-right', label: i18n('reference-data.data-table.border-left-right') },
    { id: 8, value: 'table--border-all', label: i18n('reference-data.data-table.border-all') },
  ];

  const backgrounds = [
    { id: 1, value: 'clear', label: i18n('reference-data.data-table.no-background') },
    { id: 2, value: 'table--background-primary', label: i18n('reference-data.data-table.primary-background') },
    { id: 3, value: 'table--background-secondary', label: i18n('reference-data.data-table.secondary-background') },
    { id: 4, value: 'table--background-tertiary', label: i18n('reference-data.data-table.tertiary-background') },
    { id: 5, value: 'table--background-quaternary', label: i18n('reference-data.data-table.quaternary-background') },
  ];

  const [selection, setSelection] = useState();

  const getDropdownSelector = (type, options) => {
    console.log('type', type);
    console.log('options', options);
    return (data, selection) => getSelectedDropdownValue(type, data, selection, options);
  };

  const { user } = useContext(UserContext);
  const lang = user?.userProperties?.cultureId ?? 'sv';
  const financialToolbars = toolbarCSSExtractor(docCss, lang, 'financial');

  // Check if financialToolbars.options is available and has content
  const hasFinancialToolbarOptions = financialToolbars[0]?.options?.length > 0;

  // Transform financialToolbars.options to match the dropdown format, if available
  const financialToolbarFormats = hasFinancialToolbarOptions
    ? financialToolbars[0].options.map((option, index) => ({
        id: index + 1, // Assign a unique id
        value: option.selector, // Use the CSS selector as the value
        label: option.title, // Use the title for the dropdown label
      }))
    : [];

  // Use financialToolbarFormats if available, otherwise default to defaultFormats
  const formatDropdownData = financialToolbarFormats.length > 0 ? financialToolbarFormats : defaultFormats;

  const getSelectedFormat = getDropdownSelector('format', formatDropdownData);
  const getSelectedBorder = getDropdownSelector('border', borderFormats);
  const getSelectedBackground = getDropdownSelector('background', backgrounds);

  const handleClick = (styling) => {
    if (!selection) return;

    getSelectedCells(selection).forEach(({ x, y }) => {
      if (data.selectionContent[x][y]['styling'] === styling) {
        delete data.selectionContent[x][y]['styling'];
      } else {
        data.selectionContent[x][y]['styling'] = styling;
      }
    });

    setSelection(null);
  };

  const setStyle = (key, value) => {
    if (!selection) return;

    getSelectedCells(selection).forEach(({ x, y }) => {
      data.selectionContent[x][y][key] = value;
    });

    setSelection(null);
  };

  const clearStyle = (key) => {
    if (!selection) return;

    getSelectedCells(selection).forEach(({ x, y }) => {
      data.selectionContent[x][y][key] = '';
      delete data.selectionContent[x][y][key];
    });

    setSelection(null);
  };

  const handleChange = (menuItem) => {
    if (menuItem.value === 'clear') {
      clearStyle('format');
    } else {
      setStyle('format', menuItem.value);
    }
  };

  const handleBackgroundChange = (menuItem) => {
    if (menuItem.value === 'clear') {
      clearStyle('background');
    } else {
      setStyle('background', menuItem.value);
    }
  };

  const handleBorderChange = (menuItem) => {
    if (menuItem.value === 'clear') {
      clearStyle('border');
    } else {
      setStyle('border', menuItem.value);
    }
  };

  const handleSubmit = () => {
    const referenceData = {
      name: data.name,
      documentSuperId: documentId,
      fileRevisionId: data.fileRevisionId,
      fileSuperId: data.fileSuperId,
      selection: data.selection,
      selectionContent: data.selectionContent,
      superId: data.superId,
      revisionId: uuidv4(),
    };

    updateReferenceData({ document, referenceData });
  };

  const shadowedChildren = <style>{docCss}</style>;

  if (!referenceData.isSuccess) {
    return <Spinner />;
  }

  return (
    <div>
      <div className="reference-data--toolbar">
        <div className="rw-dropdown-wrapper">
          <DropdownList
            data={formatDropdownData} // Conditionally use the appropriate data source
            selectIcon={<i className="fa-regular fa-chevron-down" />}
            textField="label"
            defaultValue={formatDropdownData[0]} // Ensure default value is set correctly
            value={getSelectedFormat(data, selection)} // Ensure this function can handle both data structures
            onSelect={handleChange} // Adapt handleChange for the new data structure
            onChange={() => {}}
          />
        </div>
        <div className="divider" />
        <div className="rw-dropdown-wrapper">
          <DropdownList
            data={borderFormats}
            selectIcon={<i className="fa-regular fa-chevron-down" />}
            textField="label"
            defaultValue={borderFormats[0]}
            value={getSelectedBorder(data, selection)}
            onSelect={handleBorderChange}
            onChange={() => {}}
          />
        </div>
        <div className="rw-dropdown-wrapper">
          <DropdownList
            data={backgrounds}
            selectIcon={<i className="fa-regular fa-chevron-down" />}
            textField="label"
            defaultValue={backgrounds[0]}
            value={getSelectedBackground(data, selection)}
            onSelect={handleBackgroundChange}
            onChange={() => {}}
          />
        </div>
        <div className="divider" />
        <div className="py-1 d-flex align-items-center">
          <Tippy content={<p className="gaby-tooltip arrow-top">{i18n('reference-table.bold')}</p>} placement="bottom" theme="light">
            <button
              onClick={() => {
                handleClick('bold');
              }}
              className={`${isStylingSelected('bold', data, selection) ? 'active' : ''}`}
            >
              <i className="fa-regular fa-bold" />
            </button>
          </Tippy>
          <Tippy content={<p className="gaby-tooltip arrow-top">{i18n('reference-table.italic')}</p>} placement="bottom" theme="light">
            <button
              onClick={() => {
                handleClick('italic');
              }}
              className={`${isStylingSelected('italic', data, selection) ? 'active' : ''}`}
            >
              <i className="fa-regular fa-italic" />
            </button>
          </Tippy>
          <Tippy content={<p className="gaby-tooltip arrow-top">{i18n('reference-table.underline')}</p>} placement="bottom" theme="light">
            <button
              onClick={() => {
                handleClick('underline');
              }}
              className={`${isStylingSelected('underline', data, selection) ? 'active' : ''}`}
            >
              <i className="fa-regular fa-underline" />
            </button>
          </Tippy>
        </div>
        <div className="divider" />
        <Tippy content={<p className="gaby-tooltip arrow-top">{i18n('reference-table.link-note')}</p>} placement="bottom" theme="light">
          <button>{i18n('reference-table.link-note')}</button>
        </Tippy>
        <div className="divider" />
        <Tippy content={<p className="gaby-tooltip arrow-top">{i18n('common.save')}</p>} placement="bottom" theme="light">
          <button onClick={handleSubmit}>{i18n('common.save')}</button>
        </Tippy>
      </div>
      <div style={{ overflow: 'auto' }}>
        <div className="page-content" style={{ overflow: 'auto' }}>
          <StyledDataSheet data={data.selectionContent} onSelect={setSelection} locale={locale} />
        </div>
      </div>

      {shadowedChildren}
    </div>
  );
}
