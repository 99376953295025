export const Layout = ({ attributes, element, children, markEmpty }) => {
  const className = `${element.className} ${
    element.isLayoutLeaf ? (element.isEmptyLayoutLeaf && markEmpty ? 'layout-leaf layout-leaf-empty' : 'layout-leaf') : ''
  }`;
  return (
    <div {...attributes} className={className}>
      {children}
    </div>
  );
};
