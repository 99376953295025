import { Clickable } from './Clickable';

const MenuItem = ({ itemConf, onClick }) => {
  const handleClick = () => {
    itemConf.onClick?.();
    onClick?.();
  };
  const item = (
    <Clickable className={`d-block tool-menu-item ${itemConf.disabled ? 'text-disabled' : ''}`} onClick={handleClick}>
      <i className={`fa-regular ${itemConf.icon}`} />
      <span className="tool-menu-title">{itemConf.text}</span>
    </Clickable>
  );

  if (itemConf.sub) {
    return itemConf.sub(item);
  }
  return item;
};

const ToolMenu = ({ menuItems, onClick }) => {
  return (
    <div className="tool-menu-wrapper">
      {menuItems?.map((item, idx) => (
        <MenuItem itemConf={item} onClick={onClick} key={idx} />
      ))}
    </div>
  );
};

export default ToolMenu;
