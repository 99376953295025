import React from 'react';
import { i18n } from 'app/utils/i18n';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="container mt-5">
          <h5>{i18n('page.unexpected.error.header')}</h5>
          <div>{i18n('page.unexpected.error.info')}</div>

          <h5>
            <a
              href="/client"
              onClick={() => {
                window.location.href = '/client/';
                window.location.reload();
              }}
            >
              {i18n('page.unexpected.error.reloadlink')}
            </a>
          </h5>

          <br />
          <br />
          <br />
          <h6>{i18n('page.unexpected.error.details')}</h6>
          <div>{this.state.error.message}</div>
          <div>{this.state.error.stack}</div>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
