import React, { useCallback, useEffect, useState } from 'react';
import request from 'app/api/request';
import { useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import { UserCircle } from 'app/components';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const getLastRevision = (change) => {
  if (!change) {
    return '';
  }
  return change.revisions[change.revisions.length - 1].revisionId;
};

const Change = ({ change, onApprove, select, currentRevisionIdL }) => {
  const { user, endDate } = change;

  const isCurrent = (change, revisionId) => {
    return getLastRevision(change) === revisionId;
  };

  return (
    <div className={`change ${isCurrent(change, currentRevisionIdL) ? 'bg-blue-200' : ''}`} onClick={() => select(change)}>
      <UserCircle user={user} />
      <div className="change--middle">
        <div className="change--middle--author--name">
          {user.firstName} {user.lastName}
        </div>
        <div className="change--middle--author--date">{moment(endDate).format('YYYY-MM-DD HH:mm')}</div>
      </div>
    </div>
  );
};

const dateUrl = () => {
  return `?fromDate=1900-01-01&toDate=${new Date().toISOString()}`;
};

export const VersionHistory = ({ setRevisionL }) => {
  const revisionIdLeft = useQuery().get('revisionL');
  const revisionIdRight = useQuery().get('revisionR');

  const { documentId } = useParams();
  const [changes, setChanges] = useState([]);

  const fetchChanges = useCallback(() => {
    request
      .get(`/gaby/documents/${documentId}/changes${dateUrl()}`)
      .then((res) => res.data)
      .then((docChanges) => {
        setChanges(docChanges);
      });
  }, [documentId]);

  useEffect(() => {
    fetchChanges();
  }, [fetchChanges]);

  const handleApprove = (revisions) => {
    request
      .post(
        '/gaby/revisions',
        revisions.map((revision) => revision.revisionId)
      )
      .then(fetchChanges());
  };

  const handleSelectChange = (change) => {
    const selection = getLastRevision(change);
    setRevisionL(selection);
  };

  return (
    <div>
      <div className="sidebar-top">
        <div className="sidebar-top-item">Versionshistorik</div>
      </div>
      <div className="changes">
        {changes.map((change) => (
          <Change
            key={change.revisions[0].revisionId}
            change={change}
            onApprove={() => handleApprove(change.revisions)}
            select={handleSelectChange}
            currentRevisionIdL={revisionIdLeft}
            currentRevisionIdR={revisionIdRight}
          />
        ))}
      </div>
    </div>
  );
};
