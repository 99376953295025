import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

const NavPillItem = ({ item = {} }) => {
  const { pathname } = useLocation();
  const isActive = pathname === item.url;

  return (
    <li className="nav-item">
      <Link to={item.url} className={`nav-link ${isActive ? 'active' : ''}`}>
        {item.text}
      </Link>
    </li>
  );
};

const NavPills = ({ items = [], className = '' }) => {
  return (
    <ul className={`nav nav-pills bg-light mb-5 p-1 border border-line rounded ${className}`}>
      {items.map((item, index) => {
        return <NavPillItem key={index} item={item} />;
      })}
    </ul>
  );
};

NavPills.propTypes = {
  items: PropTypes.array.isRequired,
  className: PropTypes.string,
};

export default NavPills;
