import React, { useState, useEffect } from 'react';
import request from 'app/api/request';
import config from 'config';
import authStore from 'app/utils/authStore';

/**
 * Bootstraps the client
 *
 * Will fetch client-config and then verify authentication
 * and then load all required resources before rendering App.js
 */
const AuthWrapper = (props) => {
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    async function getConfig() {
      const url = window.location.origin;
      request.init(config);
      // Fetch client-config and then update the config module
      await request
        .get(`${url}/foundation/client-config`)
        .then((response) => {
          const fetchedConfig = response.data;

          for (const key in fetchedConfig) {
            // ESLint issue for key: https://github.com/eslint/eslint/issues/12117
            if (fetchedConfig.hasOwnProperty(key)) {
              config[key] = fetchedConfig[key];
            }
          }
          request.init(config);
          request.setToken(authStore.authToken);
          setFetching(false);
        })
        .catch(() => {
          setFetching(false);
        });
    }

    getConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (fetching) {
    return <div>Authenticating...</div>;
  }

  return props.children;
};

export default AuthWrapper;
